//Libraries
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useMemo } from 'react'
import {
  Typography,
  Card,
  Button,
  makeStyles,
  Box,
  CardContent,
  Snackbar,
  Hidden,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'

//Component
import FileErrorMessage from '../FileErrorMessage'

function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

//Style
const useStyles = makeStyles(theme => ({
  img: {
    [theme.breakpoints.between('xs', 'md')]: {
      width: 45.45,
      height: 32.67
    }
  },
  cardroot: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    borderRadius: 8
  },
  box: {
    boxShadow: '0px 0px 0px 0px',
    width: 62,
    height: 65
  },
  cardhead: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 12
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 16
    }
  }
}))

const activeStyle = {
  borderColor: 'blue',
  backgroundColor: 'pink'
}

const style2 = {
  // borderStyle: 'solid',
  // borderColor:  "#f8f8f8",
  backgroundColor: '#f8f8f8'
}

const acceptStyle = {
  borderStyle: 'dashed',
  borderColor: 'green',
  backgroundColor: '#9DC183'
}

const rejectStyle = {
  borderStyle: 'dashed',
  borderColor: 'red',
  backgroundColor: '#CA3433'
}

export default function DocumentCard ({
  props,
  CardName,
  name,
  doc,
  setFieldValue,
  orderDataHandler,
  disabled
}) {
  const classes = useStyles()
  const theme = useTheme()
  const mdmatches = useMediaQuery(theme.breakpoints.down('md'))
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles: 1,
    maxSize: 5000000,
    accept: 'image/*, application/pdf'
  })

  useEffect(() => {
    console.log('accepted files', doc)
    if (acceptedFiles && acceptedFiles.length) {
      console.log(doc, acceptedFiles[0])
      orderDataHandler(doc, acceptedFiles[0])
      setFieldValue(doc, acceptedFiles[0])
    }
  }, [acceptedFiles])

  const style = useMemo(
    () => ({
      ...(acceptedFiles.length > 0 ? style2 : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [, isDragReject, isDragAccept, acceptedFiles]
  )

  const [open, setOpen] = useState(false)
  const handleErrorSnack = () => {
    setOpen(true)
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    console.log('File Rejected', fileRejections.length > 0)
    if (fileRejections.length > 0) {
      handleErrorSnack()
    }
  }, [fileRejections])

  const upload = process.env.PUBLIC_URL + '/assets/images/paper.png'
  const checked =
    process.env.PUBLIC_URL + '/assets/images/icons8_checked_48px.png'
  // const BackgroundHead = {
  //   backgroundImage: 'url(doc)'
  // }

  return (
    <>
      <Card
        {...getRootProps({ className: 'dropzone', style })}
        className={classes.cardroot}
        style={disabled ? { pointerEvents: 'none', opacity: '0.4' } : {}}
      >
        <CardContent>
          <Box
            className={classes.box}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <img
              src={acceptedFiles.length <= 0 ? upload : checked}
              alt='card'
              className={classes.img}
            />
          </Box>
          <Box mt={mdmatches ? '3px' : '10px'}>
            <input {...getInputProps()} name={name} />

            {isDragAccept && (
              <>
                <Typography className={classes.cardhead} color='textPrimary'>
                  Drop Files Here.
                </Typography>
                <Typography
                  variant='subtitle2'
                  className={classes.centerContent}
                  color='textPrimary'
                >
                  (Only JPG, PNG, GIF and PDFs are accepted)
                </Typography>
              </>
            )}
            {isDragReject && (
              <>
                <Typography className={classes.cardhead} color='textPrimary'>
                  Warning !!!
                </Typography>
                <Typography
                  variant='subtitle2'
                  className={classes.centerContent}
                  color='textPrimary'
                >
                  (Only JPG, PNG, GIF and PDFs are accepted)
                </Typography>
              </>
            )}
            {!isDragActive && (
              <>
                <Hidden smUp>
                  <Typography className={classes.cardhead}>
                    Click to Upload
                  </Typography>
                </Hidden>
                <Hidden smDown>
                  <Typography className={classes.cardhead} color='textPrimary'>
                    Drag and Drop
                  </Typography>
                  <Typography className={classes.cardhead} color='textPrimary'>
                    or
                  </Typography>
                  <Typography className={classes.cardhead} color='textPrimary'>
                    Browse your Computer
                  </Typography>
                </Hidden>
              </>
            )}
          </Box>
        </CardContent>
        <Box mt={mdmatches ? 0 : '15px'} style={{ backgroundColor: '#6251FE' }}>
          <Button style={{ color: 'white' }}>{CardName}</Button>
        </Box>
      </Card>
      <FileErrorMessage name={name} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity='error'>
          {'File Rejected! Choose Valid File Format having size less than 5 MB'}
        </Alert>
      </Snackbar>
    </>
  )
}
