//Libraries
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import {
  IconButton,
  Snackbar,
  Typography,
  CssBaseline,
  TextField,
  Button,
  Box,
  useTheme
} from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'

//Components
import Yup from '../../../Yup/YupAuthValidation.schemas'
import actions from '../../../Redux/Driver/Driver.actions'
import NavigationDrawer from '../../../Components/NavigationDrawer/NavigationDrawer'

function Alert (props) {
  return <MuiAlert elevation={2} variant='filled' {...props} />
}

//Style
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(1, 0, 0)
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  image: {
    height: 'auto',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(12),
      width: '50%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(15),
      width: '50%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(18),
      width: '50%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(12),
      width: '60%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(15),
      width: '80%'
    }
  },
  heading: {
    fontWeight: 'bold',
    paddingTop: '2%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 28
    }
  },
  box: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      padding: '1%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '60%',
      padding: '3%'
    },
    [theme.breakpoints.only('md')]: {
      width: '50%',
      padding: '1%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '40%',
      padding: '1%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '30%',
      padding: '3%'
    }
  }
}))

export default function ResetPassword (props) {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const phone = useSelector(state => state.driver.phone)
  const currentPassword = useSelector(state => state.driver.currentPassword)

  useEffect(() => {
    console.log('clearing previous error')
    dispatch(actions.clearError())
  }, [])

  const [Values, setValues] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
    showPassword1: false
  })

  const handleShowPassword = () => {
    setValues({
      ...Values,
      showPassword: !Values.showPassword
    })
  }

  const handleShowPassword1 = () => {
    setValues({
      ...Values,
      showPassword1: !Values.showPassword1
    })
  }

  const handleMDownPassword = event => {
    event.preventDefault()
  }

  const jwt = useSelector(state => state.driver.jwt)
  const visible = useSelector(state => state.driver.isVisible)
  const [open, setOpen] = useState(false)

  const [initialValues, setInitialValues] = useState({
    phone: phone,
    password: '',
    showPassword: false
  })

  const handleClickShowPassword = () => {
    setInitialValues({
      ...initialValues,
      showPassword: !initialValues.showPassword
    })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const currentPassError = useSelector(
    state => state.driver.errors.currentPassError
  )
console.log('currentPassError',currentPassError)
  useEffect(() => {
    if (currentPassError) {
      handleErrorSnack()
    }
  }, [currentPassError])

  const handleErrorSnack = () => {
    setOpen(true)
  }

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Box className={classes.box}>
            <img
              src='/assets/images/createPass.png'
              alt='password'
              className={classes.image}
            />
            <Typography className={classes.heading}>Reset Password</Typography>
            {!visible ? (
              <div>
                <Box pt={'2%'} />
                <Typography>Enter your current password</Typography>
                <Formik
                  initialValues={initialValues}
                  onSubmit={values => {
                    console.log('values:', values)
                    dispatch(actions.currentPass(phone, values.password, props))
                  }}
                >
                  {({ errors, handleChange, touched }) => (
                    <Form className={classes.form}>
                      <Box textAlign='center'>
                        <TextField
                          error={errors.password && touched.password}
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : null
                          }
                          variant='outlined'
                          margin='normal'
                          fullWidth
                          name='password'
                          placeholder='Enter your Current Password'
                          id='password'
                          className={classes.fields}
                          type={
                            initialValues.showPassword ? 'text' : 'password'
                          }
                          autoComplete='password'
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 20
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'
                                >
                                  {initialValues.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <Button
                          type='submit'
                          fullWidth
                          color='primary'
                          size='large'
                          variant='contained'
                          className={classes.submit}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>

                {currentPassError ? (
                  <Snackbar
                    open={open}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={2000}
                    onClose={handleCloseSnack}
                  >
                    <Alert onClose={handleCloseSnack} severity='error'>
                      {currentPassError}
                    </Alert>
                  </Snackbar>
                ) : null}
              </div>
            ) : (
              <div>
                <Box pt={'2%'} />
                <Typography>
                  Your new Password must be different from the previous password
                  you've used
                </Typography>
                <Formik
                  key='2'
                  initialValues={{
                    password: '',
                    confirmPassword: ''
                  }}
                  validationSchema={Yup.ConfirmPasswordSchema}
                  onSubmit={values => {
                    console.log(values)
                    // setVisbilty(true)
                    dispatch(
                      actions.resetPassword(
                        values.password,
                        jwt,
                        currentPassword,
                        props
                      )
                    )
                  }}
                >
                  {({ errors, handleChange, touched }) => (
                    <Form className={classes.form}>
                      <Box textAlign='center'>
                        <TextField
                          error={errors.password && touched.password}
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : null
                          }
                          variant='outlined'
                          margin='normal'
                          fullWidth
                          name='password'
                          placeholder='Enter new Password'
                          id='password'
                          className={classes.fields}
                          type={Values.showPassword ? 'text' : 'password'}
                          autoComplete='password'
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 20
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'
                                >
                                  {Values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box textAlign='center'>
                        <TextField
                          error={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          helperText={
                            errors.confirmPassword && touched.confirmPassword
                              ? errors.confirmPassword
                              : null
                          }
                          variant='outlined'
                          margin='normal'
                          fullWidth
                          name='confirmPassword'
                          className={classes.fields}
                          placeholder='Confirm Password'
                          id='confirmPassword'
                          type={Values.showPassword1 ? 'text' : 'password'}
                          autoComplete='confirm-password'
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 20
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleShowPassword1}
                                  onMouseDown={handleMDownPassword}
                                  edge='end'
                                >
                                  {Values.showPassword1 ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box mt={2}>
                        <Button
                          type='submit'
                          fullWidth
                          color='primary'
                          size='large'
                          variant='contained'
                          className={classes.submit}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </Box>
        </div>
      </main>
    </div>
  )
}
