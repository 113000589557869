//Libraries
import React, { useState } from 'react'
import {
  makeStyles,
  withStyles,
  CssBaseline,
  Typography,
  Box,
  Grid,
  Divider,
  Hidden
} from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

//Icons
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    marginBottom: '4%',
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.125rem',
      marginTop: '5%'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.125rem',
      marginTop: '3%'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.5rem',
      marginTop: '3%'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.95rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '2.125rem'
    }
  },
  content: {
    flexGrow: 1
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  submit: {
    margin: theme.spacing(2, 0, 4),
    fontWeight: 'bold'
  },
  root: {
    display: 'flex'
  },
  heading: {
    color: 'primary',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.1rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  subheading: {
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.1rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  accordion: {
    border: 'none',
    boxShadow: 'none'
  },
  boxes: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.only('md')]: {
      width: '95%',
      padding: '1%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '80%',
      padding: '1%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '80%',
      padding: '2%'
    }
  },
  divider: {
    marginTop: '1%',
    marginBottom: '1%'
  },
  image: {
    marginTop: theme.spacing(2)
  }
}))

//Accordion
const Accordion = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

//Accordion Summary
const AccordionSummary = withStyles({
  root: {
    padding: 0
  }
})(MuiAccordionSummary)

//Accordion Datails
const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
    textAlign: 'left'
  }
}))(MuiAccordionDetails)

export default function FAQs () {
  const classes = useStyles()

  //Q&A
  const options = [
    {
      id: 'panel1',
      question: 'How can a negative rating be removed ?',
      answer:
        'You have to contact the admin and explain your situation so that the negative rating can be removed.'
    },
    {
      id: 'panel2',
      question: 'How do i know about order priority ?',
      answer: 'The Order that is on the nearest date is of highest priority. '
    },
    {
      id: 'panel3',
      question: 'How do i contact customer ?',
      answer: 'You can contact the customer via call or chat'
    },
    {
      id: 'panel4',
      question: 'How will my transport be inspected ?',
      answer:
        'You can get your transport checked at our allocated inspection stops.'
    }
  ]
  const [expanded, setExpanded] = useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Grid container align='center' alignContent='center'>
            <Hidden lgUp>
              <Grid item sm={12} xs={12} md={12} lg={false} xl={false}>
                <img src='/assets/images/Faq.png' alt='faq' />
              </Grid>
            </Hidden>
            <Grid item sm={12} xs={12} md={12} lg={8} xl={8}>
              <Box className={classes.boxes}>
                <Typography className={classes.title} align='center'>
                  Frequently ask Questions
                </Typography>
                {options.map(option => (
                  <React.Fragment key={option.id}>
                    <Accordion
                      className={classes.accordion}
                      expanded={expanded === option.id}
                      onChange={handleChange(option.id)}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === option.id ? <RemoveIcon /> : <AddIcon />
                        }
                      >
                        <Typography className={classes.heading}>
                          {option.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.subheading}>
                          {option.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Divider className={classes.divider} />{' '}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
            <Hidden mdDown>
              <Grid
                item
                xs={false}
                sm={false}
                md={false}
                lg={4}
                xl={4}
                align='center'
              >
                <img
                  src='/assets/images/FAQs.png'
                  alt='Faq'
                  className={classes.image}
                />
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </main>
    </div>
  )
}
