//Libraries
import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import {
  Box,
  Grid,
  Typography,
  Card,
  Button,
  useTheme,
  useMediaQuery,
  TextField,
  Dialog,
  makeStyles
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

//Components
import Yup from '../../Yup/YupOrderValidation.schemas'
import actions from '../../Redux/Order/Order.actions'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  centered1: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    height: '40%'
  },
  title: {
    marginTop: '2%',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22.5
    }
  },
  helperText: {
    marginLeft: 0,
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px'
    }
  },
  textfields: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.83rem',
      marginTop: '2%'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem',
      marginTop: '2%'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem',
      marginTop: '3%'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.1rem',
      marginTop: '3%'
    }
  },
  button: {
    marginRight: '3%'
  },
  texts: {
    marginTop: '8px'
  },
  dialog: {
    borderRadius: 8
  },
  box: {
    width: '100%'
  }
}))

export default function CongestionCharges ({
  open,
  orderID,
  props,
  handleClose
}) {
  const classes = useStyles()
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const dispatch = useDispatch()
  const jwt = useSelector(state => state.driver.jwt)
  const [fullWidth] = React.useState(true)
  const orderDetail = useSelector(state => state.order.orderDetail)
  const numberOfTollPlaza = useSelector(
    state => state.order.orderDetail?.estimatedPrice?.numberOfTollPlaza
  )
  const finalTollCharges = useSelector(
    state => state.order.orderDetail?.estimatedPrice?.finalTollCharges
  )
  const citiesWithCongestion = useSelector(
    state => state.order.orderDetail?.estimatedPrice?.citiesWithCongestion
  )
  const finalCongestionCharges = useSelector(
    state => state.order.orderDetail?.estimatedPrice?.finalCongestionCharges
  )
  const [initialValues, setInitialValues] = useState({
    tollPlaza: numberOfTollPlaza ? numberOfTollPlaza : 0,
    amountPlaza: finalTollCharges ? finalTollCharges : 0,
    cities: citiesWithCongestion ? citiesWithCongestion : 0,
    amountCongestion: finalCongestionCharges ? finalCongestionCharges : 0
  })

  //#region useEffect
  useEffect(() => {
    dispatch(actions.getOrderDetails(jwt, orderID))
  }, [])

  useEffect(() => {
    console.log('congestion Charges updated')
  }, [
    numberOfTollPlaza,
    finalTollCharges,
    citiesWithCongestion,
    finalCongestionCharges
  ])

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth='sm'
      hideBackdrop={true}
      open={open}
      className={classes.dialog}
      onClose={handleClose}
      aria-labelledby='max-width-dialog-title'
    >
      <Card key={orderID} className={classes.centered1} elevation={2}>
        <Box className={classes.box} p={lgMatch ? 4 : 2}>
          <Typography className={classes.title}>
            <b>Additional Charges?</b>
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.congestionChargesSchema}
            enableReinitialize={true}
            onSubmit={values => {
              console.log('message', values)
              dispatch(actions.conjestionCharges(orderID, values, jwt))
              handleClose()
            }}
          >
            {({ errors, handleChange, touched, values }) => (
              <Form className={classes.form}>
                <Typography variant='body2' className={classes.textfields}>
                  Number of Toll Plaza
                </Typography>
                <TextField
                  error={errors.tollPlaza && touched.tollPlaza}
                  helperText={
                    errors.tollPlaza && touched.tollPlaza
                      ? errors.tollPlaza
                      : null
                  }
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  id='tollPlaza'
                  name='tollPlaza'
                  type='number'
                  placeholder='No of Toll Plaza'
                  fullWidth
                  defaultValue={
                    orderDetail
                      ? orderDetail?.estimatedPrice?.numberOfTollPlaza
                      : null
                  }
                  margin='normal'
                  className={classes.texts}
                  variant='outlined'
                  onChange={handleChange}
                />
                <Typography variant='body2' className={classes.textfields}>
                  Total amount of Toll Plaza
                </Typography>
                <TextField
                  error={errors.amountPlaza && touched.amountPlaza}
                  helperText={
                    errors.amountPlaza && touched.amountPlaza
                      ? errors.amountPlaza
                      : null
                  }
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  className={classes.texts}
                  defaultValue={
                    orderDetail
                      ? orderDetail?.estimatedPrice?.finalTollCharges
                      : null
                  }
                  id='amountPlaza'
                  name='amountPlaza'
                  type='number'
                  placeholder='£ Toll plaza amount'
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  onChange={handleChange}
                />
                <Typography variant='body2' className={classes.textfields}>
                  Cities with congestion
                </Typography>
                <TextField
                  error={errors.cities && touched.cities}
                  helperText={
                    errors.cities && touched.desc ? errors.cities : null
                  }
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  className={classes.texts}
                  id='cities'
                  name='cities'
                  type='number'
                  placeholder='Cities'
                  defaultValue={
                    orderDetail
                      ? orderDetail?.estimatedPrice?.citiesWithCongestion
                      : null
                  }
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  onChange={handleChange}
                />
                <Typography variant='body2' className={classes.textfields}>
                  Total amount of Congestion
                </Typography>
                <TextField
                  error={errors.amountCongestion && touched.amountCongestion}
                  helperText={
                    errors.amountCongestion && touched.desc
                      ? errors.amountCongestion
                      : null
                  }
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  className={classes.texts}
                  id='amountCongestion'
                  name='amountCongestion'
                  defaultValue={
                    orderDetail
                      ? orderDetail?.estimatedPrice?.finalCongestionCharges
                      : null
                  }
                  placeholder='£ Congestion amount'
                  fullWidth
                  type='number'
                  margin='normal'
                  variant='outlined'
                  onChange={handleChange}
                />
                <Box m={2} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      size='large'
                      className={classes.button}
                      style={{ backgroundColor: '#F5F6FE' }}
                      fullWidth
                      variant='contained'
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type='submit'
                      fullWidth
                      color='primary'
                      size='large'
                      variant='contained'
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
    </Dialog>
  )
}
