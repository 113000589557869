//Libraries
import React, { useState, useRef, useEffect, useCallback } from 'react'
import {
  useTheme,
  useMediaQuery,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'

//Components
import mapStyles from '../../Utils/mapStyles'
import mapStylesDark from '../../Utils/mapStylesDark'
import MapDirections from './MapDirections'

//Style
const useStyles = makeStyles(theme => ({
  progress: {
    marginLeft: '50%',
    [theme.breakpoints.between('xs', 'md')]: {
      width: '30px',
      height: '30px',
      marginTop: '20%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: '40px',
      height: '40px',
      marginTop: '30%'
    }
  },
  div: {
    height: '100%'
  }
}))

const libraries = ['places']

const GoogleMaps = props => {
  const { places, updateLocation } = props
  const theme = useTheme()
  const classes = useStyles()
  const themeType = useSelector(state => state.themes.theme)
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const smmatches = useMediaQuery(theme.breakpoints.down('sm'))
  const lgMatches = useMediaQuery(theme.breakpoints.up('lg'))

  const [center] = useState({
    lat: 33.738045,
    lng: 73.084488
  })

  const [mapOptions] = useState({
    styles: themeType === 'dark' ? mapStylesDark : mapStyles,
    disableDefaultUI: true,
    zoomControl: true
  })
  const [positions, setPostition] = useState({
    lat: updateLocation?.lat,
    lng: updateLocation?.lng
  })

  useEffect(() => {
    setPostition({
      lat: updateLocation?.lat,
      lng: updateLocation?.lng
    })
  }, [updateLocation?.lat, updateLocation?.lng])

  const onMapClick = useCallback(event => {
    console.log('onMapClick Event', event)
  }, [])
  const mapRef = useRef(null)
  const onMapLoad = useCallback(map => {
    mapRef.current = map
  }, [])
  const handleCenterChanged = () => {
    console.log('onCenterChange', mapRef.current?.getCenter().toJSON())
  }
  function handleZoomChanged () {
    console.log('onZoomLevelChange', mapRef.current?.getZoom()) //this refers to Google Map instance
  }
  console.log('newCenter', center)
  const onUnmount = React.useCallback(function callback (map) {}, [])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })
  console.log(
    'process.env.REACT_APP_GOOGLE_MA',
    process.env.REACT_APP_GOOGLE_MA
  )
  return (
    <>
      {isLoaded ? (
        <div className={classes.div}>
          <GoogleMap
            id='direction-example'
            mapContainerStyle={
              smmatches
                ? { height: '60vh' }
                : lgMatches
                ? { height: '100%' }
                : matches
                ? { height: '50vh', width: '100%' }
                : { height: '40vh' }
            }
            zoom={10}
            center={center}
            onLoad={onMapLoad}
            onCenterChanged={handleCenterChanged}
            onZoomChanged={handleZoomChanged}
            onUnmount={onUnmount}
            onClick={onMapClick}
            options={mapOptions}
          >
            <Marker
              position={positions}
              options={{
                icon: {
                  url: '/assets/images/marker.jpg',
                  scaledSize: new window.google.maps.Size(60, 60),
                  anchor: { x: 10, y: 10 }
                }
              }}
            />

            {places[0].latitude === 0 ? null : (
              <MapDirections places={places} travelMode='DRIVING' />
            )}
          </GoogleMap>
        </div>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </>
  )
}
export default GoogleMaps
