//Components
import orderActions from './Order.actions'

//Order State/Store
const initialState = {
  socket: null,
  location: null,
  currentLocation: {
    lat: null,
    lng: null
  },
  updateLocation: {
    lat: null,
    lng: null
  },
  newPolyline: [
    {
      lat: null,
      lng: null
    }
  ],
  tripStartLocation: {
    formattedAddress: null,
    zipcode: null,
    lat: null,
    long: null,
    floor: null,
    isFreightElevatorAvailable: null
  },
  tripEndLocation: {
    formattedAddress: null,
    zipcode: null,
    lat: null,
    long: null,
    floor: null,
    isFreightElevatorAvailable: null
  },
  isRideInsured: null,
  noOfHelpersRequired: null,
  description: null,
  rideType: null,
  promoCode: null,
  dateAndTime: null,
  isYes: null,
  isNo: null,
  orderDetail: null,
  orderStatus: null,
  allOrders: null,
  response: '',
  updateResponse: '',
  acceptedOrders: null,
  pastOrders: null,
  activeOrders: [],
  pendingOrders: [],
  deliveredOrders: [],
  cancelledOrders: [],
  errors: {
    conjestionError: null
  },
  success: {
    conjestionSuccess: null
  }
}

// Order Reducer
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    //#region socket connection
    case orderActions.SOCKET_CONNECTION: {
      return {
        ...state,
        socket: action.payload
      }
    }
    case orderActions.RECEIVE_MESSAGE: {
      return {
        ...state,
        location: action.payload
      }
    }
    //#endregion

    //#region start, end, driver location
    case orderActions.DRIVER_CURRENT_LOCATION: {
      return {
        ...state,
        currentLocation: {
          lat: action.payload.lat,
          lng: action.payload.lng
        }
      }
    }
    case orderActions.UPDATE_LOCATION: {
      return {
        ...state,
        updateLocation: {
          lat: action.payload.lat,
          lng: action.payload.lng
        }
      }
    }
    case orderActions.UPDATE_POLYLINE: {
      return {
        ...state,
        newPolyline: [
          ...state.newPolyline,

          {
            lat: action.payload.lat,
            lng: action.payload.lng
          }
        ]
      }
    }
    case orderActions.CLEAR_WATCH_HISTORY: {
      return {
        ...state,
        currentLocation: {
          lat: null,
          lng: null
        },
        updateLocation: {
          lat: null,
          lng: null
        },
        newPolyline: [
          {
            lat: null,
            lng: null
          }
        ]
      }
    }

    case orderActions.UPDATE_START_LOCATION:
      return {
        ...state,
        tripStartLocation: {
          formattedAddress: action.payload.formattedAddress,
          zipcode: action.payload.zipcode,
          lat: action.payload.lat,
          long: action.payload.long,
          floor: action.payload.floor,
          isFreightElevatorAvailable: action.payload.isFreightElevatorAvailable
        }
      }

    case orderActions.UPDATE_END_LOCATION:
      return {
        ...state,
        tripEndLocation: {
          formattedAddress: action.payload.formattedAddress,
          zipcode: action.payload.zipcode,
          lat: action.payload.lat,
          long: action.payload.long,
          floor: action.payload.floor,
          isFreightElevatorAvailable: action.payload.isFreightElevatorAvailable
        }
      }
    //#endregion

    //#region Orders List, details and update order details
    case orderActions.UPDATE_ORDER_DETAILS:
      return {
        ...state,
        isRideInsured: action.payload.isRideInsured,
        noOfHelpersRequired: action.payload.noOfHelpersRequired,
        description: action.payload.description,
        rideType: action.payload.rideType,
        promoCode: action.payload.promoCode,
        dateAndTime: action.payload.dateAndTime
      }

    case orderActions.SET_ORDERS:
      return {
        ...state,
        allOrders: action.payload
      }

    case orderActions.SET_ACCEPTED_ORDERS:
      return {
        ...state,
        acceptedOrders: action.payload
      }

    case orderActions.VERIFY_ORDER_CODE:
      return {
        ...state,
        updateResponse: action.payload
      }

    case orderActions.GET_PAST_ORDERS:
      return {
        ...state,
        pastOrders: action.payload
      }

    case orderActions.GET_ACTIVE_ORDERS:
      return {
        ...state,
        activeOrders: action.payload
      }

    case orderActions.GET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: action.payload
      }

    case orderActions.GET_DELIVERED_ORDERS:
      return {
        ...state,
        deliveredOrders: action.payload
      }

    case orderActions.GET_CANCELLED_ORDERS:
      return {
        ...state,
        cancelledOrders: action.payload
      }

    case orderActions.GET_ORDER_DETAILS:
      return {
        ...state,
        orderDetail: action.payload,
        orderStatus: action.payload.orderStatus
      }
    //#endregion

    //#region order status, confirmation, complete order issue
    case orderActions.GET_ORDER_CONFIRMATION:
      return {
        ...state,
        response: action.payload
      }

    case orderActions.UPDATE_ORDER_STATUS:
      return {
        ...state,
        updateResponse: action.payload
      }
    //#endregion

    //#region conjestion charges
    case orderActions.CONJESTION_CHARGES_SUCCESS:
      console.log('congestion charges', action.payload)
      return {
        ...state,
        errors: { ...state.errors, conjestionError: null },
        success: {
          ...state.success,
          conjestionSuccess: action.payload.success
        }
      }

    case orderActions.CONJESTION_CHARGES_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          conjestionError: action.payload.error
        },
        success: { ...state.success, conjestionSuccess: null }
      }
    //#endregion

    //clear error
    case orderActions.CLEAR_ERROR:
      return {
        ...state,
        currentPassword: null,
        errors: {
          ...state.errors,
          conjestionError: null
        },
        success: {
          ...state.success,
          conjestionSuccess: null
        }
      }

    default:
      return state
  }
}

export default orderReducer
