//Libraries
import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

//Components
import ForgotPassword from '../Pages/Auth/ForgotPassword/ForgotPassword.page'
import SignUp from '../Pages/Auth/SignUp/SignUp.page'
import SignIn from '../Pages/Auth/SignIn/SignIn.page'
import OTP from '../Pages/Auth/OTP/OTP.page'
import Invite from '../Pages/Invite.page'
import ContactUs from '../Pages/ContactUS.page'
import Help from '../Pages/Help/Help.page'
import Setting from '../Pages/Settings/Settings.page'
import CreatePassword from '../Pages/Settings/CreatePassword.page'
import Notifications from '../Pages/Notifications.page'
import Orders from '../Pages/Orders/Orders.page'
import Payment from '../Pages/PaymentMethods/Payment.page'
import MyProfile from '../Pages/MyProfile.page'
import Messages from '../Pages/Messages/Messages.page'
import VehicleType from '../Pages/VehicleRegistrationProcess/Step2_VehicleType.page'
import DocumentVerification from '../Pages/VehicleRegistrationProcess/Step1_DocumentVerification.page'
import DocumentApproval from '../Pages/Documents/DocApproval.page'
import DriverBlock from '../Pages/Documents/DriverBlock.page'
import OrderSummary from '../Pages/OrderSummary.page'
import DocumentEdit from '../Pages/Documents/DocEdit.page'
import UpdatePhone from '../Pages/Auth/UpdatePhone/UpdatePhone'
import ResetPassword from '../Pages/Settings/Resetpassword/ResetPassword.page'
import PhoneOTP from '../Pages/Auth/OTP/PhoneOTP'
import Chat from '../Pages/Chat'
import InternetConnection from '../Components/InternetConnection.component'
import HomeNew from '../Pages/Home/homeNew.page'
import NotFound from '../Pages/NotFound.page'
import actions from '../Redux/Driver/Driver.actions'

const FunRoutes = ({ messages, sendMessage }) => {
  let routes

  const dispatch = useDispatch()
  const isOTPRecieved = useSelector(state => state.driver.isOTPRecieved)
  const activityStatus = useSelector(state => state.driver.activityStatus)
  const isJWTRecieved = useSelector(state => state.driver.isJWTRecieved)
  const isLoggedIn = useSelector(state => state.driver.isLoggedIn)
  const jwt = useSelector(state => state.driver.jwt)
  console.log('routes, jwt', jwt)
  
  useEffect(()=>{
    console.log('routes, jwt1', jwt)
  },[activityStatus])
  console.log('Driver Activity Status in routes: ', activityStatus)


  if (isLoggedIn) {
    routes = (
      <div>
        <InternetConnection />
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route
            exact
            path='/'
            element={
              activityStatus === 'Looking for work' ||
              activityStatus === 'Disable' ? (
                <HomeNew />
              ) : activityStatus === 'Documents Verification Required' ? (
                <DocumentVerification />
              ) : activityStatus === 'Pending' ? (
                <DocumentApproval />
              ) : activityStatus === 'Block' ? (
                <DriverBlock />
              ) : null
            }
          />
          <Route
            path='/home'
            element={
              activityStatus === 'Looking for work' ||
              activityStatus === 'Disable' ? (
                <HomeNew />
              ) : activityStatus === 'Documents Verification Required' ? (
                <DocumentVerification />
              ) : activityStatus === 'Pending' ? (
                <DocumentApproval />
              ) : activityStatus === 'Block' ? (
                <DriverBlock />
              ) : null
            }
          />
          {activityStatus !== 'Block' ? (
            <>
              {activityStatus === 'Pending' ||
              activityStatus === 'Documents Verification Required' ? (
                <Route path='/Doc_Approval' element={<DocumentApproval />} />
              ) : null}
              <Route path='/profile' element={<MyProfile />} />
              {activityStatus === 'Looking for work' ||
              activityStatus === 'Disable' ? (
                <>
                  <Route path='/orders' element={<Orders />} />
                  <Route
                    path='/order_details/:orderId'
                    element={<OrderSummary />}
                  />
                  <Route path='/chat/:orderId' element={<Chat />} />
                  <Route
                    path='/orderDetails/:orderId/messages/:userId'
                    element={Messages}
                  />
                </>
              ) : null}
              <Route path='/notifications' element={<Notifications />} />
              <Route path='/settings' element={<Setting />} />
              <Route path='/help' element={<Help />} />
              <Route path='/invite' element={<Invite />} />
              <Route path='/about_us' render={() => <div>About Us</div>} />
              <Route path='/contact_us' element={<ContactUs />} />
              <Route path='/reset_password' element={<ResetPassword />} />
              <Route path='/otp' element={<OTP />} />
              <Route path='/phone_otp' element={<PhoneOTP />} />
              <Route path='/payment' element={<Payment />} />
              <Route path='/messages' element={<Messages />} />
              <Route path='/vehicle_type' element={<VehicleType />} />
              {activityStatus === 'Documents Verification Required' ? (
                <Route
                  path='/document_verification'
                  element={<DocumentVerification />}
                />
              ) : null}
              {activityStatus !== 'Documents Verification Required' ? (
                <Route path='/document_edit' element={<DocumentEdit />} />
              ) : null}
              <Route path='/forgot_password' element={<ForgotPassword />} />
              <Route path='/updatePhone' element={<UpdatePhone />} />{' '}
            </>
          ) : null}
        </Routes>
      </div>
    )
  } else {
    //Auth Routes
    routes = (
      <div>
        <InternetConnection />
        <Routes>
          <Route path='*' element={<SignIn />} />
          <Route path='/' element={<SignIn />} />
          <Route path='/sign_in' element={<SignIn />} />
          <Route path='/forgot_password' element={<ForgotPassword />} />
          <Route path='/sign_up' element={<SignUp />} />
          {isOTPRecieved ? (
            <Route path='/otp' element={<OTP />} />
          ) : (
            <Route path='/' />
          )}

          <Route
            path='/otp'
            element={!isOTPRecieved ? <Navigate replace to='/' /> : <OTP />}
          />

          <Route
            path='/reset_password'
            element={
              !isJWTRecieved && !isOTPRecieved ? (
                <Navigate replace to='/' />
              ) : (
                <CreatePassword />
              )
            }
          />
        </Routes>
      </div>
    )
  }

  return routes
}
export default FunRoutes
