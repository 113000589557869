//Libraries
import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Link,
  Box,
  TextField,
  Chip,
  IconButton,
  CssBaseline,
  Button,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  Hidden,
  Snackbar,
  InputAdornment,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Autocomplete } from "@material-ui/lab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

//Components
import { themeReducer } from "../../../Redux/Theme/themeReducer";
import actions from "../../../Redux/Driver/Driver.actions";
import Yup from "../../../Yup/YupAuthValidation.schemas";
import Loader from "../../../Components/Loader/Loader";

function Alert(props) {
  return <MuiAlert elevation={2} variant="filled" {...props} />;
}

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
  },
  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.17rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.25rem",
    },
  },
  subtitle: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.83rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.17rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.37rem",
    },
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  image: {
    width: "100%",
    height: "auto",
  },
  block: {
    display: "inline-block",
  },
  links: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.85rem",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.155rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.125rem",
    },
  },
  form: {
    width: "100%",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  textfields: {
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.83rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: "1.1rem",
    },
  },
  submit: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 0, 4),
    },
    [theme.breakpoints.only("lg")]: {
      margin: theme.spacing(3, 0, 2),
    },
    [theme.breakpoints.only("xl")]: {
      margin: theme.spacing(5, 0, 0),
    },
  },
  text: {
    "& p": {
      marginRight: 0,
      marginLeft: 0,
      [theme.breakpoints.between("xs", "lg")]: {
        fontSize: 12,
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: 14.9,
      },
    },
  },
  customTextField: {
    "& input::placeholder": {
      [theme.breakpoints.between("xs", "md")]: {
        fontSize: 13,
      },
      [theme.breakpoints.between("lg", "xl")]: {
        fontSize: 18,
      },
    },
  },
  box: {
    marginBottom: "1%",
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: "7%",
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "9%",
    },
  },
}));

const topZipCodes = [
  { title: "EC1A", city: "London" },
  { title: "EC1V", city: "London" },
  { title: "EC2A", city: "London" },
  { title: "E1", city: "London" },
  { title: "N1", city: "London" },
  { title: "NW1", city: "London" },
  { title: "SE1", city: "London" },
  { title: "W1", city: "London" },
  { title: "B1", city: "Birmingham" },
  { title: "B2", city: "Birmingham" },
  { title: "B3", city: "Birmingham" },
  { title: "B4", city: "Birmingham" },
  { title: "M1", city: "Manchester" },
  { title: "M2", city: "Manchester" },
  { title: "M3", city: "Manchester" },
  { title: "L1", city: "Liverpool" },
  { title: "L2", city: "Liverpool" },
  { title: "L3", city: "Liverpool" },
  { title: "G1", city: "Glasgow" },
  { title: "G2", city: "Glasgow" },
  { title: "EH1", city: "Edinburgh" },
  { title: "EH2", city: "Edinburgh" },
  { title: "BS1", city: "Bristol" },
  { title: "BS2", city: "Bristol" },
  { title: "LS1", city: "Leeds" },
  { title: "LS2", city: "Leeds" },
  { title: "NE1", city: "Newcastle" },
  { title: "NE2", city: "Newcastle" },
  { title: "NG1", city: "Nottingham" },
  { title: "NG2", city: "Nottingham" },
  { title: "CF10", city: "Cardiff" },
  { title: "CF11", city: "Cardiff" },
  { title: "BA1", city: "Bath" },
  { title: "BA2", city: "Bath" },
  { title: "BT1", city: "Belfast" },
  { title: "BT2", city: "Belfast" },
  { title: "PO1", city: "Portsmouth" },
  { title: "PO2", city: "Portsmouth" },
  { title: "SO14", city: "Southampton" },
  { title: "SO15", city: "Southampton" },
  { title: "OX1", city: "Oxford" },
  { title: "OX2", city: "Oxford" },
  { title: "CB1", city: "Cambridge" },
  { title: "CB2", city: "Cambridge" },
  { title: "SR1", city: "Sunderland" },
  { title: "SR2", city: "Sunderland" },
  { title: "TS1", city: "Middlesbrough" },
  { title: "TS2", city: "Middlesbrough" },
];

export default function SignUp(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const lgMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const signUpError = useSelector((state) => state.driver.errors.signUpError);
  const isOTPRecieved = useSelector((state) => state.driver.isOTPRecieved);
  const isJWTRecieved = useSelector((state) => state.driver.isJWTRecieved);
  const themeType = useSelector((state) => state.themes.theme);
  console.log(themeType);
  const isLoggedIn = useSelector(
    (state) => state.driver.isLoggedIn && state.driver.jwt !== null
  );
  const loading = useSelector((state) => state.driver.isFetching);
  const initialTimer = 60;
  const [seconds, setSeconds] = React.useState(initialTimer);
  localStorage.setItem("seconds", seconds);

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    zipcode: "",
    activeZipcodes: [],
  });
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState([]);

  const [open, setOpen] = useState(false);
  console.log("inputValue", inputValue, chips);
  useEffect(() => {
    console.log("clearing previous error");
    dispatch(actions.clearError());
  }, []);

  useEffect(() => {
    if (signUpError) {
      handleErrorSnack();
    }
  }, [signUpError]);

  const handleErrorSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError());
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  if (isLoggedIn) return <Navigate replace to="/Home" />;
  if (isOTPRecieved) return <Navigate replace to="/otp" />;
  if (isOTPRecieved && isJWTRecieved)
    return <Navigate replace to="/set_password" />;

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        component={Paper}
        elevation={2}
        square
      >
        <div className={classes.paper}>
          <Typography variant="h1" className={classes.title} color="primary">
            LOGO
          </Typography>
          <Box className={classes.box}>
            <Typography variant="h1" className={classes.title}>
              Sign up.
            </Typography>
          </Box>
          <Typography
            variant="caption"
            className={classes.subtitle}
            color="secondary"
          >
            Get Register with us to continue
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={Yup.SignUpValidationSchema}
            onSubmit={(values) => {
              console.log(values);
              // dispatch(actions.signUp(values, props));
            }}
          >
            {({ errors, handleChange, touched, setFieldValue }) => (
              <Form className={classes.form}>
                <TextField
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name ? errors.name : null}
                  classes={{ root: classes.customTextField }}
                  variant="outlined"
                  margin="normal"
                  label="Full Name"
                  fullWidth
                  id="name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
                <TextField
                  error={errors.email && touched.email}
                  helperText={
                    errors.email && touched.email ? errors.email : null
                  }
                  variant="outlined"
                  classes={{ root: classes.customTextField }}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                <TextField
                  error={errors.phone && touched.phone}
                  helperText={
                    errors.phone && touched.phone ? errors.phone : null
                  }
                  variant="outlined"
                  margin="normal"
                  classes={{ root: classes.customTextField }}
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                  type="tel"
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 16,
                  }}
                />
                <TextField
                  error={errors.address && touched.address}
                  helperText={
                    errors.address && touched.address ? errors.address : null
                  }
                  variant="outlined"
                  classes={{ root: classes.customTextField }}
                  margin="normal"
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  autoComplete="address"
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 200,
                  }}
                />
                <TextField
                  error={errors.city && touched.city}
                  helperText={errors.city && touched.city ? errors.city : null}
                  variant="outlined"
                  margin="normal"
                  classes={{ root: classes.customTextField }}
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                  autoComplete="city"
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 85,
                  }}
                />
                 <TextField
                    error={errors.zipcode && touched.zipcode}
                    helperText={
                      errors.zipcode && touched.zipcode ? errors.zipcode : null
                    }
                    variant="outlined"
                    margin="normal"
                    classes={{ root: classes.customTextField }}
                    fullWidth
                    id="zipcode"
                    label="Zip Code (Home)"
                    name="zipcode"
                    autoComplete="zipcode"
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 5,
                    }}
                  />
                <Box
                  style={{
                    border: "1px solid #C4C4C4",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                 

                  <TextField
                    variant="outlined"
                    margin="normal"
                    id="activeZipcodes"
                    name="activeZipcodes"
                    label="Zip Code (Driving)"
                    classes={{ root: classes.customTextField }}
                    fullWidth
                    error={errors.activeZipcodes && touched.activeZipcodes}
                    helperText={
                      errors.activeZipcodes && touched.activeZipcodes
                        ? errors.activeZipcodes
                        : "Note:Click the check button after entering a Valid Zip Code"
                    }
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                         
                            edge="end"
                            color="primary"
                            onClick={() => {
                              if (inputValue.trim() !== "") {
                                const newChips = [...chips, inputValue.trim()];
                                setChips(newChips);
                                setFieldValue("activeZipcodes", newChips);
                                setInputValue("");
                              }
                            }}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            color="secondary"
                            onClick={() => setInputValue("")}
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
                    {chips.map((chip, index) => (
                      <Chip
                        key={index}
                        label={chip}
                        onDelete={() => {
                          const newChips = chips.filter(
                            (chipToRemove) => chipToRemove !== chip
                          );
                          setChips(newChips);
                          setFieldValue("activeZipcodes", newChips);
                        }}
                        deleteIcon={<CancelIcon />}
                      />
                    ))}
                  </Box>
                </Box>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  className={classes.submit}
                >
                  {loading ? <Loader /> : "Sign Up"}
                </Button>
                <Grid container className={classes.block}>
                  <Grid item className={classes.typo}>
                    <Typography className={classes.links}>
                      Already have an account?
                      <Link href="/sign_in">{" Sign in"}</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={false}
        lg={6}
        xl={6}
        align="center"
        className={classes.item}
        style={{
          backgroundColor: "#6251FE",
        }}
      >
        <Hidden mdDown>
          <Grid item lg={10}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.root}
            >
              {themeType == "light" ? (
                <img
                  src="/assets/images/signup.png"
                  alt="sign up"
                  className={classes.image}
                />
              ) : (
                <img
                  src="/assets/images/signupd.png"
                  alt="sign up"
                  className={classes.image}
                />
              )}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      {signUpError ? (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {signUpError}
          </Alert>
        </Snackbar>
      ) : null}
    </Grid>
  );
}
