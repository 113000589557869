import * as yup from 'yup'

const phoneRegExpNew =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
const onlyStringRegExp = /^[a-zA-Z\s]+$/

const schemata = {
  YupContactUsValidationSchema: yup.object().shape({
    firstName: yup
      .string()
      .matches(onlyStringRegExp, 'Numbers and symbols are not Allowed.')
      .required('This field is required.'),
    lastName: yup
      .string()
      .matches(onlyStringRegExp, 'Numbers and symbols are not Allowed.')
      .required('This field is required.'),
    email: yup
      .string()
      .email('Invalid email format.')
      .required('This field is required.'),
    phone: yup
      .string()
      .matches(phoneRegExpNew, 'Invalid phone number format.')
      .required('This field is required.'),
    message: yup
      .string()

      .required('This field is required.')
  })
}

export default schemata
