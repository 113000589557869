//Libraries
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Button,
  CssBaseline,
  makeStyles,
  Typography,
  Box,
  TextField,
  useTheme,
  CardContent,
  Grid,
  Paper,
  Popover,
  Avatar,
  IconButton,
  useMediaQuery
} from '@material-ui/core'
import clsx from 'clsx'
import ScrollableFeed from 'react-scrollable-feed'
import moment from 'moment'
import { Send } from '@material-ui/icons'
import { Formik, Form } from 'formik'

//Components
import NavigationDrawer from '../Components/NavigationDrawer/NavigationDrawer'
import { webSocketLiveChatPath } from '../Utils/constants'
import Sender from './Messages/Sender'
import Receiver from './Messages/Receiver'

//Style
const useStyles = makeStyles(theme => ({
  submit: {
    fontWeight: 'bold'
  },
  cards: {
    boxShadow: '0 0 2px rgba(34, 35, 58, 0.2)'
  },
  root: {
    display: 'flex'
  },
  boxHeight: {
    [theme.breakpoints.only('xs')]: {
      minHeight: '70vh'
    },
    [theme.breakpoints.only('sm')]: {
      minHeight: '74vh'
    },
    [theme.breakpoints.only('md')]: {
      minHeight: '72vh'
    },
    [theme.breakpoints.only('lg')]: {
      minHeight: '73vh'
    },
    [theme.breakpoints.only('xl')]: {
      minHeight: '68vh'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginTop: '4%'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '3%'
    }
  },
  large: {
    paddingBottom: 0,
    height: '100vh',
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(4.5),
      fontSize: 14,
      height: theme.spacing(4.5)
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(5.5),
      fontSize: 16,
      height: theme.spacing(5.5)
    },
    [theme.breakpoints.only('md')]: {
      width: theme.spacing(5.5),
      fontSize: 18,
      height: theme.spacing(5.5)
    },
    [theme.breakpoints.only('lg')]: {
      width: theme.spacing(7),
      fontSize: 20,
      height: theme.spacing(7)
    },
    [theme.breakpoints.only('xl')]: {
      width: theme.spacing(7),
      fontSize: 22,
      height: theme.spacing(7)
    }
  },
  purple: {
    backgroundColor: '#6251FE'
  },
  cardCont: {
    padding: 16
  },
  paper: {
    width: '100%',
    padding: '3%',
    paddingBottom: '7%'
  },
  heading: {
    fontWeight: 'bold',
    paddingTop: '9px'
  },
  label: {
    float: 'center'
  },
  subHeading: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  },
  grid: {
    paddingRight: '2%',
    paddingLeft: '2%'
  }
}))

const Chat = props => {
  const [messages, setMessages] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const theme = useTheme()
  const classes = useStyles()
  const jwt = useSelector(state => state.driver.jwt)
  const orderDetails = useSelector(state => state.order.orderDetail)
  const themeType = useSelector(state => state.themes.theme)
  const { orderId } = useParams()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const [initialValues, setInitialValues] = useState({
    message: ''
  })

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const id = open ? 'simple-popover' : undefined

  let URL = webSocketLiveChatPath + 'userChat'
  const [ws, setWs] = useState(new WebSocket(URL, jwt))

  const submitMessage = msg => {
    var messageObj = {
      userID: orderDetails.user._id,
      driverID: orderDetails.driver._id,
      orderID: orderId,
      senderType: 'driver',
      receiverType: 'user',
      messageContent: msg,
      type: 'message' //types can be 'sendAll' / 'message' / 'connect'
    }
    ws.send(JSON.stringify(messageObj))
  }

  useEffect(() => {
    ws.onopen = () => {
      console.log('WebSocket Connected')

      var messageObj = {
        userID: orderDetails.user._id,
        driverID: orderDetails.driver._id,
        orderID: orderId,
        senderType: 'driver',
        receiverType: 'user',
        messageContent: 'connect',
        type: 'connect' //types can be 'sendAll' / 'message' / 'connect'
      }
      ws.send(JSON.stringify(messageObj))
    }

    ws.onmessage = e => {
      const messageObj = JSON.parse(e.data)
      console.log('Message Received:', messageObj)
      if (Array.isArray(messageObj)) {
        let msgArray = messageObj.map(msg => {
          return {
            user:
              msg.senderType === 'user'
                ? orderDetails.user.name
                : orderDetails.driver.name,
            message: msg.messageContent,
            timeStamp: msg.timeStamp,
            received: true,
            senderType: msg.senderType
          }
        })
        if (!messages.length) {
          setMessages([...messages, ...msgArray])
        }
      } else {
        const message = {
          user:
            messageObj.senderType === 'user'
              ? orderDetails.user.name
              : orderDetails.driver.name,
          message: messageObj.messageContent,
          timeStamp: messageObj.timeStamp,
          received: true,
          senderType: messageObj.senderType
        }
        setMessages([...messages, message])
      }
    }

    return () => {
      ws.onclose = () => {
        console.log('WebSocket Disconnected')
        setWs(new WebSocket(URL))
      }
    }
  }, [ws.onmessage, ws.onopen, ws.onclose, messages])

  const name = orderDetails?.user?.name ? orderDetails?.user?.name : 'User'
  let myStr2 = name.toUpperCase()
  let first = myStr2.match(/\b(\w)/g)
  const firstChar = first.slice(0, 3)

  return (
    <div className={classes.root}>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box>
          <Box className={classes.cards}>
            <CardContent className={classes.cardCont}>
              <Grid container>
                <Grid
                  item
                  xs={1}
                  lg={1}
                  container
                  justifyContent='center'
                  alignItems='center'
                >
                  <Avatar className={clsx(classes.large, classes.purple)}>
                    {/* {orderDetails?.user?.name
                      ? (orderDetails?.user?.name).match(/\b(\w)/g)
                      : null} */}
                    {firstChar}
                  </Avatar>
                </Grid>
                <Grid item xs={10} lg={10}>
                  <Typography variant='h6' className={classes.heading}>
                    {orderDetails?.user?.name}
                  </Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <label htmlFor='icon-button-file' className={classes.label}>
                    <IconButton aria-label='calling' onClick={handleClick}>
                      {lgMatch ? (
                        <img
                          alt='call'
                          src='/assets/images/call.png'
                          className={classes.call}
                        />
                      ) : (
                        <img
                          alt='call'
                          src='/assets/images/call/callSmallicon.png'
                          className={classes.call}
                        />
                      )}
                    </IconButton>
                  </label>
                  <Popover
                    id={id}
                    open={open}
                    elevation={2}
                    className={classes.pop}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <Paper className={classes.paper}>
                      <Typography
                        color='secondary'
                        className={classes.subHeading}
                      >
                        Phone Number: {orderDetails?.user?.phone}
                      </Typography>
                    </Paper>
                  </Popover>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
        </Box>
        <ScrollableFeed className={classes.boxHeight}>
          <Grid container>
            {messages.map((message, index) =>
              message.senderType === 'user' ? (
                <Grid item xs={12} lg={12}>
                  <Sender
                    msgTitle={message.user}
                    msgMessage={message.message}
                    msgTime={moment(message.timeStamp).fromNow()}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Receiver
                    msgImage={'/assets/images/clark.png'}
                    msgMessage={message.message}
                    msgTime={moment(message.timeStamp).fromNow()}
                  />
                </Grid>
              )
            )}
          </Grid>
        </ScrollableFeed>
        <Box pt={1} mt={10}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              console.log('message', values.message)
              submitMessage(values.message)
              resetForm({})
            }}
          >
            {({ errors, handleChange, touched, values }) => (
              <Form className={classes.form}>
                <Grid
                  container
                  style={{
                    opacity: '100%',
                    backgroundColor: { themeType: 'dark' ? 'none' : '#F0F0F0' },
                    position: 'fixed',
                    bottom: 0
                  }}
                  alignItems='center'
                >
                  <Grid
                    item
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    className={classes.grid}
                  >
                    <TextField
                      variant='outlined'
                      margin='normal'
                      // style={themeType==='dark' ? {backgroundColor:'none'} : {backgroundColor: 'white'}}
                      required
                      fullWidth
                      type='text'
                      id='message'
                      name='message'
                      onChange={handleChange}
                      value={values.message || ''}
                      placeholder='Type Here'
                      multiline
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={2}
                    sm={1}
                    md={2}
                    lg={1}
                    direction='column'
                    display='flex'
                    justifyContent='center'
                  >
                    <Button
                      type='submit'
                      color='primary'
                      // fullWidth
                      variant='contained'
                      className={classes.submit}
                      value={'Send'}
                    >
                      <Send />
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </main>
    </div>
  )
}

export default Chat
