//Libraries
import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Grid,
  Hidden,
  useTheme,
  useMediaQuery,
  TablePagination,
  CircularProgress,
  Box
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

//Components
import actions from '../../../Redux/Order/Order.actions'
import history from '../../../Utils/history'
import NoOrders from '../../../Components/UserRequests/NoOrders.component'
import ActiveCard from './activeCard'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '80vh'
  },
  content: {
    [theme.breakpoints.only('xs')]: {
      marginRight: '4%',
      marginTop: '3%'
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: '2%',
      marginTop: '2%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '1%',
      paddingLeft: '2.5%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '1%',
      paddingLeft: '2.5%'
    },
    [theme.breakpoints.only('xl')]: {
      paddingTop: '2%',
      paddingLeft: '2.5%'
    }
  },
  progress: {
    marginTop: '30%',
    marginLeft: '50%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '30px',
      height: '30px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '40px',
      height: '40px'
    },
    [theme.breakpoints.only('xl')]: {
      width: '50px',
      height: '50px'
    }
  }
}))

const ActiveSection = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const SMatches = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const lgMatch = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const activityStatus = useSelector(state => state.driver.activityStatus)
  const [page, setPage] = useState(0)
  const allOrders = useSelector(state => state.order.activeOrders)
  const rows = lgMatch ? 6 : SMatches ? 4 : 8

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const jwt = useSelector(state => state.driver.jwt)
  useEffect(() => {
    dispatch(actions.getOrdersList(jwt))
  }, [])

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <>
          <Grid
            container
            spacing={3}
            style={
              activityStatus == 'Disable'
                ? { pointerEvents: 'none', opacity: '0.4' }
                : null
            }
          >
            <Hidden smDown>
              {Array.isArray(allOrders) ? (
                <>
                  {allOrders && allOrders.length > 0 ? (
                    allOrders
                      .slice(page * rows, page * rows + rows)
                      .map((customer, index) => (
                        <React.Fragment key={customer?._id}>
                          <Grid item xs={6} sm={4} md={4} lg={4} xl={3}>
                            <div
                              onClick={() =>
                                history.push(`/order_details/${customer?._id}`)
                              }
                            >
                              <ActiveCard
                                _id={customer?._id}
                                customerSchedule={`${moment(
                                  customer?.dateAndTime
                                ).format('MMMM DD, YYYY h:mm a')}`}
                                day={`${moment(customer?.dateAndTime).format(
                                  'dddd'
                                )}`}
                                customerPickup={
                                  customer?.tripStartLocation?.formattedAddress
                                }
                                customerDropoff={
                                  customer?.tripEndLocation?.formattedAddress
                                }
                              />
                            </div>
                          </Grid>
                        </React.Fragment>
                      ))
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <NoOrders setFieldValue='no' />
                    </Grid>
                  )}
                </>
              ) : (
                <CircularProgress className={classes.progress} />
              )}
            </Hidden>
            <Hidden mdUp>
              {Array.isArray(allOrders) ? (
                <>
                  {allOrders && allOrders.length > 0 ? (
                    allOrders
                      .slice(page * rows, page * rows + rows)
                      .map((customer, index) => (
                        <Grid
                          item
                          key={customer?._id}
                          xs={12}
                          sm={6}
                          md={4}
                          // justify={mdMatch ? 'none' : 'center'}
                        >
                          <div
                            onClick={() =>
                              history.push(`/order_details/${customer?._id}`)
                            }
                          >
                            <ActiveCard
                              _id={customer?._id}
                              customerSchedule={`${moment(
                                customer?.dateAndTime
                              ).format('MMMM DD, YYYY h:mm a')}`}
                              day={`${moment(customer?.dateAndTime).format(
                                'dddd'
                              )}`}
                              customerPickup={
                                customer?.tripStartLocation?.formattedAddress
                              }
                              customerDropoff={
                                customer?.tripEndLocation?.formattedAddress
                              }
                            />
                          </div>
                        </Grid>
                      ))
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <NoOrders setFieldValue='no' />
                    </Grid>
                  )}
                </>
              ) : (
                <CircularProgress className={classes.progress} />
              )}
            </Hidden>
          </Grid>
          <Box mt={3} />
          <Box display='flex' justifyContent='center'>
            {allOrders && allOrders.length > 0 ? (
              <TablePagination
                rowsPerPageOptions={[4]}
                component='div'
                count={allOrders && allOrders.length}
                rowsPerPage={rows}
                page={page}
                onPageChange={handleChangePage}
              />
            ) : null}
          </Box>
          {/* <Pagination
            // page={page}
            count={Math.ceil(allOrders.length / rows)}
            shape='rounded'
            color='primary'
            boundaryCount={2}
            onChangePage={handleChangePage}
          /> */}
          {/* <Box
            display='flex'
            justifyContent='center'
            style={{
              width: '100%',
              marginTop: '15%'
            }}
          >
            <Pagination
              count={allOrders && allOrders.length}
              shape='rounded'
              rowsPerPage={rows}
              // page={page}
              size='small'
              onChangePage={handleChangePage}
            />
          </Box> */}
        </>
      </main>
    </div>
  )
}

export default ActiveSection
