//Libraries
import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Hidden,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core'
import { Link } from 'react-router-dom'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 14
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 18
    }
  },
  content: {
    flexGrow: 1
  },
  maintitle: {
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 16
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 42
    }
  },
  mainbox: {
    width: '100%',
    textAlign: 'center'
  },
  toolbar: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    height: '100vh',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  link: {
    textDecoration: 'none'
  }
}))

export default function NotFound () {
  const classes = useStyles()

  return (
    <div>
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Box className={classes.mainbox}>
            <Typography className={classes.maintitle}>
              404 Page Not Found
            </Typography>
            <Typography className={classes.title}>
              Redirect to{' '}
              <Link to='/' className={classes.link}>
                Home page
              </Link>
            </Typography>
          </Box>
        </div>
      </main>
    </div>
  )
}
