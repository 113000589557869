export const APPLY_THEME = "APPLY_THEME"; 
export const REMEMBER_ME = "REMEMBER_ME";
export const NOTIFICATION_PERMISSION = "NOTIFICATION_PERMISSION";

export const applyTheme = (theme) => {
  return {
    type: APPLY_THEME,
    payload: theme
  };
}

export const rememberMe = (isChecked) => {
  return {
    type: REMEMBER_ME,
    payload: isChecked
  };
}

export const notificationPermission = (isConsent) => {
  return {
    type: NOTIFICATION_PERMISSION,
    payload: isConsent
  };
}