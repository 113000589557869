//Libraries
import React, { useState } from 'react'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import { Row, Item } from '@mui-treasury/components/flex'
import MenuIcon from '@material-ui/icons/Menu'
import {
  MenuList,
  Drawer,
  CssBaseline,
  Typography,
  useScrollTrigger,
  makeStyles,
  useTheme,
  Slide,
  AppBar,
  MenuItem,
  Toolbar,
  Box,
  Button,
  Avatar,
  SwipeableDrawer,
  Hidden,
  IconButton,
  useMediaQuery
} from '@material-ui/core'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

//Components
import history from '../../Utils/history'
import actions from '../../Redux/Driver/Driver.actions'
import { applyTheme } from '../../Redux/Theme/themeActions'

const drawerWidth = 240

//Style
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  paper: {
    background: '#6251FE',
    backgroundColor: '#6251FE'
  },
  list: {
    [theme.breakpoints.between('sm', 'md')]: {
      width: 240
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      height: '100vh'
    },
    backgroundColor: '#6251FE'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  Button: {
    marginLeft: '0.5px'
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: '#6251FE',
    [theme.breakpoints.only('xs')]: {
      width: drawerWidth - 50
    },
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth
    }
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 0
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(13) + 1,
      backgroundColor: '#6251FE'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  menuText: {
    marginRight: '15px',
    textTransform: 'uppercase'
  },
  menuText1: {
    fontWeight: 'bold',
    textAlign: 'right',
    color: 'white',
    marginRight: '15px',
    textTransform: 'uppercase'
  },
  logo: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
    [theme.breakpoints.only('xs')]: {
      marginRight: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 50
    }
  },
  icons: {
    marginRight: 0
  },
  menuitem: {
    marginRight: '6px',
    [theme.breakpoints.between('xs', 'lg')]: {
      marginTop: '10px'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '20px'
    }
  },
  divmenu: {
    color: 'white',
    [theme.breakpoints.only('xs')]: {
      marginTop: '10px',
      marginRight: '6px'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '10px',
      marginRight: '6px'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '10px',
      marginRight: '6px'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '10px',
      marginRight: '6px'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '20px',
      marginRight: '6px'
    }
  },
  divitem: {
    [theme.breakpoints.only('lg')]: {
      marginTop: '10px',
      marginRight: '6px'
    },
    [theme.breakpoints.only('xl')]: {
      minHeight: '47%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    }
  },
  '& .MuiIconButton-root': {
    padding: '0px',
    color: '#000000'
  },
  '& .MuiSvgIcon-root': {
    padding: '0px',
    color: '#000000'
  },
  purple: {
    backgroundColor: '#6251FE'
  },
  large: {
    paddingBottom: 0,
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(4.5),
      fontSize: 14,
      height: theme.spacing(4.5)
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(5.5),
      fontSize: 16,
      height: theme.spacing(5.5)
    },
    [theme.breakpoints.only('md')]: {
      width: theme.spacing(5.5),
      fontSize: 18,
      height: theme.spacing(5.5)
    },
    [theme.breakpoints.only('lg')]: {
      width: theme.spacing(7),
      fontSize: 20,
      height: theme.spacing(7)
    },
    [theme.breakpoints.only('xl')]: {
      width: theme.spacing(7),
      fontSize: 22,
      height: theme.spacing(7)
    }
  },
  box: {
    [theme.breakpoints.only('xs')]: {
      marginTop: '3%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '0.1%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '1%'
    }
  },
  boxRow: {
    marginLeft: 'auto',
    color: 'black',
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      marginTop: '3%'
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: '0.1%'
    },
    [theme.breakpoints.only('md')]: {
      marginRight: '1%'
    },
    [theme.breakpoints.only('lg')]: {
      marginRight: '3%'
    },
    [theme.breakpoints.only('xl')]: {
      marginRight: '4%'
    }
  },
  marginBox: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '16%'
    }
  },
  typo: {
    [theme.breakpoints.down('md')]: {
      marginTop: '6%',
      fontSize: 20
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '8%',
      fontSize: 25.6
    }
  },
  color: {
    color: '#ffbf00'
  },
  menuList: { color: 'white' }
}))

function HideOnScroll (props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
    target: window ? window() : undefined
  })

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default function NavigationDrawer (props) {
  const classes = useStyles()
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const mdMatch = useMediaQuery(theme.breakpoints.only('md'))
  const [open, setOpen] = React.useState(false)
  const [opened, setOpened] = useState(false)
  const themesType = useSelector(state => state.themes.theme)
  const avatarStyles = useDynamicAvatarStyles(
    lgMatch ? { size: 56 } : mdMatch ? { size: 56 } : { size: 36 }
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const themeType = useSelector(state => state.themes.theme)

  const activityStatus = useSelector(state => state.driver.activityStatus)

  //for taking first word of name
  const drivername = useSelector(state => state.driver.name)
  const name = drivername ? drivername : 'Driver'
  let myStr = name
  let firstWord = myStr.split(' ')[0]

  let myStr2 = name.toUpperCase()
  let first = name ? myStr2.match(/\b(\w)/g) : null
  const firstChar = first.slice(0, 3)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const changeThemeHandler = () => {
    if (themesType === 'dark') {
      dispatch(applyTheme('light'))
    } else if (themesType === 'light') {
      dispatch(applyTheme('dark'))
    }
  }

  const logoutFun = () => {
    if (dispatch(actions.signOut())) {
      navigate('/')
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdDown>
        <AppBar
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar>
            <Box className={classes.box}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                className={clsx(classes.Button, {
                  [classes.hide]: open
                })}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <HideOnScroll {...props}>
              <Box className={classes.boxRow}>
                <Row p={1} gap={3}>
                  <Box mt={2}>
                    {themesType === 'dark' ? (
                      <label htmlFor='icon-button-file'>
                        <IconButton onClick={changeThemeHandler}>
                          <Brightness4Icon color='secondary' />
                        </IconButton>
                      </label>
                    ) : (
                      <label htmlFor='icon-button-file'>
                        <IconButton onClick={changeThemeHandler}>
                          <Brightness7Icon className={classes.color} />
                        </IconButton>
                      </label>
                    )}
                  </Box>
                  {/* <Boxs
                    sm={{ mt: '1%' }}
                    md={{ mt: '2%' }}
                    lg={{ mt: '4%' }}
                    xl={{ mt: '4%' }}
                  >
                    <div onClick={() => history.push('/notifications')}>
                      <label htmlFor='icon-button-file'>
                        <IconButton
                          aria-label='upload picture'
                          component='span'
                        >
                          <Badge badgeContent={1} color='secondary'>
                            <NotificationsIcon fontSize='large' />
                          </Badge>
                        </IconButton>
                      </label>
                    </div>
                  </Boxs> */}
                  <Typography className={classes.typo} color='textPrimary'>
                    <b>{firstWord}</b>
                  </Typography>
                  <div onClick={() => history.push('/profile')}>
                    <Item>
                      <Avatar
                        classes={avatarStyles}
                        className={clsx(classes.large, classes.purple)}
                      >
                        {firstChar}
                      </Avatar>
                    </Item>
                  </div>
                </Row>
              </Box>
            </HideOnScroll>
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <div className={classes.toolbar}>
            <Typography
              variant='h5'
              className={clsx(classes.menuText1, {
                [classes.hide]: !open
              })}
            >
              Logo
            </Typography>
            <IconButton
              onClick={handleDrawerClose}
              className={clsx(classes.menuButton, {
                [classes.hide]: !open
              })}
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightRoundedIcon fontSize='large' />
              ) : (
                <img src='/assets/images/menuicons/lefticon.png' alt='Icon' />
              )}
            </IconButton>
          </div>
          <Box className={classes.marginBox}>
            <MenuList className={classes.menuList}>
              <MenuItem
                component={Link}
                id='click-home'
                to='/home'
                className={classes.menuitem}
              >
                <Box marginRight='20px'>
                  <img src='/assets/images/menuicons/home.png' alt='Home' />
                </Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open
                  })}
                >
                  Home
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                to='/profile'
                id='click-profile'
                className={classes.menuitem}
              >
                <Box marginRight='20px'>
                  <img
                    src='/assets/images/menuicons/profile.png'
                    alt='Profile'
                  />
                </Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open
                  })}
                >
                  User Profile
                </Typography>
              </MenuItem>
              {activityStatus === 'Looking for work' ||
              activityStatus === 'Disable' ? (
                <MenuItem
                  id={'click-past-orders'}
                  component={Link}
                  to='/orders'
                  className={classes.menuitem}
                >
                  <img src='/assets/images/menuicons/order.png' alt='Orders' />
                  <Box marginRight='20px' />
                  <Typography
                    className={clsx(classes.menuText, {
                      [classes.hide]: !open
                    })}
                  >
                    Orders
                  </Typography>
                </MenuItem>
              ) : null}
              {activityStatus !== 'Documents Verification Required' ? (
                <MenuItem
                  id={'click-document'}
                  component={Link}
                  to='/document_edit'
                  className={classes.menuitem}
                >
                  <img src='/assets/images/menuicons/van.png' alt='Docs' />
                  <Box marginRight='13px' />
                  <Typography
                    className={clsx(classes.menuText, {
                      [classes.hide]: !open
                    })}
                  >
                    Document
                  </Typography>
                </MenuItem>
              ) : null}
              <MenuItem
                id='click-setting'
                component={Link}
                to='/settings'
                className={classes.menuitem}
              >
                <img src='/assets/images/menuicons/setting.png' alt='Setting' />
                <Box marginRight='20px'></Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open
                  })}
                >
                  Settings
                </Typography>
              </MenuItem>

              <MenuItem
                component={Link}
                id='click-help'
                to='/help'
                className={classes.menuitem}
              >
                <img src='/assets/images/menuicons/Help.png' alt='Help' />
                <Box marginRight='20px'></Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open
                  })}
                >
                  Help
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                to='/invite'
                id='click-invite'
                className={classes.menuitem}
              >
                <img src='/assets/images/menuicons/share.png' alt='Share' />
                <Box marginRight='20px'></Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open
                  })}
                >
                  Share
                </Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                to='/contact_us'
                id='click-contact'
                className={classes.menuitem}
              >
                <Box marginRight='20px'>
                  <img
                    src='/assets/images/menuicons/contact.png'
                    alt='Contact'
                  />
                </Box>
                <Typography
                  className={clsx(classes.menuText, {
                    [classes.hide]: !open
                  })}
                >
                  Contact Us
                </Typography>
              </MenuItem>
              <div className={classes.divitem}>
                <MenuItem
                  component={Button}
                  className={classes.divmenu}
                  onClick={logoutFun}
                >
                  <Box marginRight='20px'>
                    <img
                      src='/assets/images/menuicons/logout.png'
                      alt='Logout'
                    />
                  </Box>
                  <Typography
                    className={clsx(classes.menuText, {
                      [classes.hide]: !open
                    })}
                  >
                    Logout
                  </Typography>
                </MenuItem>
              </div>
            </MenuList>
          </Box>
        </Drawer>
      </Hidden>

      {/* Swipeable Drawer */}
      <Hidden lgUp>
        <AppBar
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar>
            <Box className={classes.box}>
              <IconButton
                style={
                  themeType === 'dark' ? { color: 'white' } : { color: 'black' }
                }
                color='inherit'
                aria-label='open drawer'
                onClick={() => setOpened(true)}
                edge='start'
                className={clsx(classes.Button, {
                  [classes.hide]: open
                })}
              >
                <HideOnScroll {...props}>
                  <MenuIcon />
                </HideOnScroll>
              </IconButton>
            </Box>

            <HideOnScroll {...props}>
              <Box className={classes.boxRow}>
                <Row p={1} gap={3}>
                  <Box mt={1}>
                    {themesType === 'dark' ? (
                      <label htmlFor='icon-button-file'>
                        <IconButton onClick={changeThemeHandler}>
                          <Brightness4Icon color='secondary' />
                        </IconButton>
                      </label>
                    ) : (
                      <label htmlFor='icon-button-file'>
                        <IconButton onClick={changeThemeHandler}>
                          <Brightness7Icon className={classes.color} />
                        </IconButton>
                      </label>
                    )}
                  </Box>
                  {/* <Boxs
                    sm={{ mt: '1%' }}
                    md={{ mt: '2%' }}
                    lg={{ mt: '4%' }}
                    xl={{ mt: '4%' }}
                  >
                    <label htmlFor='icon-button-file'>
                      <IconButton aria-label='upload picture' component='span'>
                        <Badge badgeContent={1} color='secondary'>
                          <NotificationsIcon fontSize='large' />
                        </Badge>
                      </IconButton>
                    </label>
                  </Boxs> */}
                  <Typography className={classes.typo} color='textPrimary'>
                    {' '}
                    <b>{firstWord}</b>
                  </Typography>
                  <div onClick={() => history.push('/profile')}>
                    <Item>
                      <Avatar
                        classes={avatarStyles}
                        className={clsx(classes.large, classes.purple)}
                      >
                        {firstChar}
                      </Avatar>
                    </Item>
                  </div>
                </Row>
              </Box>
            </HideOnScroll>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          anchor='left'
          open={opened}
          onClose={() => setOpened(false)}
          onOpen={() => {}}
          classes={{ paper: classes.paper }}
        >
          <div className={classes.list}>
            <div className={classes.toolbar}>
              <Typography variant='h5' className={classes.logo}>
                Logo
              </Typography>
              <IconButton
                className={classes.icons}
                onClick={() => setOpened(false)}
              >
                {theme.direction === 'rtl' ? (
                  <ChevronRightRoundedIcon fontSize='large' />
                ) : (
                  <img src='/assets/images/menuicons/lefticon.png' alt='Icon' />
                )}
              </IconButton>
            </div>
            <Box className={classes.marginBox}>
              <MenuList className={classes.menuList}>
                <MenuItem
                  component={Link}
                  id='click-home'
                  to='/home'
                  className={classes.menuitem}
                >
                  <Box marginRight='20px'>
                    <img src='/assets/images/menuicons/home.png' alt='home' />
                  </Box>
                  <Typography>Home</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/profile'
                  id='click-profile'
                  className={classes.menuitem}
                >
                  <Box marginRight='20px'>
                    <img
                      src='/assets/images/menuicons/profile.png'
                      alt='profile'
                    />
                  </Box>
                  <Typography className={classes.menuText}>
                    User Profile
                  </Typography>
                </MenuItem>
                {activityStatus === 'Looking for work' ||
                activityStatus === 'Disable' ? (
                  <MenuItem
                    id={'click-past-orders'}
                    component={Link}
                    to='/orders'
                    className={classes.menuitem}
                  >
                    <img src='/assets/images/menuicons/order.png' alt='order' />
                    <Box marginRight='20px' />
                    <Typography className={classes.menuText}>Orders</Typography>
                  </MenuItem>
                ) : null}
                {activityStatus !== 'Documents Verification Required' ? (
                  <MenuItem
                    id={'click-document'}
                    component={Link}
                    to='/document_edit'
                    className={classes.menuitem}
                  >
                    <img src='/assets/images/menuicons/van.png' alt='van' />
                    <Box marginRight='13px' />
                    <Typography className={classes.menuText}>
                      Document
                    </Typography>
                  </MenuItem>
                ) : null}
                {/* <MenuItem
                  component={Link}
                  to='/payment'
                  className={classes.menuitem}
                >
                  <img src='/assets/images/menuicons/coins.png' alt='coins' />
                  <Box marginRight='20px' />
                  <Typography className={classes.menuText}>Coins</Typography>
                </MenuItem> */}
                <MenuItem
                  id='click-setting'
                  component={Link}
                  to='/settings'
                  className={classes.menuitem}
                >
                  <img
                    src='/assets/images/menuicons/setting.png'
                    alt='setting'
                  />
                  <Box marginRight='20px'></Box>
                  <Typography className={classes.menuText}>Settings</Typography>
                </MenuItem>

                <MenuItem
                  component={Link}
                  id='click-help'
                  to='/help'
                  className={classes.menuitem}
                >
                  <img src='/assets/images/menuicons/Help.png' alt='Help' />
                  <Box marginRight='20px'></Box>
                  <Typography className={classes.menuText}>Help</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/invite'
                  id='click-invite'
                  className={classes.menuitem}
                >
                  <img src='/assets/images/menuicons/share.png' alt='share' />
                  <Box marginRight='20px'></Box>
                  <Typography className={classes.menuText}>Share</Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/contact_us'
                  id='click-contact'
                  className={classes.menuitem}
                >
                  <Box marginRight='20px'>
                    <img
                      src='/assets/images/menuicons/contact.png'
                      alt='contact'
                    />
                  </Box>
                  <Typography className={classes.menuText}>
                    Contact Us
                  </Typography>
                </MenuItem>
                <div className={classes.divitem}>
                  <MenuItem
                    component={Button}
                    className={classes.divmenu}
                    onClick={logoutFun}
                  >
                    <Box marginRight='20px'>
                      <img
                        src='/assets/images/menuicons/logout.png'
                        alt='logout'
                      />
                    </Box>
                    <Typography className={classes.menuText}>Logout</Typography>
                  </MenuItem>
                </div>
              </MenuList>
            </Box>
          </div>
        </SwipeableDrawer>
      </Hidden>
    </div>
  )
}
