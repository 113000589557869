//Libraries
import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 18
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 22
    }
  },
  content: {
    flexGrow: 1
  },
  maintitle: {
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 18,
      marginTop: '3%'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 24,
      marginTop: '3%'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 30,
      marginTop: '5%'
    }
  },
  image: {
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '40%',
      height: '30%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '30%',
      height: '20%'
    },
    [theme.breakpoints.only('md')]: {
      width: '30%',
      height: '20%'
    }
  },
  mainbox: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.only('xl')]: {
      padding: '3%'
    }
  },
  toolbar: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  box: {
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: '3%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '2%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '5%'
    }
  }
}))

export default function DriverBlock () {
  const classes = useStyles()
  return (
    <div>
      <main className={classes.content}>
        <div
          className={classes.toolbar}
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <Box className={classes.mainbox}>
            <img
              src='/assets/images/docs.png'
              alt='docs'
              className={classes.image}
            />
            <Typography className={classes.maintitle}>
              Your account is currently blocked.
            </Typography>
            <Typography className={classes.title} color='secondary'>
              Contact to admin for further detail: <br></br>+92 (21) 121-332-331
            </Typography>
          </Box>
        </div>
      </main>
    </div>
  )
}
