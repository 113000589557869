//Libraries
import React from 'react'
import {
  Box,
  Grid,
  Typography,
  Card,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  makeStyles
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

//Components
import actions from '../../Redux/Driver/Driver.actions'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  centered1: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    height: '40%'
  },
  title: {
    marginTop: '2%',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22.5
    }
  },
  helperText: {
    marginLeft: 0,
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px'
    }
  },
  textfields: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.83rem',
      marginTop: '2%'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem',
      marginTop: '2%'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem',
      marginTop: '3%'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.1rem',
      marginTop: '3%'
    }
  },
  button: {
    marginRight: '3%'
  },
  texts: {
    marginTop: '8px'
  },
  dialog: {
    borderRadius: 8
  },
  box: {
    width: '100%'
  },
  submitTrip: {
    marginRight: '3%',
    margin: theme.spacing(2, 0, 0)
  }
}))

export default function DestinationToActive ({
  open,
  orderID,
  zipCode,
  props,
  handleClose
}) {
  const classes = useStyles()
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const dispatch = useDispatch()
  const jwt = useSelector(state => state.driver.jwt)
  const [fullWidth] = React.useState(true)

  const ActiveZipCodeHandler = () => {
    dispatch(actions.updateActiveZipCode(zipCode, jwt))
    handleClose()
  }

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth='xs'
      open={open}
      hideBackdrop={true}
      onClose={handleClose}
      className={classes.dialog}
      aria-labelledby='max-width-dialog-title'
    >
      <Card key={orderID} className={classes.centered1} elevation={2}>
        <Box className={classes.box} p={lgMatch ? 4 : 2}>
          <form className={classes.form}>
            <Typography>
              Would you like to add destination as active zipcode?
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  size='large'
                  style={{ backgroundColor: '#F5F6FE' }}
                  fullWidth
                  className={classes.submitTrip}
                  variant='contained'
                  onClick={handleClose}
                >
                  No
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color='primary'
                  size='large'
                  variant='contained'
                  onClick={ActiveZipCodeHandler}
                  className={classes.submitTrip}
                >
                  Yes
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
    </Dialog>
  )
}
