//Libraries
import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Grid,
  Hidden,
  useTheme,
  useMediaQuery,
  TablePagination,
  CircularProgress,
  Box
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

//Components
import actions from '../../Redux/Order/Order.actions'
import NoOrders from './NoOrders.component'
import { CustomerOfferCard } from './CustomerOfferCard'

//Style
const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    maxWidth: '100%'
  },
  root: {
    width: '100%'
  },
  content: {
    [theme.breakpoints.only('xs')]: {
      marginTop: '3%',
      marginRight: '4%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '3%',
      marginRight: '2%'
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '1%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      paddingLeft: '2%'
    }
  },
  progress: {
    marginTop: '20%',
    marginLeft: '50%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '30px',
      height: '30px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '40px',
      height: '40px'
    },
    [theme.breakpoints.only('xl')]: {
      width: '50px',
      height: '50px'
    }
  }
  // marg: {
  //   marginRight: '1%',
  //   marginTop: '1%'
  // },
  // marg1: {
  //   marginTop: '1%',
  //   marginTop: '1%'
  // }
}))

const CustomerOffers = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const activityStatus = useSelector(state => state.driver.activityStatus)
  const mdMatch = useMediaQuery(theme.breakpoints.up('md'))
  const MedMatch = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const [page, setPage] = useState(0)
  const allOrders = useSelector(state => state.order.allOrders)
  const rows = mdMatch ? 6 : 4

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const jwt = useSelector(state => state.driver.jwt)
  useEffect(() => {
    dispatch(actions.getOrdersList(jwt))
  }, [])

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <>
          <Grid
            container
            // spacing={1}
            style={
              activityStatus === 'Disable'
                ? { pointerEvents: 'none', opacity: '0.4' }
                : null
            }
          >
            <Hidden smDown>
              {Array.isArray(allOrders) ? (
                <>
                  {allOrders && allOrders.length > 0 ? (
                    allOrders
                      .slice(page * rows, page * rows + rows)
                      .map((customer, index) => (
                        <React.Fragment key={customer?._id}>
                          <Grid
                            className={classes.marg}
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={4}
                            key={customer?._id}
                          >
                            <div>
                              <CustomerOfferCard
                                customerId={customer?._id}
                                customerImage={customer?.user?.name}
                                customerTitle={customer?.user?.name}
                                customerDesc={customer?.rideType}
                                customerAmount={
                                  customer?.estimatedPrice?.minimumPrice
                                }
                                customerRating={customer?.user?.userRating}
                                customerSchedule={`${moment(
                                  customer?.dateAndTime
                                ).format('MMMM DD, YYYY h:mm a')}`}
                                day={`${moment(customer?.dateAndTime).format(
                                  'dddd'
                                )}`}
                                customerDriverMatch={`${moment(
                                  customer?.driverMatchTime
                                )
                                  .add(1, 'hours')
                                  .format('DD/M/YYYY h:mm A')}`}
                                customerPickup={
                                  customer?.tripStartLocation?.formattedAddress
                                }
                                customerDropoff={
                                  customer?.tripEndLocation?.formattedAddress
                                }
                              />
                            </div>
                          </Grid>
                        </React.Fragment>
                      ))
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <NoOrders setFieldValue='no' />
                    </Grid>
                  )}
                </>
              ) : (
                <CircularProgress className={classes.progress} />
              )}
            </Hidden>
            <Hidden mdUp>
              {Array.isArray(allOrders) ? (
                <>
                  {allOrders && allOrders.length > 0 ? (
                    allOrders
                      .slice(page * rows, page * rows + rows)
                      .map((customer, index) => (
                        <Grid
                          className={classes.marg1}
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={4}
                          key={customer?._id}
                        >
                          <CustomerOfferCard
                            customerId={customer?._id}
                            customerImage={'assets/images/zara.png'}
                            customerTitle={customer?.user?.name}
                            customerDesc={customer?.rideType}
                            customerDriverMatch={`${moment(
                              customer?.driverMatchTime
                            )
                              .add(1, 'hours')
                              .format('DD/M/YYYY HH:mm A')}`}
                            customerAmount={
                              customer?.estimatedPrice?.minimumPrice
                            }
                            customerRating={customer?.user?.userRating}
                            customerSchedule={`${moment(
                              customer?.dateAndTime
                            )}`}
                            day={`${moment(customer?.dateAndTime).format(
                              'dddd'
                            )}`}
                            customerPickup={
                              customer?.tripStartLocation?.formattedAddress
                            }
                            customerDropoff={
                              customer?.tripEndLocation?.formattedAddress
                            }
                          />
                        </Grid>
                      ))
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <NoOrders setFieldValue='no' />
                    </Grid>
                  )}
                </>
              ) : (
                <CircularProgress className={classes.progress} />
              )}
            </Hidden>
          </Grid>
          <Box mt={3} />
          <Box display='flex' justifyContent='center'>
            {allOrders && allOrders.length > 0 ? (
              <TablePagination
                rowsPerPageOptions={[4]}
                component='div'
                count={allOrders && allOrders.length}
                rowsPerPage={rows}
                page={page}
                onPageChange={handleChangePage}
              />
            ) : null}
          </Box>
        </>
      </main>
    </div>
  )
}

export default CustomerOffers
