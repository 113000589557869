import React, { useState, useEffect } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Offline, Online } from 'react-detect-offline'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  snackbar: {
    width: '-moz-fit-content',
    width: 'fit-content'
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '15px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.1rem'
    }
  }
}))

export default function InternetConnection () {
  const [open, setOpen] = React.useState(true)
  const classes = useStyles()
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return (
    <div>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!isOnline}
        // autoHideDuration={2000}
      >
        <Alert severity='error' className={classes.title}>
          You're offline right now. Check your connection
        </Alert>
      </Snackbar>
      {/* <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOnline}
        // autoHideDuration={2000}
      >
        <Alert severity='error' className={classes.title}>
          You connection was Restore
        </Alert>
      </Snackbar> */}
    </div>
  )
}
