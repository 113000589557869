//Libraries
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper, Typography } from '@material-ui/core'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '10px 10px 2px 10px',
    backgroundColor: '#6251FE',
    color: 'white',
    opacity: '76%',
    width: '-moz-fit-content',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      padding: '1%',
      minWidth: '10%',
      maxWidth: '80%'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '16px',
      padding: '1%',
      minWidth: '8%',
      maxWidth: '70%'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px',
      padding: '0.5%',
      minWidth: '6%',
      maxWidth: '40%'
    }
  },
  typo: {
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px'
    }
  },
  typoTime: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px'
    }
  },
  div: {
    marginLeft: '4%',
    marginTop: '2%'
  },
  box: {
    textAlign: 'end'
  }
}))

export const Receiver = React.memo(function SimpleCard ({
  msgTitle,
  msgMessage,
  msgTime
}) {
  const classes = useStyles()
  return (
    <div className={classes.div}>
      <Paper className={classes.root}>
        {msgMessage}
        <Box className={classes.box}>
          <Typography className={classes.typoTime} variant='body1'>
            {msgTime}
          </Typography>
        </Box>
      </Paper>
    </div>
  )
})
export default Receiver
