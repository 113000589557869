//Libraries
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const Loader = ({ marginTop = '0px', width = '25px', height = '25px' }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: marginTop
      }}
    >
      <CircularProgress style={{ width: width, height: height }} />
    </div>
  )
}

export default Loader
