//Libraries
import React, { useState } from 'react'
import { makeStyles, Box, TextField, Hidden, Button } from '@material-ui/core'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import moment from 'moment'

//Components
import NavigationDrawer from '../../Components/NavigationDrawer/NavigationDrawer'
import Sender from './Sender'
import Receiver from './Receiver'

//Style
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    [theme.breakpoints.only('xs')]: {
      paddingTop: '1%',
      marginTop: '20%',
      marginRight: '5%',
      marginLeft: '3%'
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '1%',
      marginTop: '15%',
      marginRight: '5%',
      marginLeft: '2%'
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '1%',
      marginTop: '15%',
      marginRight: '5%',
      marginLeft: '2%'
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: theme.spacing(3),
      paddingTop: '1%',
      marginTop: '5%',
      marginRight: '5%',
      marginLeft: '5%'
    },
    [theme.breakpoints.only('xl')]: {
      paddingLeft: theme.spacing(3),
      paddingTop: '1%',
      marginTop: '5%',
      marginRight: '5%',
      marginLeft: '5%'
    }
  },
  mainRoot: {
    display: 'flex',
    width: '100%'
  },
  submit: {
    width: '100%',
    height: 56,
    marginLeft: '0%',
    margin: theme.spacing(0.5, 0, 0)
  },
  box: {
    right: '5%',
    [theme.breakpoints.only('xs')]: {
      left: '7%'
    },
    [theme.breakpoints.only('sm')]: {
      left: '8%'
    },
    [theme.breakpoints.only('md')]: {
      left: '6%'
    },
    [theme.breakpoints.only('lg')]: {
      left: '14%',
      marginLeft: '1%'
    },
    [theme.breakpoints.only('xl')]: {
      left: '12%'
    }
  },
  textField: {
    marginRight: '3%'
  }
}))

export default function Messages () {
  const classes = useStyles()
  const params = useParams()
  const messages = useSelector(state => state.chat.chats)
  const driverId = useSelector(state => state.driver._id)
  const [newMessage, setNewMessage] = useState('')

  const socket = useSelector(state => state.chat.socket)

  const sendMessage = socketObj => {
    console.log(socketObj)
    socket?.send(JSON.stringify(socketObj))
  }

  return (
    <div className={classes.mainRoot}>
      <NavigationDrawer />
      <main className={classes.content}>
        {messages?.map(msg => {
          return msg.senderType === 'Driver' ? (
            <Sender
              msgImage={'/assets/images/customer.png'}
              msgMessage={msg.messageContent}
              msgTime={moment(msg.timeStamp).fromNow()}
            />
          ) : (
            <Receiver
              msgImage={'/assets/images/clark.png'}
              msgMessage={msg.messageContent}
              msgTime={moment(msg.timeStamp).fromNow()}
            />
          )
        })}
        <Hidden only='lg'>
          <Box display='flex' mt={30} ml={4} alignContent='flex-end'>
            <TextField
              className={classes.textField}
              id='filled-full-width'
              placeholder='Type Something to send ..........'
              fullWidth
              margin='normal'
              variant='outlined'
              disabled={newMessage?.length ? false : true}
              onChange={e => setNewMessage(e.target.value)}
            />
            <Button
              color='primary'
              size='large'
              variant='contained'
              disabled={newMessage?.length ? false : true}
              className={classes.submit}
              onClick={() =>
                sendMessage({
                  userID: params?.userId,
                  driverID: driverId,
                  orderID: params?.orderId,
                  senderType: 'Driver',
                  receiverType: 'User',
                  messageContent: newMessage,
                  type: 'message'
                })
              }
            >
              Send
            </Button>
          </Box>
        </Hidden>
        <Hidden only='xl'>
          <Box display='flex' mt={14} ml={1} alignContent='flex-end'>
            <TextField
              className={classes.textField}
              placeholder='Type Something to send ..........'
              fullWidth
              margin='normal'
              variant='outlined'
              onChange={e => setNewMessage(e.target.value)}
            />
            <Button
              color='primary'
              size='large'
              variant='contained'
              disabled={newMessage?.length ? false : true}
              className={classes.submit}
              onClick={() =>
                sendMessage({
                  userID: params?.userId,
                  driverID: driverId,
                  orderID: params?.orderId,
                  senderType: 'Driver',
                  receiverType: 'User',
                  messageContent: newMessage,
                  type: 'sendAll'
                })
              }
            >
              Send
            </Button>
          </Box>
        </Hidden>
      </main>
    </div>
  )
}
