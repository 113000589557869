//Libraries
import React from 'react'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import {
  Typography,
  Box,
  Grid,
  CssBaseline,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

//Components
import actions from '../../../Redux/Order/Order.actions'

//Style
const useStyles = makeStyles(theme => ({
  card: {
    height: 'auto',
    borderRadius: 8,
    padding: '20px',
    marginLeft: '1%',
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)'
  },
  heading: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18
    }
  },
  subheading: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18
    }
  },
  divider: {
    width: '90%'
  }
}))

export const ActiveCard = React.memo(function CustomerCard ({
  _id,
  customerImage,
  customerTitle,
  customerDesc,
  customerAmount,
  customerRating,
  customerSchedule,
  day,
  customerPickup,
  customerDropoff
}) {
  const classes = useStyles()

  return (
    <div>
      <CssBaseline />
      <Card className={classes.card}>
        <Typography variant='subtitle2' className={classes.heading}>
          {day}, {customerSchedule}
        </Typography>
        <Box mt={2} />
        <Grid container direction='row' alignItems='center'>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <img
              src='/assets/images/pick.png'
              alt='pick'
              className={classes.image}
            />
          </Grid>
          <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              alignItems='flex-start'
            >
              <Typography variant='body1' className={classes.subheading}>
                {customerPickup}
              </Typography>
              <Box mt={3} />
              <Divider className={classes.divider} />
              <Box m={1} />
              <Typography variant='body1' className={classes.subheading}>
                {customerDropoff}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
})

export default ActiveCard
