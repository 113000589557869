//Libraries
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Hidden,
  Zoom,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  CircularProgress,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Snackbar,
  Avatar,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { Autocomplete, Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

//Components
import Yup from "../Yup/YupAuthValidation.schemas";
import actions from "../Redux/Driver/Driver.actions";
import NavigationDrawer from "../Components/NavigationDrawer/NavigationDrawer";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
//Style
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.only("md")]: {
      width: "65%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "40%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "35%",
    },
  },
  customTextField: {
    "& input::placeholder": {
      [theme.breakpoints.between("xs", "md")]: {
        fontSize: 13,
      },
      [theme.breakpoints.between("lg", "xl")]: {
        fontSize: 18,
      },
    },
  },
  content: {
    flexGrow: 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.only("xs")]: {
      marginTop: "25%",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "15%",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: "8%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "6%",
    },
  },
  purple: {
    backgroundColor: "#6251FE",
  },
  input: {
    "&::placeholder": {
      paddingTop: "4.5%",
    },
  },
  fab: {
    float: "right",
    margin: theme.spacing(2, 0, 0),
    fontWeight: "bold",
  },
  fabLeft: {
    float: "right",
    margin: theme.spacing(2, 0, 0),
    fontWeight: "bold",
    [theme.breakpoints.only("xs")]: {
      left: "65px",
    },
    [theme.breakpoints.up("sm")]: {
      left: "100px",
    },
  },
  large: {
    [theme.breakpoints.only("xs")]: {
      width: theme.spacing(13),
      fontSize: 24,
      height: theme.spacing(13),
    },
    [theme.breakpoints.only("sm")]: {
      width: theme.spacing(15),
      fontSize: 26,
      height: theme.spacing(15),
    },
    [theme.breakpoints.only("md")]: {
      width: theme.spacing(16),
      fontSize: 28,
      height: theme.spacing(16),
    },
    [theme.breakpoints.only("lg")]: {
      width: theme.spacing(17),
      fontSize: 30,
      height: theme.spacing(17),
    },
    [theme.breakpoints.only("xl")]: {
      width: theme.spacing(17),
      fontSize: 38,
      height: theme.spacing(17),
    },
  },
  div: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  progress: {
    width: "50px",
    height: "50px",
  },
  textfield: {
    marginTop: "1%",
  },
  textLeft: {
    float: "left",
  },
}));

const topZipCodes = [
  { title: "EC1A", city: "London" },
  { title: "EC1V", city: "London" },
  { title: "EC2A", city: "London" },
  { title: "E1", city: "London" },
  { title: "N1", city: "London" },
  { title: "NW1", city: "London" },
  { title: "SE1", city: "London" },
  { title: "W1", city: "London" },
  { title: "B1", city: "Birmingham" },
  { title: "B2", city: "Birmingham" },
  { title: "B3", city: "Birmingham" },
  { title: "B4", city: "Birmingham" },
  { title: "M1", city: "Manchester" },
  { title: "M2", city: "Manchester" },
  { title: "M3", city: "Manchester" },
  { title: "L1", city: "Liverpool" },
  { title: "L2", city: "Liverpool" },
  { title: "L3", city: "Liverpool" },
  { title: "G1", city: "Glasgow" },
  { title: "G2", city: "Glasgow" },
  { title: "EH1", city: "Edinburgh" },
  { title: "EH2", city: "Edinburgh" },
  { title: "BS1", city: "Bristol" },
  { title: "BS2", city: "Bristol" },
  { title: "LS1", city: "Leeds" },
  { title: "LS2", city: "Leeds" },
  { title: "NE1", city: "Newcastle" },
  { title: "NE2", city: "Newcastle" },
  { title: "NG1", city: "Nottingham" },
  { title: "NG2", city: "Nottingham" },
  { title: "CF10", city: "Cardiff" },
  { title: "CF11", city: "Cardiff" },
  { title: "BA1", city: "Bath" },
  { title: "BA2", city: "Bath" },
  { title: "BT1", city: "Belfast" },
  { title: "BT2", city: "Belfast" },
  { title: "PO1", city: "Portsmouth" },
  { title: "PO2", city: "Portsmouth" },
  { title: "SO14", city: "Southampton" },
  { title: "SO15", city: "Southampton" },
  { title: "OX1", city: "Oxford" },
  { title: "OX2", city: "Oxford" },
  { title: "CB1", city: "Cambridge" },
  { title: "CB2", city: "Cambridge" },
  { title: "SR1", city: "Sunderland" },
  { title: "SR2", city: "Sunderland" },
  { title: "TS1", city: "Middlesbrough" },
  { title: "TS2", city: "Middlesbrough" },
];

const MyProfile = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editDisabled, setEditDisabled] = useState(true);
  const [animateFab, setAnimateFab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const jwt = useSelector((state) => state.driver.jwt);

  useEffect(() => {
    console.log("clearing previous error");
    dispatch(actions.clearError());
  }, []);

  const updateDriverSuccess = useSelector(
    (state) => state.driver.success.updateDriverSuccess
  );

  const updateDriverError = useSelector(
    (state) => state.driver.errors.updateDriverError
  );

  const driverData = useSelector((state) => state.driver);
  const [chips, setChips] = useState(driverData?.activeZipcodes || []);
  const [profile, setProfile] = useState(
    driverData
      ? {
          name: driverData?.name,
          email: driverData.email,
          city: driverData?.city,
          state: driverData?.state,
          address: driverData?.address,
          zipcode: driverData?.zipcode,
          activeZipcodes: driverData?.activeZipcodes,
        }
      : {
          name: "",
          email: "",
          city: "",
          state: "",
          address: "",
          zipcode: "",
          activeZipcodes: [],
        }
  );

  const onClickFab = () => {
    if (animateFab === 0) {
      setAnimateFab(1);
      setEditDisabled(false);
    } else if (animateFab === 1) {
      setAnimateFab(0);
      setEditDisabled(true);
      setOpenDialog(true);
    }
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDialogNo = () => {
    setOpenDialog(false);
    setEditDisabled(false);
  };

  const handleDialogYes = () => {
    dispatch(actions.updateDriver(profile, jwt));
    setAnimateFab(0);
    setOpenDialog(false);
    setEditDisabled(true);
  };

  const [value, setValue] = React.useState(
    driverData ? driverData?.activeZipcodes : []
  );

  useEffect(() => {
    if (driverData) {
      setProfile(() => {
        return {
          name: driverData?.name,
          email: driverData.email,
          city: driverData?.city,
          state: driverData?.state,
          address: driverData?.address,
          zipcode: driverData?.zipcode,
          activeZipcodes: driverData?.activeZipcodes,
        };
      });
      setValue(driverData?.activeZipcodes);
      setLoading(false);
    }
  }, [driverData]);

  const fabs = [
    {
      id: "cl-edit",
      color: "primary",
      className: classes.fab,
      icon: <img src="/assets/images/edit.png" alt="edit" />,
      label: "Edit",
    },
    {
      id: "cl-save",
      color: "primary",
      className: classes.fabLeft,
      icon: <img src="/assets/images/Tick.png" alt="save" />,
      label: "Save",
    },
  ];

  const [openSnackUpdateDriver, setOpenSnackUpdateDriver] = useState(false);

  const handleErrorSnackUpdateDriver = () => {
    setOpenSnackUpdateDriver(true);
  };

  const handleCloseSnackUpdateDriver = (event, reason) => {
    dispatch(actions.clearError());
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackUpdateDriver(false);
  };

  useEffect(() => {
    if (updateDriverError && !updateDriverSuccess) {
      handleErrorSnackUpdateDriver();
    } else if (updateDriverSuccess && !updateDriverError) {
      handleErrorSnackUpdateDriver();
    }
  }, [updateDriverError, updateDriverSuccess]);

  const driverName = useSelector((state) => state.driver.name);
  const name = driverName ? driverName : "Driver";

  let myStr = name.toUpperCase();
  let first = myStr.match(/\b(\w)/g);
  const firstChar = first.slice(0, 3);

  console.log(firstChar);

  const [inputValue, setInputValue] = React.useState("");
  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          {loading ? (
            <div className={classes.div}>
              <CircularProgress className={classes.progress} />
            </div>
          ) : (
            <>
              <Box className={classes.root}>
                <Grid container align="center">
                  <Grid item xs={12}>
                    <Avatar className={clsx(classes.large, classes.purple)}>
                      {firstChar}
                    </Avatar>
                  </Grid>
                </Grid>
                <Formik
                  initialValues={profile}
                  validationSchema={Yup.profileValidationSchema}
                  onSubmit={(values, value) => {
                    console.log("values ok", values);
                    console.log(values.activeZipcodes);
                    setProfile({
                      ...profile,
                      name: values?.name,
                      email: values?.email,
                      activeZipcodes: values?.activeZipcodes,
                      city: values?.city,
                      address: values?.address,
                      zipcode: values?.zipcode,
                    });
                     setOpenDialog(true);
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    handleSubmit,
                    touched,
                    setFieldValue,
                    values,
                  }) => (
                    <Form
                      className={classes.form}
                      id="profileForm"
                      onSubmit={handleSubmit}
                    >
                      <Box mt={3}>
                        <Typography
                          color="textPrimary"
                          className={classes.textLeft}
                        >
                          Full Name
                        </Typography>
                      </Box>
                      <TextField
                        error={errors.name && touched.name}
                        helperText={
                          errors.name && touched.name ? errors.name : null
                        }
                        id="name"
                        name="name"
                        fullWidth
                        className={classes.textfield}
                        variant="outlined"
                        disabled={editDisabled}
                        defaultValue={driverData.name}
                        onChange={handleChange}
                      />
                      <Box mt={3}>
                        <Typography
                          color="textPrimary"
                          className={classes.textLeft}
                        >
                          Email Address
                        </Typography>
                      </Box>
                      <TextField
                        id="email"
                        fullWidth
                        variant="outlined"
                        className={classes.textfield}
                        name="email"
                        autoComplete="email"
                        disabled={editDisabled}
                        defaultValue={driverData.email}
                        onChange={handleChange}
                      />
                      <Box mt={3}>
                        <Typography
                          color="textPrimary"
                          className={classes.textLeft}
                        >
                          Phone Number
                        </Typography>
                      </Box>
                      <TextField
                        id="phone"
                        fullWidth
                        className={classes.textfield}
                        variant="outlined"
                        name="phone"
                        type="tel"
                        disabled={true}
                        defaultValue={driverData.phone}
                      />
                      <Box mt={3}>
                        <Typography className={classes.textLeft}>
                          Active ZipCode
                        </Typography>
                      </Box>
                      <Autocomplete
                        multiple
                        freeSolo
                        id="activeZipcodes"
                        name="activeZipcodes"
                        limitTags={4}
                        disabled={editDisabled}
                        options={topZipCodes.map((option) => option?.title)}
                        getOptionLabel={(option) => option?.title || option}
                        onChange={(event, newValue) => {
                          setChips(newValue);
                          setFieldValue(
                            "activeZipcodes",
                            newValue
                          );
                          setInputValue("");
                        }}
                        value={chips}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                          
                              key={index}
                              label={option}
                              {...getTagProps({ index })}
                              onDelete={() => {
                                const newChips = chips.filter(
                                  (chipToRemove) => chipToRemove !== option
                                );
                                setChips(newChips);
                                setFieldValue("activeZipcodes", newChips);
                              }}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            id="activeZipcodes"
                            name="activeZipcodes"
                            label="Zip Code (Driving)"
                            classes={{ root: classes.customTextField }}
                            fullWidth
                            error={Boolean(
                              errors.activeZipcodes && touched.activeZipcodes
                            )}
                            helperText={
                              errors.activeZipcodes && touched.activeZipcodes
                                ? errors.activeZipcodes
                                : "Note:Click the check button after entering a Valid Zip Code"
                            }
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end" style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: 10 }}>
                                <IconButton
                                  edge="end"
                                  color="primary"
                                  onClick={() => {
                                    if (inputValue.trim() !== "") {
                                      const newChips = [...chips, inputValue.trim()];
                                      setChips(newChips);
                                      setFieldValue("activeZipcodes", newChips);
                                      setInputValue("");
                                    }
                                  }}
                                >
                                  <CheckCircleIcon />
                                </IconButton>
                              </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                      {/* <Autocomplete
                        multiple
                        freeSolo
                        id="activeZipcodes"
                        name="activeZipcodes"
                        limitTags={5}
                        disabled={editDisabled}
                        defaultValue={
                          profile.activeZipcodes ? profile.activeZipcodes : ""
                        }
                        options={topZipCodes.map((option) => option.title)}
                        getOptionLabel={(option) => option.title || option}
                        fullWidth
                        onChange={(e, value) => {
                          setFieldValue(
                            "activeZipcodes",
                            value !== null ? value : profile.activeZipcodes
                          );
                        }}
                        //Comma delimmiter code comment default value and use value instead
                        // value={profile.activeZipcodes}
                        // inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //   console.log('event', event)
                        //   if (event) {
                        //     if (event.key === 'Enter') {
                        //       event.stopPropagation()
                        //       console.log('enter key', event.key)
                        //       profile.activeZipcodes = profile.activeZipcodes
                        //         .concat(event.target.value)
                        //         .map(x => x.trim())
                        //         .filter(x => x)

                        //       console.log('active', profile.activeZipcodes)
                        //       setProfile({ ...profile })
                        //       console.log('profile', profile)

                        //       setFieldValue(
                        //         'activeZipcodes',
                        //         value !== null
                        //           ? profile.activeZipcodes
                        //           : profile.activeZipcodes
                        //       )
                        //     }
                        //   }
                        //   const options = newInputValue.split(',')
                        //   if (options.length > 1) {
                        //     // values.activeZipcodes = value
                        //     //   .concat(options)
                        //     //   .map(x => x.trim())
                        //     //   .filter(x => x)
                        //     profile.activeZipcodes = profile.activeZipcodes
                        //       .concat(options)
                        //       .map(x => x.trim())
                        //       .filter(x => x)

                        //     console.log('value', value)

                        //     console.log('active', profile.activeZipcodes)
                        //     setProfile({ ...profile })
                        //     console.log('profile', profile)

                        //     setFieldValue(
                        //       'activeZipcodes',
                        //       value !== null
                        //         ? profile.activeZipcodes
                        //         : profile.activeZipcodes
                        //     )
                        //   } else {
                        //     setInputValue(newInputValue)
                        //   }
                        // }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              className={classes.text}
                              classes={{ root: classes.customTextField }}
                              error={
                                errors.activeZipcodes && touched.activeZipcodes
                              }
                              helperText={
                                errors.activeZipcodes && touched.activeZipcodes
                                  ? errors.activeZipcodes
                                  : "Note: Hit enter/comma after entering a Valid Zip Code"
                              }
                              // name='activeZipcodes'
                              // id='activeZipcodes'
                              // onChange={handleChange}
                              // disabled={editDisabled}
                              margin="normal"
                              variant="outlined"
                            />
                          );
                        }}
                      /> */}

                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Box mt={3}>
                            <Typography
                              color="textPrimary"
                              className={classes.textLeft}
                            >
                              City
                            </Typography>
                          </Box>
                          <TextField
                            error={errors.city && touched.city}
                            helperText={
                              errors.city && touched.city ? errors.city : null
                            }
                            id="city"
                            variant="outlined"
                            fullWidth
                            className={classes.textfield}
                            name="city"
                            autoComplete="city"
                            disabled={editDisabled}
                            defaultValue={
                              driverData.city ? driverData.city : ""
                            }
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Box mt={3}>
                            <Typography className={classes.textLeft}>
                              Zipcode
                            </Typography>
                          </Box>
                          <TextField
                            error={errors.zipcode && touched.zipcode}
                            helperText={
                              errors.zipcode && touched.zipcode
                                ? errors.zipcode
                                : null
                            }
                            id="zipcode"
                            variant="outlined"
                            fullWidth
                            className={classes.textfield}
                            name="zipcode"
                            autoComplete="zipcode"
                            disabled={editDisabled}
                            defaultValue={
                              driverData.zipcode ? driverData.zipcode : ""
                            }
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Box mt={3}>
                        <Typography
                          color="textPrimary"
                          className={classes.textLeft}
                        >
                          Address
                        </Typography>
                      </Box>
                      <TextField
                        error={errors.address && touched.address}
                        helperText={
                          errors.address && touched.address
                            ? errors.address
                            : null
                        }
                        id="address"
                        name="address"
                        fullWidth
                        className={classes.textfield}
                        multiline={true}
                        rows={2}
                        variant="outlined"
                        defaultValue={
                          driverData.address ? driverData.address : ""
                        }
                        disabled={editDisabled}
                        onChange={handleChange}
                      />
                    </Form>
                  )}
                </Formik>
                {fabs.map((fab, index) => (
                  <Zoom key={fab.id} in={animateFab === index} timeout={400}>
                    <Button
                      type={fab.label === "Save" ? "submit" : null}
                      form={fab.label === "Save" ? "profileForm" : null}
                      aria-label={fab.label}
                      className={fab.className}
                      color={fab.color}
                      onClick={fab.label === "Save" ? null : onClickFab}
                      variant="contained"
                    >
                      {fab.icon}
                      <Hidden xsDown>
                        <Box m="10px">{fab.label}</Box>
                      </Hidden>
                    </Button>
                  </Zoom>
                ))}
                <Dialog
                  open={openDialog}
                  onClose={onCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  // disableBackdropClick={true}
                  hideBackdrop={true}
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Update Profile?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to update your profile?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      id="click-no-btn"
                      onClick={handleDialogNo}
                      variant="contained"
                    >
                      No
                    </Button>
                    <Button
                      id="click-yes-btn"
                      onClick={handleDialogYes}
                      color="primary"
                      autoFocus
                      variant="contained"
                    >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </>
          )}
        </div>
      </main>
      {updateDriverError || updateDriverSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackUpdateDriver}
          autoHideDuration={2000}
          onClose={handleCloseSnackUpdateDriver}
        >
          <Alert
            onClose={handleCloseSnackUpdateDriver}
            severity={updateDriverError ? "error" : "success"}
          >
            {updateDriverError ? updateDriverError : updateDriverSuccess}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};
export default MyProfile;
