//Libraries
import React, { useEffect, useState } from 'react'
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  Link,
  makeStyles,
  CssBaseline,
  Hidden,
  Paper,
  Snackbar
} from '@material-ui/core'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '@material-ui/lab'
import Loader from '../../../Components/Loader/Loader'
//Components
import NavigationDrawer from '../../../Components/NavigationDrawer/NavigationDrawer'
import actions from '../../../Redux/Driver/Driver.actions'
import Yup from '../../../Yup/YupAuthValidation.schemas'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1.17rem',
      textAlign: 'left'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.5rem',
      textAlign: 'left'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '2.03rem',
      textAlign: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '2.1rem',
      textAlign: 'center'
    }
  },
  subtitle: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.125rem'
    }
  },
  form: {
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(4),
      alignItems: 'left'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.spacing(4),
      alignItems: 'center'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(5),
      alignItems: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(6),
      alignItems: 'center'
    }
  },
  links: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.95rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.0rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.155rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.125rem'
    }
  },
  mainlinks: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.95rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.0rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.155rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.125rem'
    }
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(3, 0, 2)
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(7, 0, 2)
    }
  },
  image: {
    alignItems: 'center',
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(12),
      width: '90%',
      height: 'auto'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(14)
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(4, 4),
      alignItems: 'left'
    },
    [theme.breakpoints.only('sm')]: {
      margin: theme.spacing(12, 14),
      alignItems: 'left'
    },
    [theme.breakpoints.only('md')]: {
      margin: theme.spacing(18, 14),
      alignItems: 'left'
    },
    [theme.breakpoints.only('lg')]: {
      margin: theme.spacing(8, 15),
      alignItems: 'center',
      textAlign: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(18, 28),
      elevation: '0',
      alignItems: 'center'
    }
  },
  textfields: {
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.1rem'
    }
  },
  box: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center'
    }
  },
  boximg: {
    [theme.breakpoints.only('lg')]: {
      marginTop: '10%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '15%'
    }
  },
  forgot: {
    [theme.breakpoints.only('xs')]: {
      marginTop: '15%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '12%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '6%'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 6
    }
  },
  text: {
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      marginTop: '1%'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '2%'
    }
  },
  container: {
    display: 'inline-block'
  }
}))

export default function ForgotPassword (props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isForgotPassword = true
  const forgotPasswordError = useSelector(
    state => state.driver.errors.forgotPasswordError
  )
  const loading = useSelector(state => state.driver.isFetching)
  const isLoggedIn = useSelector(state => state.driver.isLoggedIn)

  useEffect(() => {
    console.log('clearing previous error')
    dispatch(actions.clearError())
  }, [])

  const initialTimer = localStorage.getItem('secondPhone1') ?? 60
  const timeoutId1 = React.useRef(null)
  const [secondPhone, setSecondsPhone] = React.useState(initialTimer)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const countTimer1 = React.useCallback(() => {
    if (secondPhone <= 0) {
      localStorage.clear('secondPhone1')
    } else {
      setSecondsPhone(secondPhone - 1)
      localStorage.setItem('secondPhone1', secondPhone - 1)
    }
  }, [secondPhone])

  React.useEffect(() => {
    if (secondPhone > 0) {
      timeoutId1.current = window.setTimeout(countTimer1, 1000)
      // cleanup function
      return () => window.clearTimeout(timeoutId1.current)
    } else {
      localStorage.setItem('secondPhone1', secondPhone)
      setSecondsPhone(0)
      setIsButtonDisabled(false)
    }
  }, [secondPhone, countTimer1])

  const resetResendButton = () => {
    setSecondsPhone(60)
    setIsButtonDisabled(true)
  }
  useEffect(() => {
    if (forgotPasswordError) {
      handleErrorSnack()
    }
  }, [forgotPasswordError])
  console.log('forgotPasswordError', forgotPasswordError)

  const [initialValues, setInitialValues] = useState({
    phone: ''
  })

  const [open, setOpen] = useState(false)

  const handleErrorSnack = () => {
    setOpen(true)
  }

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <div>
      {isLoggedIn ? <NavigationDrawer /> : null}
      <Grid container component='main' className={classes.root}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={7}
          component={Paper}
          elevation={0}
          square
        >
          <div className={classes.paper}>
            <Box className={classes.forgot}>
              <Typography variant='h1' align='center' className={classes.title}>
                Did you Forget your Password?
              </Typography>
            </Box>
            <Box mt={'2%'} className={classes.box}>
              <Typography
                variant='caption'
                color='secondary'
                className={classes.subtitle}
                align='center'
              >
                Enter your Email address or phone number You're using for your
                account
              </Typography>
            </Box>

            <Formik
              initialValues={initialValues}
              validationSchema={Yup.ForgotPasswordValidationSchema}
              onSubmit={values => {
                console.log(values)
                dispatch(
                  actions.requestOTP(values.phone, isForgotPassword, props)
                )
                resetResendButton()
              }}
            >
              {({ errors, handleChange, touched }) => (
                <Form className={classes.form}>
                  <Typography
                    variant='body2'
                    className={classes.textfields}
                    align='left'
                  >
                    Email / Phone
                  </Typography>
                  <TextField
                    error={errors.phone && touched.phone}
                    helperText={
                      errors.phone && touched.phone ? errors.phone : null
                    }
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='phone'
                    placeholder='Enter your Phone'
                    name='phone'
                    autoComplete='phone'
                    autoFocus
                    onChange={handleChange}
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={isButtonDisabled}
                  >
                    {loading ? (
                      <Loader />
                    ) : secondPhone === 0 ? (
                      '   Request Reset'
                    ) : (
                      `   Request Reset in ${secondPhone} second`
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
            <Grid container className={classes.container}>
              <Grid item className={classes.text}>
                <Typography className={classes.mainlinks}>
                  Back to
                  {isLoggedIn ? (
                    <Link className={classes.links} href='/reset_password'>
                      {' Reset Password'}
                    </Link>
                  ) : (
                    <Link className={classes.links} href='/sign_in'>
                      {' Sign in'}
                    </Link>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          item
          xs={false}
          align='center'
          sm={false}
          md={false}
          lg={5}
          style={{ backgroundColor: '#ffffff' }}
        >
          <Box className={classes.boximg}>
            <Hidden mdDown>
              <img
                src='/assets/images/pasword.png'
                alt='password'
                className={classes.image}
              />
            </Hidden>
          </Box>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={2000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity={'error'}>
            {forgotPasswordError}
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  )
}
