//Libraries
import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  withStyles,
  Typography,
  Card,
  CardContent,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  makeStyles
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

//Components
import DriverActions from '../../Redux/Driver/Driver.actions'
import Yup from '../../Yup/YupOrderValidation.schemas'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    marginTop: '2%',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22.5
    }
  },
  cancel: {
    margin: theme.spacing(1, 0, 2),
    marginRight: '3%'
  },
  dialog: {
    borderRadius: 8
  },
  centered: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    height: '40%'
  },
  root: {
    width: '100%'
  },
  div: {
    color: '#f44336',
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  },
  box: {
    float: 'right',
    marginRight: '1%'
  }
}))

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: '#6251FE'
    }
  },
  checked: {}
})(props => <Radio {...props} />)

export default function CancelOrder ({ open, orderID, props, handleClose }) {
  const classes = useStyles()
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const dispatch = useDispatch()
  const jwt = useSelector(state => state.driver.jwt)
  const [fullWidth] = React.useState(true)
  const options = [
    {
      id: 1,
      title: 'User not paying'
    },
    {
      id: 2,
      title: 'Car Broke down'
    },
    {
      id: 3,
      title: 'User not available at pickup'
    },
    {
      id: 4,
      title: 'Other'
    }
  ]
  const [initialValues, setInitialValues] = useState({
    value: ''
  })

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth='xs'
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      hideBackdrop={true}
      aria-labelledby='max-width-dialog-title'
    >
      <Card key={orderID} className={classes.centered} elevation={2}>
        <Box className={classes.root} p={lgMatch ? 4 : 2}>
          <CardContent>
            <Typography className={classes.title}>
              <b>Why do you want to cancel order?</b>
            </Typography>
          </CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.orderCancelValidationSchema}
            enableReinitialize={true}
            onSubmit={values => {
              console.log('message', values)
              dispatch(
                DriverActions.cancelOrder(jwt, orderID, values.value, props)
              )
              handleClose()
            }}
          >
            {({ errors, handleChange, touched, values }) => (
              <Form className={classes.form}>
                <FormControl component='fieldset'>
                  <RadioGroup
                    error={errors.value && touched.value}
                    helperText={
                      errors.value && touched.value ? errors.value : null
                    }
                    id='value'
                    name='value'
                    // value={values.value}
                    aria-label='cancel'
                    onChange={handleChange}
                  >
                    {options.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.title}
                        control={<GreenRadio color='primary' />}
                        label={
                          <Typography color='secondary'>
                            {option.title}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {errors.value && touched.value ? (
                    <div className={classes.div}>{errors.value}</div>
                  ) : null}
                </FormControl>
                <Box
                  display='flex'
                  className={classes.box}
                  mt={lgMatch ? 4 : 2}
                  mb={lgMatch ? 5 : 2}
                >
                  <Button
                    size={lgMatch ? 'large' : 'small'}
                    className={classes.cancel}
                  >
                    <Typography style={{ color: '#FFC400' }}>
                      Need Help?
                    </Typography>
                  </Button>
                  <Button
                    type='submit'
                    color='primary'
                    size={lgMatch ? 'large' : 'small'}
                    variant='contained'
                    className={classes.cancel}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
    </Dialog>
  )
}
