//Libraries
import React, { useState } from 'react'
import {
  Box,
  AppBar,
  Tabs,
  Tab,
  makeStyles,
  useTheme,
  Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import styled from '@emotion/styled'

//Components
import NavigationDrawer from '../../Components/NavigationDrawer/NavigationDrawer'
import CreditCards from './CreditCards'
import Paypal from './Paypal'

//Style
const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.only('xs')]: {
      flexGrow: 1,
      marginTop: '3%',
      marginLeft: '5%'
    },
    [theme.breakpoints.only('sm')]: {
      flexGrow: 1,
      marginTop: '3%',
      marginLeft: '5%'
    },
    [theme.breakpoints.only('md')]: {
      flexGrow: 1,
      paddingTop: '2%',
      marginTop: '3%',
      marginRight: '5%',
      marginLeft: '2%'
    },
    [theme.breakpoints.only('lg')]: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingTop: '2%',
      marginTop: '3%',
      marginRight: '5%',
      marginLeft: '2%'
    },
    [theme.breakpoints.only('xl')]: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingTop: '2%',
      marginTop: '3%',
      marginRight: '5%',
      marginLeft: '2%'
    }
  },
  mainRoot: {
    display: 'flex',
    width: '100%'
  },
  customStyleOnTab: {
    fontSize: '15px'
  },
  activeTab: {
    fontSize: '15px',
    fontWeight: '600',
    color: 'white'
  },
  tabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      textAlign: 'left',
      alignItems: 'left'
    },
    '& .MuiTab-root': {
      padding: '0px'
    }
  },
  bar: {
    background: 'transparent',
    boxShadow: 'none',
    textAlign: 'left',
    [theme.breakpoints.only('xs')]: {
      paddingTop: '20%'
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '10%'
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '6%',
      paddingLeft: '1.5%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '3%',
      paddingLeft: '2.5%'
    }
  }
}))

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const StyledTabs = styled(({ className, ...other }) => {
  return (
    <Tabs
      {...other}
      classes={{
        root: className,
        flexContainer: 'flexContainer',
        indicator: 'indicator'
      }}
      TabIndicatorProps={{ children: <span /> }}
    />
  )
})({
  '& .indicator': {
    background: 'primary',
    minWidth: '30px',
    maxWidth: '30px'
  },
  '& .flexContainer': {
    flexDirection: 'row',
    alignItems: 'left',
    justifyContent: 'left',
    textAlign: 'left'
    // width:'fit-content'
  }
})

export default function Payment () {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  return (
    <div className={classes.mainRoot}>
      <NavigationDrawer />
      <main className={classes.content}>
        <div className={classes.root}>
          <AppBar position='static' color='default' className={classes.bar}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              className={classes.tabs}
            >
              <Tab
                label={
                  <span
                    className={
                      value === 0 ? classes.activeTab : classes.customStyleOnTab
                    }
                  >
                    Paypal
                  </span>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <span
                    className={
                      value === 1 ? classes.activeTab : classes.customStyleOnTab
                    }
                  >
                    Credit Cards
                  </span>
                }
                {...a11yProps(1)}
              />
            </StyledTabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Paypal />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <CreditCards />
            </TabPanel>
          </SwipeableViews>
        </div>
      </main>
    </div>
  )
}
