import * as yup from 'yup'

const schemata = {
  orderProblemValidationSchema: yup.object().shape({
    value: yup.string().required('Select any one option.'),
    desc: yup.string().required('This field is required.')
  }),
  congestionChargesSchema: yup.object().shape({
    tollPlaza: yup.number().required('This field is required.').nullable(),
    amountPlaza: yup.number().required('This field is required.').nullable(),
    cities: yup.number().required('This field is required.').nullable(),
    amountCongestion: yup.number().required('This field is required.').nullable()
  }),
  orderCancelValidationSchema: yup.object().shape({
    value: yup.string().required('Select any one option.')
  })
}

export default schemata
