//Libraries
import { ThemeProvider } from '@material-ui/core/styles'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import createTheme from '@material-ui/core/styles/createTheme'
import { CssBaseline } from '@material-ui/core'
import { useSelector } from 'react-redux'

//Components
import FunRoutes from './Routes/routes'
import history from './Utils/history'

function App () {
  const themeType = useSelector(state => state.themes.theme)

  let theme = createTheme({
    palette: {
      type: themeType,
      primary: {
        main: '#6251FE'
      },
      secondary: {
        main: '#B9B6B6'
      },
      subSecondary: {
        main: '#008000'
      }
    },
    typography: {
      fontFamily: ['Poppins'].join(','),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500
    }
  })
  theme.typography.caption = {
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '15px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.1rem'
    }
  }

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HistoryRouter history={history}>
          <FunRoutes />
        </HistoryRouter>
      </ThemeProvider>
    </div>
  )
}

export default App
