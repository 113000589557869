//Libraries
import React from 'react'
import {
  Box,
  Grid,
  Button,
  IconButton,
  InputAdornment,
  CssBaseline,
  Typography,
  makeStyles,
  TextField,
  MenuItem,
  Hidden,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  box: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '95%',
      paddingRight: '5%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100%',
      paddingRight: '2%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
      paddingLeft: '2%',
      paddingRight: '2%'
    }
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0, 0)
    },
    [theme.breakpoints.only('md')]: {
      margin: theme.spacing(1, 0, 1)
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(2, 0, 4),
      fontWeight: 'bold'
    }
  },
  MuiCardContent: {
    width: '100%',
    display: 'center',
    textAlign: 'center'
  },
  root: {
    display: 'flex'
  },
  image: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
      width: 154.56,
      height: 103.5
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(2),
      alignItems: 'center',
      width: 267,
      height: 158
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(4),
      alignItems: 'center',
      width: '60%',
      height: '60%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(4),
      alignItems: 'center',
      width: '45%',
      height: '80%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(2),
      alignItems: 'center'
    }
  },
  logoMas: {
    marginRight: '2%',
    [theme.breakpoints.down('md')]: {
      marginTop: '7%'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '5%'
    }
  },
  logoVisa: {
    marginRight: '2%',
    marginTop: '8%'
  },
  grid: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start'
    }
  },
  gridItem: {
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'start'
    }
  },
  item: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'end',
      paddingRight: '5%'
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center'
    }
  },
  text: {
    float: 'left'
  }
}))

const countries = [
  {
    value: 'United States of America',
    label: 'United States of America'
  },
  {
    value: 'China',
    label: 'China'
  },
  {
    value: 'Denmark',
    label: 'Denmark'
  },
  {
    value: 'Japan',
    label: 'Japan'
  },
  {
    value: 'Pakistan',
    label: 'Pakistan'
  },
  {
    value: 'Spain',
    label: 'Spain'
  }
]

export default function CreditCards () {
  const classes = useStyles()
  const theme = useTheme()
  const mdMatch = useMediaQuery(theme.breakpoints.up('md'))
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const xsMatch = useMediaQuery(theme.breakpoints.only('xs'))
  const Match = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Grid container>
            <Grid item xs={12} lg={6} className={classes.grid}>
              <Grid container spacing={Match ? 0 : 2}>
                <Grid item xs={6} lg={12} className={classes.item}>
                  <img
                    src='/assets/images/payment/card1.png'
                    alt='card'
                    className={classes.image}
                  />
                </Grid>
                <Grid item xs={6} lg={12} className={classes.gridItem}>
                  <img
                    src='/assets/images/payment/card2.png'
                    alt='card'
                    className={classes.image}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} align='center'>
              <Box className={classes.box} textAlign='center'>
                <Typography className={classes.typo}>
                  Pay using credit cards
                </Typography>
                <Grid container>
                  <Grid item xs={6} lg={6} align='right'>
                    <img
                      src={
                        lgMatch
                          ? '/assets/images/payment/mastercardlogo.png'
                          : '/assets/images/payment/mastersmall.png'
                      }
                      alt='master card'
                      className={classes.logoMas}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} align='left'>
                    <img
                      src={
                        lgMatch
                          ? '/assets/images/payment/visalogo.png'
                          : '/assets/images/payment/visasmall.png'
                      }
                      alt='visa logo'
                      className={classes.logoVisa}
                    />
                  </Grid>
                </Grid>
                <form>
                  <Box textAlign='center' mt={2}>
                    <Typography color='secondary' className={classes.text}>
                      Card Number
                    </Typography>
                    <TextField
                      id='filled-full-width'
                      placeholder='4848-5856-5853-6786'
                      fullWidth
                      variant='outlined'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton>
                              <img
                                src='/assets/images/payment/mastercardlogosmall.png'
                                id='logo'
                              />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                  <Box textAlign='center' mt={2}>
                    <Typography color='secondary' className={classes.text}>
                      Card Holder Name
                    </Typography>
                    <TextField
                      id='filled-full-width'
                      placeholder='Enter Card Holder name'
                      fullWidth
                      variant='outlined'
                    />
                  </Box>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} lg={6}>
                        <Typography color='secondary' className={classes.text}>
                          Expiry Date
                        </Typography>
                        <TextField
                          id='filled-full-width'
                          placeholder='MM / YY'
                          variant='outlined'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Typography color='secondary' className={classes.text}>
                          3 Digit csc
                        </Typography>
                        <TextField
                          id='filled-full-width'
                          placeholder='091'
                          variant='outlined'
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton>
                                  <img
                                    alt='password'
                                    src='/assets/images/cscpassword.png'
                                  />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} lg={6}>
                        <Typography color='secondary' className={classes.text}>
                          Billing Address
                        </Typography>
                        <TextField
                          id='filled-full-width'
                          placeholder='Enter your Address'
                          variant='outlined'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Typography color='secondary' className={classes.text}>
                          Country
                        </Typography>
                        <TextField
                          fullWidth
                          variant='outlined'
                          name='country'
                          type=''
                          id='country'
                          select
                        >
                          {countries.map(option => (
                            <MenuItem
                              key={option.value}
                              value={option.value || ''}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} lg={6}>
                        <Typography color='secondary' className={classes.text}>
                          State
                        </Typography>
                        <TextField
                          id='filled-full-width'
                          placeholder='Florida'
                          variant='outlined'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Typography color='secondary' className={classes.text}>
                          Zip Code
                        </Typography>
                        <TextField
                          id='filled-full-width'
                          placeholder='Enter zip code'
                          variant='outlined'
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Button
                      fullWidth
                      color='primary'
                      size='large'
                      variant='contained'
                      className={classes.submit}
                    >
                      Pay
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  )
}
