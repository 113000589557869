//Libraries
import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Button,
  useMediaQuery,
  makeStyles,
  Typography,
  Grid,
  useTheme,
  Snackbar,
  MenuItem
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

//Components
import NavigationDrawer from '../../Components/NavigationDrawer/NavigationDrawer'
import Yup from '../../Yup/YupAuthValidation.schemas'
import DocumentCard from '../../Components/DocumentUpload/DocumentCard'
import actions from '../../Redux/Driver/Driver.actions'
import Loader from '../../Components/Loader/Loader'
//Style
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  img: {
    [theme.breakpoints.between('xs', 'md')]: {
      width: 45.45,
      height: 32.67
    }
  },
  paper: {
    marginBottom: theme.spacing(0),
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(11, 4)
    },
    [theme.breakpoints.only('sm')]: {
      margin: theme.spacing(13, 9)
    },
    [theme.breakpoints.only('md')]: {
      margin: theme.spacing(13, 7)
    },
    [theme.breakpoints.only('lg')]: {
      margin: theme.spacing(9, 7)
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(10, 15)
    },
    noBg: {
      '& .MuiListItem-root.Mui-selected': {
        background: 'none !important'
      }
    }
  },
  form: {
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(4)
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(2),
      alignItems: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(4),
      alignItems: 'center'
    }
  },
  submit: {
    fontSize: 18,
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(5, 0, 2),
      width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(3, 0, 2),
      width: '100%'
    },
    [theme.breakpoints.only('lg')]: {
      margin: theme.spacing(3, 0, 2),
      width: 392.83
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(8, 0, 0),
      width: 392.83
    }
  },
  box: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)'
  },
  heading: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 22
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 28
    }
  },
  subheading: {
    color: '#707070',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 14
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 16
    }
  },
  text: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 16
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 24
    }
  },
  alignBox: {
    textAlign: 'center'
  },
  div: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  textfield: {
    marginTop: '1%'
  }
}))

export default function DocumentVerification () {
  const theme = useTheme()
  const classes = useStyles()
  const mdmatches = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useDispatch()
  const jwt = useSelector(state => state.driver.jwt)

  useEffect(() => {
    console.log('clearing previous error')
    dispatch(actions.clearError())
  }, [])

  const addDocsError = useSelector(state => state.driver.errors.addDocsError)
  const loading = useSelector(state => state.driver.isFetching)
  const addDocsSuccess = useSelector(
    state => state.driver.success.addDocsSuccess
  )

  const [initialValues, setInitialValues] = useState({
    doc1: null,
    doc2: null,
    doc3: null,
    doc4: null,
    vehicleType: '',
    vehicleNumber: '',
    vehicleColor: ''
  })

  const [openSnackUpdateDriver, setOpenSnackUpdateDriver] = useState(false)

  const handleErrorSnackUpdateDriver = () => {
    setOpenSnackUpdateDriver(true)
  }
  const handleCloseSnackUpdateDriver = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackUpdateDriver(false)
  }

  useEffect(() => {
    
    if (addDocsError && !addDocsSuccess) {
      handleErrorSnackUpdateDriver()
    } else if (addDocsSuccess && !addDocsError) {
      handleErrorSnackUpdateDriver()
    }
  }, [addDocsError, addDocsSuccess])

  const vehicleTypeOptions = [
    {
      id: 1,
      img: '/assets/images/mwb.png',
      name: 'Mwb',
      value: 'small',
      Desc: 'Medium Wheelbase Transit'
    },
    {
      id: 2,
      img: '/assets/images/Lwb.png',
      name: 'Lwb',
      value: 'medium',
      Desc: 'Long Wheelbase Transit'
    },
    {
      id: 3,
      img: '/assets/images/Tlbv.png',
      name: 'Tlbv',
      value: 'large',
      Desc: 'Ton luton body van with tail lift'
    },
    {
      id: 4,
      img: '/assets/images/Tlbw.png',
      name: 'Tlbw',
      value: 'xl',
      Desc: 'Ton luton box van with tail lift'
    }
  ]

  const options = [
    {
      id: 'click-license',
      CardName: 'Driving License',
      doc: 'doc1'
    },
    {
      id: 'click-vehicle-id',
      CardName: 'Vehicle License',
      doc: 'doc2'
    },
    {
      id: 'click-vehicle-insurance',
      CardName: 'Vehicle Insurance',
      doc: 'doc3'
    },
    {
      id: 'click-personal-id',
      CardName: 'Personal ID Card',
      doc: 'doc4'
    }
  ]

  const orderDataHandler = (type, data) => {
    console.log('orderDetailsHandler', data)
    if (type === 'doc1') {
      console.log(type, data)
      setInitialValues({
        ...initialValues,
        doc1: data
      })
    } else if (type === 'doc2') {
      console.log(type, data)
      setInitialValues({
        ...initialValues,
        doc2: data
      })
    } else if (type === 'doc3') {
      console.log(type, data)
      setInitialValues({
        ...initialValues,
        doc3: data
      })
    } else if (type === 'doc4') {
      console.log(type, data)
      setInitialValues({
        ...initialValues,
        doc4: data
      })
    }
  }

  const docs = 'UploadDocs'

  return (
    <div className={classes.root}>
      <NavigationDrawer />
      <div className={classes.paper}>
        <Typography variant='h1' className={classes.heading}>
          Van go confirms who you are
        </Typography>
        <Box mt={2}>
          <Typography className={classes.subheading}>
            To confirm your identification, Upload all the necessary photos
            require below
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.documentVerificationSchema}
          onSubmit={values => {
            console.log('doc values',values)
            dispatch(actions.accountVerificationDocs(values, jwt, docs))
          }}
        >
          {({ errors, handleChange, touched, setFieldValue, values }) => (
            <Form className={classes.form}>
              <Grid container spacing={mdmatches ? 2 : 5}>
                {options.map((option, i) => (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    xl={3}
                    align='center'
                    key={i}
                  >
                    <DocumentCard
                      CardName={option.CardName}
                      id={option.id}
                      name={option.doc}
                      setFieldValue={setFieldValue}
                      orderDataHandler={orderDataHandler}
                      doc={option.doc}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box mt={mdmatches ? 5 : 10}>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item xs={6} sm={5} lg={3}>
                    <Typography className={classes.text}>
                      Vehicle Number
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={7} lg={9}>
                    <TextField
                      error={errors.vehicleNumber && touched.vehicleNumber}
                      helperText={
                        errors.vehicleNumber && touched.vehicleNumber
                          ? errors.vehicleNumber
                          : null
                      }
                      id='vehicleNumber'
                      name='vehicleNumber'
                      fullWidth
                      className={classes.textfield}
                      onChange={handleChange}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={mdmatches ? 2 : 6}>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item xs={6} sm={5} lg={3}>
                    <Typography className={classes.text}>
                      Vehicle Color
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={7} lg={9}>
                    <TextField
                      error={errors.vehicleColor && touched.vehicleColor}
                      helperText={
                        errors.vehicleColor && touched.vehicleColor
                          ? errors.vehicleColor
                          : null
                      }
                      id='vehicleColor'
                      name='vehicleColor'
                      onChange={handleChange}
                      className={classes.textfield}
                      fullWidth
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={mdmatches ? 2 : 6}>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item xs={6} sm={5} lg={3}>
                    <Typography className={classes.text}>
                      Vehicle Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={7} lg={9}>
                    <TextField
                      error={errors.vehicleType && touched.vehicleType}
                      helperText={
                        errors.vehicleType && touched.vehicleType
                          ? errors.vehicleType
                          : null
                      }
                      id='vehicleType'
                      name='vehicleType'
                      className={classes.textfield}
                      fullWidth
                      variant='outlined'
                      // value={values?.vehicleType}
                      onChange={handleChange}
                      select
                    >
                      {vehicleTypeOptions?.map(option => {
                        return (
                          <MenuItem
                            value={option.value || ''}
                            style={
                              option.id === 4
                                ? { background: 'none' }
                                : {
                                    background: 'none',
                                    borderBottom: '1px solid grey'
                                  }
                            }
                            key={option.id}
                          >
                            <div className={classes.div}>
                              <Typography variant='subtitle1'>
                                {option.Desc}
                              </Typography>
                              <img src={option.img} alt='van' width='50' />
                            </div>
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.alignBox}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={loading}
                  className={classes.submit}
                >
                   {loading ? <Loader /> : 'Submit'}
                  
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
      {addDocsError || addDocsSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackUpdateDriver}
          autoHideDuration={2000}
          onClose={handleCloseSnackUpdateDriver}
        >
          <Alert
            onClose={handleCloseSnackUpdateDriver}
            severity={addDocsError ? 'error' : 'success'}
          >
            {addDocsError ? addDocsError : addDocsSuccess}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  )
}
