//Libraries
import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment'
import {
  Box,
  Divider,
  Grid,
  TextField,
  CssBaseline,
  IconButton,
  Typography,
  Card,
  CardContent,
  Button,
  Paper,
  CircularProgress,
  Popover,
  Avatar,
  Snackbar,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { Link, useParams } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import Rating from '@material-ui/lab/Rating'
import { useSelector, useDispatch } from 'react-redux'
import { Item } from '@mui-treasury/components/flex'
import { useLoadScript } from '@react-google-maps/api'

//Icons
import DirectionsIcon from '@material-ui/icons/Directions'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

//Components
import actions from '../Redux/Order/Order.actions'
import driverActions from '../Redux/Driver/Driver.actions'
import NavigationDrawer from '../Components/NavigationDrawer/NavigationDrawer'
import CancelOrder from '../Components/OrderDetailComponents/CancelOrder'
import CompleteOrderIssue from '../Components/OrderDetailComponents/CompleteOrderIssue'
import GoogleMaps from '../Components/DeliveryMap/GoogleMap'
import DestinationToActive from '../Components/OrderDetailComponents/DestinationToActive'
import { webSocketLiveLocationPath } from '../Utils/constants'
import history from '../Utils/history'
import CongestionCharges from '../Components/OrderDetailComponents/CongestionCharges'

let socket

const libraries = ['places']

//Make Styles
const useStyles = makeStyles(theme => ({
  boxWidth: {
    width: '100%'
  },
  divLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  loader: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progress: {
    [theme.breakpoints.between('xs', 'md')]: {
      width: '30px',
      height: '30px',
      marginTop: '10%',
      marginBottom: '10%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: '40px',
      height: '40px'
    }
  },
  dialog: {
    position: 'absolute',
    borderRadius: 8
  },
  alertError: {
    width: '100%',
    marginBottom: '20px',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  large: {
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(7),
      fontSize: 24,
      height: theme.spacing(7)
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(7),
      fontSize: 26,
      height: theme.spacing(7)
    },
    [theme.breakpoints.only('md')]: {
      width: theme.spacing(9),
      fontSize: 28,
      height: theme.spacing(9)
    },
    [theme.breakpoints.only('lg')]: {
      width: theme.spacing(12),
      fontSize: 30,
      height: theme.spacing(12)
    },
    [theme.breakpoints.only('xl')]: {
      width: theme.spacing(14),
      fontSize: 36,
      height: theme.spacing(14)
    }
  },
  purple: {
    backgroundColor: '#6251FE'
  },
  cardroot: {
    borderRadius: 8,
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    [theme.breakpoints.between('xs', 'md')]: {
      width: '100%',
      marginBottom: '3%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '92%',
      marginBottom: '1.5%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '87%',
      marginBottom: '1.5%'
    }
  },
  root: {
    display: 'flex'
  },
  number: {
    paddingTop: '2%',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      opacity: 1
    }
  },
  icons: {
    padding: 6
  },
  button: {
    textDecoration: 'none',
    float: 'right',
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 14
    }
  },
  color: {
    color: '#FFC400'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  list: {
    padding: '0px'
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      marginTop: '5%'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '3%'
    }
  },
  price: {
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#6251FE'
  },
  boxs: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: '3%',
      marginRight: '3%',
      marginTop: '3%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: '8%',
      marginRight: '8%',
      marginTop: '3%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      marginLeft: '3%',
      marginTop: '0%',
      marginRight: '0%'
    }
  },
  submit: {
    width: '15vw',
    marginRight: '2%',
    [theme.breakpoints.up('lg')]: {
      width: '15vw',
      margin: theme.spacing(1, 0, 2)
    }
  },
  title: {
    fontSize: 15,
    marginTop: '2%'
  },
  container: {
    position: 'relative',
    width: '50vw'
  },
  paper: {
    width: '100%',
    padding: '3%',
    paddingBottom: '7%'
  },
  cardCentered: {
    position: 'absolute',
    width: '-moz-fit-content',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      top: '35%'
    },
    [theme.breakpoints.only('sm')]: {
      top: '55%'
    },
    [theme.breakpoints.only('md')]: {
      top: '40%'
    },
    [theme.breakpoints.only('lg')]: {
      top: '50%'
    },
    [theme.breakpoints.only('xl')]: {
      top: '60%'
    }
  },
  call: {
    paddingRight: '10%'
  },
  heading: {
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 32.4
    }
  },
  centeredTrip: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    borderRadius: 8,
    [theme.breakpoints.only('xs')]: {
      width: '90%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%'
    }
  },
  centeredTrips: {
    width: '-moz-fit-content',
    width: 'fit-content',
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    borderRadius: 8
  },
  title: {
    fontSize: 15,
    marginTop: '2%'
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    marginRight: '2%'
  },
  cancel: {
    backgroundColor: '#F5F6FE',
    float: 'right',
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(1, 0, 2)
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      margin: theme.spacing(2, 0, 2)
    }
  },
  astext: {
    background: 'none',
    textTransform: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
    cursor: 'pointer'
  },
  submitTrip: {
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(1, 0, 0),
      fontSize: 12
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(2, 0, 0)
    }
  },
  dividerMargin: {
    marginTop: '5%',
    marginBottom: '5%'
  },
  estDiv: {
    marginBottom: '3%',
    width: '100%'
  },
  dividerFloor: {
    marginTop: '4%',
    marginBottom: '3%'
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
    [theme.breakpoints.down('lg')]: {
      marginRight: 1
    },
    [theme.breakpoints.only('xl')]: {
      marginRight: 4
    }
  },
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 20
    }
  },
  title1: {
    marginTop: '2%',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 20
    }
  },
  cardContent: {
    [theme.breakpoints.down('md')]: {
      padding: '8px'
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '16px'
    }
  },
  textActive: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  },
  img: {
    paddingRight: '10%'
  },
  boxFloat: {
    float: 'right',
    marginRight: '1%'
  },
  direction: {
    marginLeft: '5%',
    marginTop: '-20px',
    fontSize: 40,
    padding: '0px',
    float: 'right'
  },
  font: {
    fontSize: 40
  },
  float: {
    float: 'right'
  },
  div: {
    borderRadius: 8,
    border: '1px solid grey',
    padding: '5px 15px',
    width: '25%'
  }
}))

export default function OrderSummary (props) {
  //Use styles
  const classes = useStyles()
  const dialogRef = useRef()

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })
  console.log(
    'process.env.REACT_APP_GOOGLE_MA',
    process.env.REACT_APP_GOOGLE_MA
  )
  //#region theme
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const mdMatch = useMediaQuery(theme.breakpoints.up('md'))
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const themeType = useSelector(state => state.themes.theme)
  //#endregion

  const { orderId } = useParams()

  //useDisptach
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.clearError())
    dispatch(driverActions.clearError())
  }, [])

  //#region useSelector
  const orderDetail = useSelector(state => state.order.orderDetail)

  const jwt = useSelector(state => state.driver.jwt)
  const orderStatus = useSelector(state => state.order.orderStatus)

  const updateActiveSuccess = useSelector(
    state => state.driver.success.updateActiveSuccess
  )
  const conjestionError = useSelector(
    state => state.order.errors.conjestionError
  )
  const conjestionSuccess = useSelector(
    state => state.order.success.conjestionSuccess
  )
  const updateActiveError = useSelector(
    state => state.driver.errors.updateActiveError
  )
  const driverCurrentLocation = useSelector(
    state => state.order?.currentLocation
  )
  const activeZipCode = useSelector(state => state.driver.activeZipcodes)

  const driverUpdateLocation = useSelector(state => state.order?.updateLocation)
  const newPolyline = useSelector(state => state.order?.newPolyline)
  //#endregion

  //#region useState
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [latValue1, setLatValue1] = useState(0)
  const [latValue2, setLatValue2] = useState(0)
  const [lngValue1, setLngValue1] = useState(0)
  const [lngValue2, setLngValue2] = useState(0)
  const [phone, setPhone] = useState(false)
  const [otp, setOtp] = useState('')
  const [loading, setLoading] = useState(true)
  const [startTrip, setstartTrip] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [cancelOpenIssue, setCancelOpenIssue] = useState(false)
  const [congestionOpen, setCongestionOpen] = useState(false)

  const [openActive, setOpenActive] = useState(false)
  const [iconPosition, setIconPosition] = useState({ lat: null, lng: null })
  const [activeOrders, setActiveOrders] = useState(orderDetail ? true : false)
  const [activeOrdersAvailable, setActiveOrdersAvailable] = useState(
    orderDetail ? true : false
  )
  const [openAlert, setOpenAlert] = useState(false)
  const [errorMessage, setErrorCurrentPosition] = React.useState('')
  const [enableLocaton, setDisableLocation] = useState(false)
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')
  const [accuracy, setAccuracy] = useState()
  const [modal2Visible, setModal2Visible] = useState(false)
  const [location1, setLocation] = useState({
    lat: '',
    long: '',
    orderId: ''
  })
  const [openSnackUpdateDriver, setOpenSnackUpdateDriver] = useState(false)

  const handleErrorSnackUpdateDriver = () => {
    setOpenSnackUpdateDriver(true)
  }

  const handleCloseSnackUpdateDriver = (event, reason) => {
    dispatch(driverActions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackUpdateDriver(false)
  }

  const [openSnackDriver, setOpenSnackDriver] = useState(false)

  const handleErrorSnackDriver = () => {
    setOpenSnackDriver(true)
  }

  const handleCloseSnackDriver = (event, reason) => {
    dispatch(driverActions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackDriver(false)
  }

  //#endregion

  //#region useEffect
  useEffect(() => {
    dispatch(actions.getOrderDetails(jwt, orderId))
  }, [])

  useEffect(() => {
    if (updateActiveError && !updateActiveSuccess) {
      handleErrorSnackUpdateDriver()
    } else if (updateActiveSuccess && !updateActiveError) {
      handleErrorSnackUpdateDriver()
    }
  }, [updateActiveError, updateActiveError])

  useEffect(() => {
    if (conjestionError && !conjestionSuccess) {
      handleErrorSnackDriver()
    } else if (conjestionSuccess && !conjestionError) {
      handleErrorSnackDriver()
    }
  }, [conjestionError, conjestionSuccess])

  useEffect(() => {
    if (driverUpdateLocation?.lat !== '' && driverUpdateLocation?.lng !== '') {
      setLocation({
        lat: driverUpdateLocation?.lat,
        long: driverUpdateLocation?.lng,
        orderId: orderDetail?._id
      })
    }
  }, [driverUpdateLocation?.lat, driverUpdateLocation?.lng, orderDetail?._id])

  useEffect(() => {
    if (orderStatus === 'on the way to pickup point') {
      watchPosition()
    }
  }, [orderStatus])

  useEffect(() => {
    if (enableLocaton === true) {
      driverCurrentPosition()
    }
  }, [enableLocaton])

  useEffect(() => {
    if (orderDetail && !startTrip) {
      setLatValue1(orderDetail?.tripStartLocation?.lat)
      setLatValue2(orderDetail?.tripEndLocation?.lat)
      setLngValue1(orderDetail?.tripStartLocation?.long)
      setLngValue2(orderDetail?.tripEndLocation?.long)
      setActiveOrdersAvailable(true)
      setActiveOrders(true)
      setLoading(false)
    }
  }, [orderDetail, !startTrip])

  useEffect(() => {
    if (location1?.lat !== '' && location1?.long !== '') {
      socket = new WebSocket(webSocketLiveLocationPath + 'liveLocation', jwt)

      socket.onopen = function (e) {
        if (socket.readyState === 1) {
          socket.send(JSON.stringify(location1))
        }
      }

      socket.onmessage = function (event) {
        if (event.data === 'Ok' || event.data === 'ok') {
          // console.log(`[message] Data received from server: ${event.data}`)
        }
      }
      socket.onclose = () => {
        // console.log('disconnected')
      }

      socket.onerror = err => {
        socket.close()
      }
    }
  }, [location1?.lat, location1?.lng, location1?.orderId, lat, lng])
  //#endregion

  //#region start and stop watching Position
  let watchId = -1

  //Success Position
  function success (pos) {
    setLat(pos.coords.latitude)
    setLng(pos.coords.longitude)
    setAccuracy(pos.coords.accuracy)
    setIconPosition({ lat: pos.coords.latitude, lng: pos.coords.longitude })
    if (
      pos.coords.latitude !== driverCurrentLocation?.lat &&
      pos.coords.longitude !== driverCurrentLocation?.lng
    ) {
      dispatch(
        actions.updateLocations(pos.coords.latitude, pos.coords.longitude)
      )
    }
    // console.log(`latitude: ${pos.coords.latitude}
    // \n longitude: ${pos.coords.longitude}
    // \n accuracy: ${pos.coords.accuracy}`)
  }

  //Watch Position
  function watchPosition () {
    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(success)
    }
  }

  //Current Position Success
  function onSuccess (pos) {
    dispatch(
      actions.driverCurrentLocation(pos.coords.latitude, pos.coords.longitude)
    )
    setDisableLocation(false)
    // console.log(`DriverCurrent Location
    // \nlatitude: ${pos.coords.latitude}
    // \n longitude: ${pos.coords.longitude}
    // \n accuracy: ${pos.coords.accuracy}`)
  }

  //Current Position Error
  function onError (err) {
    // console.warn(`getCurrentPosition ERROR(${err.code}): ${err.message}`)
    setErrorCurrentPosition(err.message)
    setOpenAlert(true)
    setDisableLocation(false)
  }

  //Driver Current Position
  function driverCurrentPosition () {
    var options = {
      enableHighAccuracy: true,
      maximumAge: 0
    }
    if (navigator.geolocation) {
      watchId = navigator.geolocation.getCurrentPosition(
        onSuccess,
        onError,
        options
      )
    }
  }

  //Stop Watching
  function stopWatching () {
    if (navigator.geolocation) {
      navigator.geolocation.clearWatch(watchId)
      dispatch(actions.clearWatchHistory())
    }
  }

  const directionPlaces = [
    { latitude: parseFloat(latValue1), longitude: parseFloat(lngValue1) },
    { latitude: parseFloat(latValue2), longitude: parseFloat(lngValue2) }
  ]

  const driverDirection = [
    { latitude: parseFloat(latValue1), longitude: parseFloat(lngValue1) },
    {
      latitude: parseFloat(driverCurrentLocation?.lat),
      longitude: parseFloat(driverCurrentLocation?.lng)
    }
  ]
  //#endregion

  //#region Price Estimation
  const options = [
    {
      id: 1,
      title: 'Congestion Charges: ',
      amount: orderDetail?.estimatedPrice?.finalCongestionCharges
    },
    {
      id: 2,
      title: 'Cost flight of Stair: ',
      amount: orderDetail?.estimatedPrice?.finalCostFlightOfStair
    },
    {
      id: 3,
      title: 'Cost of Helpers: ',
      amount: orderDetail?.estimatedPrice?.finalCostOfHelpers
    },
    {
      id: 4,
      title: 'Insurrance Charges: ',
      amount: orderDetail?.estimatedPrice?.finalInsurranceCharges
    },
    {
      id: 5,
      title: 'Toll Charges: ',
      amount: orderDetail?.estimatedPrice?.finalTollCharges
    },
    {
      id: 6,
      title: 'Factore Charges: ',
      amount: orderDetail?.estimatedPrice?.finalTypeFactoreCharges
    },
    {
      id: 7,
      title: 'Minimum Price: ',
      amount: orderDetail?.estimatedPrice?.minimumPrice
    },
    {
      id: 8,
      title: 'Ride Distance: ',
      amount: orderDetail?.estimatedPrice?.rideDistance
    },
    {
      id: 9,
      title: 'Time In Ride: ',
      amount: orderDetail?.estimatedPrice?.timeInRide
    }
  ]
  //#endregion

  //#region Order Handlers
  //Order Accept Handler
  const OrderAcceptHandler = () => {
    dispatch(actions.orderConfirmation(orderDetail._id, 'Accepted', jwt))
  }

  //Order Reject Handler
  const OrderRejectHandler = () => {
    dispatch(actions.orderConfirmation(orderDetail._id, 'Rejected', jwt))
    history.push('/orders')
  }

  //On Way to Pickup Handler
  const OnWaytoPickupHandler = () => {
    dispatch(
      actions.updateOrderStatus(
        orderDetail._id,
        'on the way to pickup point',
        jwt,
        null,
        null
      )
    )
    setDisableLocation(true)
    setstartTrip(true)
  }

  //Reached Pickup Handler
  const ReachedPickupHandler = () => {
    dispatch(
      actions.updateOrderStatus(
        orderDetail._id,
        'reached pickup point',
        jwt,
        null,
        null
      )
    )
    setDisableLocation(true)
  }

  //On Route To Destination Handler
  const OnRoutToDestinationHandler = () => {
    dispatch(
      actions.updateOrderStatus(
        orderDetail._id,
        'on route to destination',
        jwt,
        null,
        null
      )
    )
    setDisableLocation(true)
  }

  //Reached Destination Handler
  const ReachedDesctinationHandler = () => {
    dispatch(
      actions.updateOrderStatus(
        orderDetail._id,
        'reached destination',
        jwt,
        null,
        null
      )
    )
    setDisableLocation(true)
  }

  //Order Complete Handler
  const OrderCompleteHandler = () => {
    dispatch(
      actions.updateOrderStatus(
        orderDetail._id,
        'order complete',
        jwt,
        otp,
        stopWatching
      )
    )
  }

  //Active zip code Handler
  const ActiveZipCodeHandler = () => {
    dispatch(
      driverActions.updateActiveZipCode(
        orderDetail?.tripEndLocation?.zipcode,
        jwt
      )
    )
  }

  //Order Complete Handler
  const NotActiveHandler = () => {
    dispatch(driverActions.UpdateNotActive('No'))
  }

  //#endregion

  //#region button click handlers
  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOTPChange = e => {
    let otpLetter = e.target.value
    setOtp(otpLetter.toUpperCase())
  }

  const handleCancelClose = () => {
    setCancelOpen(false)
  }

  const handleCancelOpen = () => {
    setCancelOpen(true)
  }

  const handleCongestionClose = () => {
    setCongestionOpen(false)
  }

  const handleCongestionOpen = () => {
    setCongestionOpen(true)
  }

  const handleCancelCloseIssue = () => {
    setCancelOpenIssue(false)
  }

  const handleCancelOpenActive = () => {
    setOpenActive(false)
  }
  //#endregion

  //#region Order Status Change Popups
  //AcceptRejectOrder
  function AcceptRejectOrder () {
    return (
      <div>
        <Card className={classes.centeredTrips} elevation={2}>
          <Box p={lgMatch ? 4 : 2} className={classes.boxWidth}>
            <Typography className={classes.title} color='secondary'>
              Do you want to Accept this Order ?
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6}>
                <Button
                  fullWidth
                  style={{ backgroundColor: 'red', color: 'white' }}
                  size='medium'
                  variant='contained'
                  className={classes.submitTrip}
                  onClick={OrderRejectHandler}
                >
                  Reject
                </Button>
              </Grid>
              <Grid item xs={6} lg={6} align='right'>
                <Button
                  color='primary'
                  size='medium'
                  fullWidth
                  variant='contained'
                  className={classes.submitTrip}
                  onClick={OrderAcceptHandler}
                >
                  Accept
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    )
  }

  // 'on the way to pickup point',
  function StartTrip () {
    return (
      <div>
        <Card className={classes.centeredTrip} elevation={2}>
          <Box p={lgMatch ? 4 : 2}>
            <Box display='flex'>
              <Typography className={classes.title} color='secondary'>
                Payment Type: &nbsp;
              </Typography>
              <Typography className={classes.title}> Cash</Typography>
            </Box>
            <Box display='flex'>
              <Typography className={classes.title} color='secondary'>
                Amount: &nbsp;
              </Typography>
              <Typography className={classes.title}>
                £{orderDetail?.estimatedPrice?.minimumPrice}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} lg={6}>
                <Button
                  fullWidth
                  style={{ backgroundColor: '#F5F6FE' }}
                  size='medium'
                  variant='contained'
                  className={classes.submitTrip}
                  onClick={handleCancelOpen}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} lg={6} align='right'>
                <Button
                  color='primary'
                  size='medium'
                  fullWidth
                  variant='contained'
                  className={classes.submitTrip}
                  onClick={OnWaytoPickupHandler}
                >
                  Start
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    )
  }

  // 'reached pickup point',

  function ReachedPickUp () {
    return (
      <div>
        {activeZipCode.includes(orderDetail?.tripEndLocation?.zipcode) ||
        modal2Visible ? (
          <div>
            <Card className={classes.centeredTrips} elevation={2}>
              <Box p={lgMatch ? 4 : 2}>
                <Typography className={classes.text}>
                  Have You Reached Pick Up Point ?
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} align='right'>
                    <Button
                      color='primary'
                      // size='large'
                      // fullWidth
                      variant='contained'
                      className={classes.submitTrip}
                      onClick={ReachedPickupHandler}
                    >
                      Reached
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </div>
        ) : (
          <Card className={classes.centeredTrips} elevation={2}>
            <Box p={lgMatch ? 2 : 2}>
              <Typography className={classes.textActive}>
                Would you like to add destination as active zipcode?
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={false} sm={4} md={4} lg={6}></Grid>
                <Grid item xs={5} sm={4} md={4} lg={3}>
                  <Button
                    size='medium'
                    fullWidth
                    variant='contained'
                    className={classes.submitTrip}
                    style={{ backgroundColor: '#F5F6FE' }}
                    onClick={() => setModal2Visible(true)}
                  >
                    No
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3} align='right'>
                  <Button
                    color='primary'
                    size='medium'
                    fullWidth
                    variant='contained'
                    className={classes.submitTrip}
                    onClick={ActiveZipCodeHandler}
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        )}
      </div>
    )
  }

  // 'on route to destination',
  function ToDestination () {
    return (
      <div>
        <Card className={classes.centeredTrips} elevation={2}>
          <Box p={lgMatch ? 4 : 2}>
            <Typography className={classes.text}>
              Are You On Route To Destination ?
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6}></Grid>
              <Grid item xs={6} lg={6} align='right'>
                <Button
                  color='primary'
                  size='medium'
                  fullWidth
                  variant='contained'
                  className={classes.submitTrip}
                  onClick={OnRoutToDestinationHandler}
                >
                  On Route
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    )
  }

  // 'reached destination',
  function ReachedDestination () {
    return (
      <div>
        <Card className={classes.centeredTrips} elevation={2}>
          <Box p={lgMatch ? 4 : 2}>
            <Typography className={classes.text}>
              Have You Reached Destination ?
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6}></Grid>
              <Grid item xs={6} lg={6} align='right'>
                <Button
                  color='primary'
                  size='medium'
                  fullWidth
                  variant='contained'
                  className={classes.submitTrip}
                  onClick={ReachedDesctinationHandler}
                >
                  Reached
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </div>
    )
  }

  // 'End Trip'
  function EndTrip () {
    return (
      <div>
        <Card className={classes.centeredTrips} elevation={2}>
          <Box pt={2} pr={2} pl={2} className={classes.boxWidth}>
            <Typography className={classes.title1} color='textPrimary'>
              Type Code here to end ride
            </Typography>
            <TextField
              variant='outlined'
              margin='normal'
              className={classes.textField}
              required
              fullWidth={lgMatch ? true : false}
              onChange={handleOTPChange}
              id='otp'
              value={otp}
              placeholder='Enter '
              name='otp'
              autoFocus
            />
            <br />
            <Button
              size={lgMatch ? 'medium' : 'small'}
              className={classes.astext}
              onClick={() => setCancelOpenIssue(true)}
            >
              <Typography className={classes.color}>Need Help?</Typography>
            </Button>
            <br></br>
            <Button
              size={lgMatch ? 'medium' : 'small'}
              className={classes.astext}
              onClick={() => setCongestionOpen(true)}
            >
              <Typography className={classes.color}>
                Any Additional Charges?
              </Typography>
            </Button>
            <br></br>
            <Box display='flex' className={classes.boxFloat}>
              <Button
                color='primary'
                size='medium'
                variant='contained'
                className={classes.submit}
                onClick={OrderCompleteHandler}
              >
                Verify
              </Button>
            </Box>
          </Box>
        </Card>
      </div>
    )
  }

  // 'order complete'
  function OrderComplete () {
    return (
      <div>
        <Card className={classes.centeredTrips} elevation={2}>
          <Box className={classes.boxWidth}>
            <CardContent>
              {orderDetail?.completeOrderProblemDetails
                ?.completeOrderProblem ? (
                <Typography className={classes.title1} color='textPrimary'>
                  Your order is completed due to{' '}
                  {
                    orderDetail?.completeOrderProblemDetails
                      ?.completeOrderProblem
                  }
                  . <br></br>Contact to admin for further detail: <br></br>+92
                  (21) 121-332-331
                </Typography>
              ) : (
                <Typography className={classes.title1} color='textPrimary'>
                  Your order was delivered successfully
                </Typography>
              )}
            </CardContent>
          </Box>
        </Card>
      </div>
    )
  }
  //#endregion

  //#region user name check
  const name = orderDetail?.user?.name ? orderDetail?.user?.name : 'User'

  let myStr2 = name.toUpperCase()
  let first = myStr2.match(/\b(\w)/g)
  const firstChar = first.slice(0, 3)
  //#endregion

  const open = Boolean(anchorEl)

  const id = open ? 'simple-popover' : undefined

  return (
    <div className={classes.root}>
      <NavigationDrawer />
      <CssBaseline />
      {loading ? (
        <div className={classes.divLoading}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid
            container
            justifyContent='center'
            align-items='center'
            display='flex'
            alignItems='stretch'
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={7}
              className={classes.container}
            >
              {isLoaded ? (
                <>
                  {openAlert ? (
                    <div className={classes.alertError}>
                      <Alert
                        variant='filled'
                        severity='error'
                        action={
                          <IconButton
                            aria-label='close'
                            color='inherit'
                            size='small'
                            onClick={() => {
                              setOpenAlert(false)
                            }}
                          >
                            <CloseIcon fontSize='inherit' />
                          </IconButton>
                        }
                      >
                        {errorMessage}
                        <Typography variant='subtitle1' component='h2'>
                          Please on Your Location.....
                        </Typography>
                      </Alert>
                    </div>
                  ) : null}
                  <GoogleMaps
                    places={
                      orderStatus?.includes('Order Confirmed')
                        ? directionPlaces
                        : orderStatus?.includes('on the way to pickup point')
                        ? driverDirection
                        : directionPlaces
                    }
                    updateLocation={
                      driverUpdateLocation ? driverUpdateLocation : ''
                    }
                    iconPosition={iconPosition}
                  />
                  <div className={classes.cardCentered}>
                    {orderDetail?.orderStatus?.includes(
                      'Awaiting Confirmation'
                    ) ? (
                      <AcceptRejectOrder />
                    ) : orderDetail?.orderStatus?.includes(
                        'Order Confirmed'
                      ) ? (
                      <StartTrip />
                    ) : orderDetail?.orderStatus?.includes(
                        'on the way to pickup point'
                      ) ? (
                      <ReachedPickUp />
                    ) : orderDetail?.orderStatus?.includes(
                        'reached pickup point'
                      ) ? (
                      <ToDestination />
                    ) : orderDetail?.orderStatus?.includes(
                        'on route to destination'
                      ) ? (
                      <ReachedDestination />
                    ) : orderDetail?.orderStatus?.includes(
                        'reached destination'
                      ) ? (
                      <EndTrip />
                    ) : orderDetail?.orderStatus?.includes('order complete') ? (
                      <OrderComplete />
                    ) : null}
                  </div>
                </>
              ) : (
                <Box className={classes.loader}>
                  <CircularProgress className={classes.progress} />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
              <Box className={classes.boxs}>
                {orderDetail?.orderStatus === 'Cancelled' ? null : (
                  <Card className={classes.cardroot}>
                    <Box className={classes.boxWidth}>
                      <CardContent className={classes.cardContent}>
                        <Box
                          display='flex'
                          style={{ justifyContent: 'space-between' }}
                        >
                          <Item>
                            <Avatar
                              className={clsx(classes.large, classes.purple)}
                            >
                              {orderDetail?.user?.name ? firstChar : null}
                            </Avatar>
                          </Item>

                          <label htmlFor='icon-button-file'>
                            <ListItem className={classes.list}>
                              <Typography>Address</Typography>
                              <IconButton
                                aria-label='upload picture'
                                component='span'
                                onClick={handleClick}
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            </ListItem>
                          </label>
                          <Popover
                            id={id}
                            open={open}
                            elevation={2}
                            className={classes.pop}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                            PaperProps={
                              matches
                                ? {
                                    style: { width: '55%' }
                                  }
                                : { style: { width: '20%' } }
                            }
                          >
                            <Paper className={classes.paper}>
                              <Grid container spacing={2}>
                                <Grid item xs={2} lg={1} align='center'>
                                  <Box marginTop={1}>
                                    <img
                                      src='/assets/images/pick1.png'
                                      alt='pick'
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={10} lg={11}>
                                  <Typography
                                    color='textPrimary'
                                    variant='body1'
                                  >
                                    Pickup
                                  </Typography>
                                  <Typography variant='body1'>
                                    {
                                      orderDetail?.tripStartLocation
                                        ?.formattedAddress
                                    }
                                  </Typography>
                                  <Box className={classes.divider}>
                                    <Divider />
                                  </Box>
                                  <Typography
                                    color='textPrimary'
                                    variant='body1'
                                  >
                                    Dropoff
                                  </Typography>
                                  <Typography variant='body1'>
                                    {
                                      orderDetail?.tripEndLocation
                                        ?.formattedAddress
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Popover>
                        </Box>
                        <Typography className={classes.textActive}>
                          {orderDetail?.user?.name}
                        </Typography>
                        <Typography
                          color='textPrimary'
                          className={classes.textActive}
                        >
                          {orderDetail?.rideType === 'small'
                            ? 'Medium Wheelbase Transit'
                            : orderDetail?.rideType === 'medium'
                            ? 'Long Wheelbase Transit'
                            : orderDetail?.rideType === 'large'
                            ? 'Ton luton body van with tail lift'
                            : orderDetail?.rideType === 'xl'
                            ? 'Ton luton box van with tail lift'
                            : null}
                        </Typography>

                        {orderDetail?.user?.userRating ? (
                          <Box
                            display='flex'
                            alignItems='center'
                            // justifyContent='center'
                          >
                            <Rating value={1} max={1} readOnly />
                            <Box>
                              <Typography color='secondary' variant='subtitle1'>
                                {orderDetail?.user?.userRating}
                              </Typography>
                            </Box>
                          </Box>
                        ) : null}
                        <Typography className={classes.textActive}>
                          <b>
                            Scheduled on{' '}
                            {moment(orderDetail?.dateAndTime).format(
                              'MMMM DD, YYYY'
                            )}{' '}
                            at{' '}
                            {moment(orderDetail?.dateAndTime).format('h:mm a')}
                          </b>
                        </Typography>
                        {phone && (
                          <div className={classes.div}>
                            <Typography
                              color='textPrimary'
                              variant='h6'
                              align='center'
                            >
                              Phone
                            </Typography>
                            <Typography
                              color='textPrimary'
                              variant='subtitle2'
                              align='center'
                            >
                              {orderDetail?.driver?.phone}
                            </Typography>
                          </div>
                        )}

                        {orderDetail?.orderStatus ===
                        'order complete' ? null : (
                          <Box>
                            <IconButton
                              className={classes.icons}
                              onClick={() => setPhone(!phone)}
                            >
                              <img
                                src={
                                  mdMatch
                                    ? '/assets/images/call.png'
                                    : '/assets/images/call/callSmallicon.png'
                                }
                                className={classes.img}
                              />
                            </IconButton>
                            <IconButton
                              className={classes.icons}
                              component={Link}
                              to={`/chat/${orderDetail?._id}`}
                            >
                              <img
                                src={
                                  mdMatch
                                    ? '/assets/images/chat.png'
                                    : '/assets/images/call/chatSmallicon.png'
                                }
                                alt='chat'
                              />
                            </IconButton>
                          </Box>
                        )}
                      </CardContent>
                    </Box>
                  </Card>
                )}
                <Card className={classes.cardroot}>
                  <CardContent className={classes.cardContent}>
                    <Grid
                      justifyContent='space-between' // Add it here :)
                      container
                      alignItems='center'
                    >
                      <Grid item xs={9} lg={10}>
                        <Typography className={classes.heading}>
                          <b>Order Summary</b>
                        </Typography>
                      </Grid>
                      {orderDetail?.userFeedback?.rating &&
                      orderDetail?.orderStatus === 'order complete' ? (
                        <Grid item xs={3} lg={2}>
                          <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                          >
                            <Grid
                              item
                              xs={9}
                              md={11}
                              lg={9}
                              className={classes.float}
                            >
                              <Rating
                                value={orderDetail?.userFeedback?.rating}
                                max={1}
                                size={lgMatch ? 'medium' : 'small'}
                                className={classes.float}
                                readOnly
                              />
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              md={1}
                              lg={3}
                              className={classes.float}
                            >
                              <Typography
                                color='textPrimary'
                                className={classes.heading}
                              >
                                {orderDetail?.userFeedback?.rating}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Box mt={'3%'}>
                      <Typography
                        className={classes.textActive}
                        color='textPrimary'
                      >
                        <b>Date: </b> &nbsp;
                        <span color='textPrimary'>
                          {moment(orderDetail?.dateAndTime).format(
                            'MMMM DD, YYYY h:mm a'
                          )}
                          &nbsp;
                        </span>
                      </Typography>
                    </Box>
                    <Typography
                      className={classes.textActive}
                      color='textPrimary'
                    >
                      <b>Order ID: </b> &nbsp;
                      <span color='textPrimary'>
                        {orderDetail?.ID ? orderDetail?.ID : orderDetail?._id}
                        &nbsp;
                      </span>
                    </Typography>
                    <Typography
                      className={classes.textActive}
                      color='textPrimary'
                    >
                      <b>Order Status: </b> &nbsp;
                      <span style={{ color: '#1EBC80' }}>
                        {orderDetail?.orderStatus}&nbsp;
                      </span>
                    </Typography>
                    {!activeZipCode.includes(
                      orderDetail?.tripEndLocation?.zipcode
                    ) &&
                    !orderDetail?.orderStatus?.includes(
                      'Awaiting Confirmation'
                    ) ? (
                      <Button
                        className={classes.astext}
                        size={lgMatch ? 'medium' : 'small'}
                        onClick={() => setOpenActive(true)}
                      >
                        <Typography
                          className={clsx(classes.textActive, classes.color)}
                        >
                          <b>Add Destination as active zip code</b>
                        </Typography>
                        <HelpIcon size='medium' className={classes.color} />
                      </Button>
                    ) : null}

                    <div>
                      {orderDetail?.completeOrderProblemDetails
                        ?.completeOrderProblem ? (
                        <Box>
                          <Typography
                            className={classes.textActive}
                            color='textPrimary'
                          >
                            <b>Complete Order Problem: </b> &nbsp;
                            <span color='textPrimary'>
                              {
                                orderDetail?.completeOrderProblemDetails
                                  ?.completeOrderProblem
                              }
                              &nbsp;
                            </span>
                          </Typography>
                          <Typography
                            className={classes.textActive}
                            color='textPrimary'
                          >
                            <b>Description: </b> &nbsp;
                            <span color='textPrimary'>
                              {
                                orderDetail?.completeOrderProblemDetails
                                  ?.description
                              }
                              &nbsp;
                            </span>
                          </Typography>
                        </Box>
                      ) : null}
                    </div>

                    {orderDetail?.orderStatus === 'order complete' ? null : (
                      <span>
                        <IconButton
                          className={classes.direction}
                          color='primary'
                          size='medium'
                          variant='contained'
                          onClick={() => {
                            if (
                              orderStatus?.includes(
                                'on the way to pickup point'
                              )
                            ) {
                              window.open(
                                `https://www.google.com/maps/dir/?api=1&destination=${parseFloat(
                                  latValue1
                                )},${parseFloat(lngValue1)}  
                      ` +
                                  `&travelmode=driving&layer=t&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
                                '_blank'
                              )
                            } else {
                              window.open(
                                `https://www.google.com/maps/dir/?api=1&destination=${parseFloat(
                                  latValue2
                                )},${parseFloat(lngValue2)}  
                      ` +
                                  `&travelmode=driving&layer=t&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,

                                '_blank'
                              )
                            }
                          }}
                        >
                          <DirectionsIcon
                            variant='contained'
                            className={classes.font}
                          />
                        </IconButton>
                      </span>
                    )}
                    <Divider className={classes.dividerMargin} />
                    <Typography
                      className={classes.textActive}
                      color='textPrimary'
                    >
                      <b>Total helpers: </b> &nbsp;
                      <span color='textPrimary'>
                        {orderDetail?.noOfHelpersRequired}&nbsp;
                      </span>
                    </Typography>
                    <Typography
                      className={classes.textActive}
                      color='textPrimary'
                    >
                      <b>Pickup Address: </b> &nbsp;
                      <span color='textPrimary'>
                        {orderDetail?.tripStartLocation?.formattedAddress}
                        &nbsp;
                      </span>
                    </Typography>
                    <Typography
                      className={classes.textActive}
                      color='textPrimary'
                    >
                      <b>Floor # : </b> &nbsp;
                      <span color='textPrimary'>
                        {orderDetail?.tripStartLocation?.floor}&nbsp;
                      </span>
                    </Typography>
                    <Typography
                      className={classes.textActive}
                      color='textPrimary'
                    >
                      <b>Destination Address: </b> &nbsp;
                      <span color='textPrimary'>
                        {orderDetail?.tripEndLocation?.formattedAddress}&nbsp;
                      </span>
                    </Typography>
                    <Typography
                      className={classes.textActive}
                      color='textPrimary'
                    >
                      <b>Floor # : </b> &nbsp;
                      <span color='textPrimary'>
                        {orderDetail?.tripEndLocation?.floor}&nbsp;
                      </span>
                    </Typography>
                    <Divider className={classes.dividerFloor} />
                    <Grid
                      justifyContent='space-between' // Add it here :)
                      container
                      alignItems='center'
                      spacing={3}
                    >
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={classes.text}
                          color='textPrimary'
                        >
                          <b>Total Price: </b> &nbsp;
                          <span color='textPrimary'>
                            £{orderDetail?.estimatedPrice?.minimumPrice}&nbsp;
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          color='primary'
                          size='medium'
                          variant='contained'
                          onClick={handleClickOpen}
                          className={classes.button}
                        >
                          Price Breakdown
                        </Button>
                        <Dialog
                          maxWidth={lgMatch ? 'sm' : 'xs'}
                          open={openDialog}
                          classes={{
                            paper: classes.dialog
                          }}
                          hideBackdrop={true}
                          onClose={handleCloseDialog}
                          aria-labelledby='alert-dialog-title'
                          aria-describedby='alert-dialog-description'
                        >
                          <DialogTitle
                            id='responsive-dialog-title'
                            className={classes.price}
                          >
                            <span>
                              <b>{'Estimated Price Breakdown'}</b>
                            </span>
                            <br />
                            Total Price: £
                            {orderDetail?.estimatedPrice?.minimumPrice}&nbsp;
                          </DialogTitle>
                          <DialogContent>
                            {options.map(option => (
                              <Grid container key={option.id}>
                                <Grid item xs={9} sm={8} md={7} lg={8} xl={7}>
                                  <Typography
                                    className={classes.titleAcc}
                                    color='textPrimary'
                                  >
                                    {option.title}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  xs={3}
                                  sm={4}
                                  md={5}
                                  lg={4}
                                  xl={5}
                                  justifyContent='flex-end'
                                >
                                  <Typography color='textPrimary'>
                                    <b> £{option.amount}</b>
                                  </Typography>
                                </Grid>
                                {option.id === 9 ? null : (
                                  <Divider className={classes.estDiv} />
                                )}
                              </Grid>
                            ))}
                            <Button
                              variant={
                                themeType === 'dark' ? 'contained' : 'outlined'
                              }
                              fullWidth
                              onClick={handleCloseDialog}
                              color='primary'
                              autoFocus
                            >
                              Close
                            </Button>
                          </DialogContent>
                        </Dialog>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
          {updateActiveError ? (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openSnackUpdateDriver}
              autoHideDuration={2000}
              onClose={handleCloseSnackUpdateDriver}
            >
              <Alert onClose={handleCloseSnackUpdateDriver} severity={'error'}>
                {updateActiveError}
              </Alert>
            </Snackbar>
          ) : null}
          {updateActiveSuccess ? (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openSnackUpdateDriver}
              autoHideDuration={2000}
              onClose={handleCloseSnackUpdateDriver}
            >
              <Alert
                onClose={handleCloseSnackUpdateDriver}
                severity={'success'}
              >
                {updateActiveSuccess}
              </Alert>
            </Snackbar>
          ) : null}
          {conjestionError ? (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openSnackDriver}
              autoHideDuration={2000}
              onClose={handleCloseSnackDriver}
            >
              <Alert onClose={handleCloseSnackDriver} severity={'error'}>
                {conjestionError}
              </Alert>
            </Snackbar>
          ) : null}
          {conjestionSuccess ? (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={openSnackDriver}
              autoHideDuration={2000}
              onClose={handleCloseSnackDriver}
            >
              <Alert onClose={handleCloseSnackDriver} severity={'success'}>
                {conjestionSuccess}
              </Alert>
            </Snackbar>
          ) : null}
        </main>
      )}
      <CancelOrder
        open={cancelOpen}
        orderID={orderDetail?._id}
        props={props}
        handleClose={handleCancelClose}
      />

      <CompleteOrderIssue
        open={cancelOpenIssue}
        orderID={orderDetail?._id}
        props={props}
        handleClose={handleCancelCloseIssue}
      />

      <CongestionCharges
        open={congestionOpen}
        orderID={orderDetail?._id}
        props={props}
        handleClose={handleCongestionClose}
      />

      <DestinationToActive
        open={openActive}
        orderID={orderDetail?._id}
        zipCode={orderDetail?.tripEndLocation?.zipcode}
        props={props}
        handleClose={handleCancelOpenActive}
      />
    </div>
  )
}
