//Libraries
import React, { useState } from 'react'
import { Container, CssBaseline, makeStyles } from '@material-ui/core'

//Components
import NotificationItem from '../Components/NotificationItem/NotificationItem.component'
import NavigationDrawer from '../Components/NavigationDrawer/NavigationDrawer'

//Style
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1
  },
  mainbox: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    borderRadius: 8,
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '90%'
    },
    [theme.breakpoints.only('md')]: {
      width: '85%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '80%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '80%'
    }
  },
  toolbar: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.only('xs')]: {
      marginTop: '25%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '14%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '12%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '8%',
      marginLeft: '5%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '6%'
    }
  }
}))
const Notifications = () => {
  const classes = useStyles()
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      description:
        'Zara your vehicle has been scheduled on 2 july 2021 from wing street etc.',
      time: '20 minutes ago'
    },
    {
      id: 2,
      description:
        'Zara your vehicle has been scheduled on 2 july 2021 from wing street etc.',
      time: '1 hour ago'
    }
  ])

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Container
            component='main'
            className={classes.mainbox}
            p={4}
            gap={2}
            bgcolor='background.paper'
          >
            {notifications.map(({ description, time }, index) => (
              <NotificationItem description={description} time={time} />
            ))}
          </Container>
        </div>
      </main>
    </div>
  )
}

export default Notifications
