//Libraries
import React, { useEffect, useState } from 'react'
import { DirectionsRenderer } from '@react-google-maps/api'

const MapDirections = props => {
  const { places, travelMode } = props
  const [directions, setDirections] = useState(null)

  useEffect(() => {
    const waypoints = places.map(p => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true
    }))

    const origin = waypoints.shift().location
    const destination = waypoints.pop().location

    const directionsService = new window.google.maps.DirectionsService()
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result)
        } else {
          console.log('error in MapDirection', result)
        }
      }
    )
  }, [JSON.stringify(places)])

  return directions && <DirectionsRenderer directions={directions} />
}

export default MapDirections
