//Libraries
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Paper,
  Box,
  TextField,
  CssBaseline,
  Button,
  Typography,
  makeStyles,
  Snackbar,
  useTheme,
  useMediaQuery,
  CardContent,
  Hidden,
  Collapse,
  Divider
} from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'

//Components
import actions from '../../../Redux/Driver/Driver.actions'
import './OTP.css'
import Yup from '../../../Yup/YupAuthValidation.schemas'

function Alert (props) {
  return <MuiAlert elevation={2} variant='filled' {...props} />
}

//Style
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  button: {
    textAlign: 'left',
    textDecoration: 'none'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  expand: {
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  image: {
    width: '80%',
    height: 'auto'
  },
  textField: {
    textTransform: 'uppercase'
  }
}))

export default function OTP (props) {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const dispatch = useDispatch()

  const [initialValues1, setInitialValues1] = useState({
    otp: ''
  })

  useEffect(() => {
    console.log('clearing previous error')
    dispatch(actions.clearError())
  }, [])

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const phone = useSelector(state => state.driver.phone)

  const otpResentSuccess = useSelector(
    state => state.driver.success.otpResentSuccess
  )

  const otpError = useSelector(state => state.driver.errors.otpError)

  const isForgotPassword = false
  const updatePhoneNewDriverSuccess = useSelector(
    state => state.driver.success.updatePhoneNewDriverSuccess
  )
  const updatePhoneNewDriverError = useSelector(
    state => state.driver.errors.updatePhoneNewDriverError
  )
  const isLoggedIn = useSelector(state => state.driver.isLoggedIn)
  const [initialValues, setInitialVales] = useState({
    phone: phone
  })
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const resetResendButton = () => {
    setSeconds(60)
    setIsButtonDisabled(true)
  }

  const resetPhoneButton = () => {
    setSeconds(60)
    setIsButtonDisabled(true)
  }

  const initialTimer = localStorage.getItem('seconds') ?? 60
  const timeoutId = React.useRef(null)
  const [seconds, setSeconds] = React.useState(initialTimer)

  const countTimer = React.useCallback(() => {
    if (seconds <= 0) {
      localStorage.clear('seconds')
    } else {
      setSeconds(seconds - 1)
      localStorage.setItem('seconds', seconds - 1)
    }
  }, [seconds])

  React.useEffect(() => {
    if (seconds > 0) {
      timeoutId.current = window.setTimeout(countTimer, 1000)
      // cleanup function
      return () => window.clearTimeout(timeoutId.current)
    } else {
      setSeconds(0)
      setIsButtonDisabled(false)
    }
  }, [seconds, countTimer])

  const [openSnackOtp, setOpenSnackOtp] = useState(false)

  const handleErrorSnackOtp = () => {
    setOpenSnackOtp(true)
  }

  const handleSuccessSnackOtp = () => {
    setOpenSnackOtp(true)
  }

  const handleCloseSnackOtp = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackOtp(false)
  }

  const [openSnackUpdatePhone, setOpenSnackUpdatePhone] = useState(false)

  const handleErrorSnackUpdatePhone = () => {
    setOpenSnackUpdatePhone(true)
  }

  const handleCloseSnackUpdatePhone = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackUpdatePhone(false)
  }

  useEffect(() => {
    if (updatePhoneNewDriverError && !updatePhoneNewDriverSuccess) {
      handleErrorSnackUpdatePhone()
    } else if (updatePhoneNewDriverSuccess && !updatePhoneNewDriverError) {
      setExpanded(!expanded)
      handleErrorSnackUpdatePhone()
    } else if (otpError) {
      handleErrorSnackOtp()
    } else if (otpResentSuccess) {
      handleSuccessSnackOtp()
    }
  }, [
    updatePhoneNewDriverError,
    updatePhoneNewDriverSuccess,
    otpError,
    otpResentSuccess
  ])

  return (
    <Grid container component='main'>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        component={Paper}
        className={classes.root}
        elevation={0}
        square
      >
        <div className={classes.paper}>
          <Box mt='10%'>
            <CardContent>
              <Collapse in={!expanded} timeout='auto' unmountOnExit>
                <Typography
                  component='h1'
                  variant={matches ? 'subtitle1' : 'h5'}
                  align='center'
                >
                  Enter Code You Received On Your Phone
                </Typography>
                <Formik
                  initialValues={initialValues1}
                  validationSchema={Yup.otpSchema}
                  onSubmit={values => {
                    console.log('phoneOTP', values)
                    dispatch(
                      actions.verifyOTP(values.otp, phone, props, isLoggedIn)
                    )
                  }}
                >
                  {({ errors, handleChange, touched }) => (
                    <Form className={classes.form}>
                      <TextField
                        error={errors.otp && touched.otp}
                        helperText={
                          errors.otp && touched.otp ? errors.otp : null
                        }
                        variant='outlined'
                        margin='normal'
                        className={classes.textField}
                        required
                        fullWidth
                        onChange={handleChange}
                        id='otp'
                        placeholder='Enter OTP'
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                        name='otp'
                        autoFocus
                      />
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                      >
                        Confirm
                      </Button>
                    </Form>
                  )}
                </Formik>

                <Button
                  fullWidth
                  color='primary'
                  size='large'
                  variant='outlined'
                  disabled={isButtonDisabled}
                  onClick={() => {
                    dispatch(actions.requestOTP(phone, isForgotPassword, props))
                    resetResendButton()
                  }}
                >
                  {seconds === 0
                    ? 'Resend OTP'
                    : `Resend OTP in ${seconds} seconds`}
                </Button>

                <Divider
                  className={classes.divider}
                  style={
                    isLoggedIn ? { display: 'none' } : { display: 'block' }
                  }
                />
              </Collapse>
              {expanded ? null : (
                <Button
                  fullWidth
                  color='primary'
                  size='large'
                  variant='outlined'
                  style={
                    isLoggedIn ? { display: 'none' } : { display: 'block' }
                  }
                  onClick={() => {
                    handleExpandClick()
                  }}
                >
                  Update Phone Number
                </Button>
              )}
            </CardContent>
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <CardContent>
                <Typography
                  component='h1'
                  variant={matches ? 'subtitle1' : 'h5'}
                  align='center'
                >
                  Enter Your Phone Number
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.OTPUpdatePhoneValidationSchema}
                  onSubmit={values => {
                    console.log(
                      '%c OTP Phone Value',
                      'color: #76FF03; font-weight: bold',
                      values
                    )
                    dispatch(actions.updateUnverifiedPhone(phone, values.phone))
                    resetPhoneButton()
                  }}
                >
                  {({ errors, handleChange, touched }) => (
                    <Form className={classes.form}>
                      <TextField
                        error={errors.phone && touched.phone}
                        helperText={
                          errors.phone && touched.phone ? errors.phone : null
                        }
                        defaultValue={phone}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        id='phone'
                        label='Phone'
                        name='phone'
                        autoComplete='phone'
                        type='tel'
                        onChange={handleChange}
                      />
                      <Button
                        type='submit'
                        fullWidth
                        disabled={isButtonDisabled}
                        color='primary'
                        size='large'
                        variant='contained'
                        id='update-phone'
                        className={classes.submit}
                      >
                        {seconds === 0
                          ? ' Update Phone Number'
                          : ` Update Phone Number in ${seconds} seconds`}
                      </Button>
                    </Form>
                  )}
                </Formik>
                {expanded ? (
                  <Button
                    fullWidth
                    size='large'
                    variant='text'
                    className={classes.button}
                    onClick={() => {
                      handleExpandClick()
                    }}
                  >
                    Back to Verify OTP
                  </Button>
                ) : null}
              </CardContent>
            </Collapse>
          </Box>
        </div>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        lg={6}
        component={Paper}
        elevation={0}
        square
        style={{
          textAlign: 'center'
        }}
      >
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          className={classes.root}
        >
          <Hidden smDown>
            <img
              src='/assets/images/otp.png'
              alt='otp'
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
      {updatePhoneNewDriverError || updatePhoneNewDriverSuccess ? (
        <Snackbar
          open={openSnackUpdatePhone}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={handleCloseSnackUpdatePhone}
        >
          <Alert
            onClose={handleCloseSnackUpdatePhone}
            severity={updatePhoneNewDriverError ? 'error' : 'success'}
          >
            {updatePhoneNewDriverError
              ? updatePhoneNewDriverError
              : updatePhoneNewDriverSuccess}
          </Alert>
        </Snackbar>
      ) : null}
      {otpError || otpResentSuccess ? (
        <Snackbar
          open={openSnackOtp}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={handleCloseSnackOtp}
        >
          <Alert
            onClose={handleCloseSnackOtp}
            severity={otpError ? 'error' : 'success'}
          >
            {otpError ? otpError : otpResentSuccess}
          </Alert>
        </Snackbar>
      ) : null}
    </Grid>
  )
}
