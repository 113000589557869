//Libraries
import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

//Components
import NavigationDrawer from '../../Components/NavigationDrawer/NavigationDrawer'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 14
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: 18
    }
  },
  content: {
    flexGrow: 1
  },
  maintitle: {
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: 16,
      marginTop: '3%'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 22,
      marginTop: '3%'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 28,
      marginTop: '5%'
    }
  },
  image: {
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '40%',
      height: '30%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '30%',
      height: '20%'
    },
    [theme.breakpoints.only('md')]: {
      width: '30%',
      height: '20%'
    }
  },
  mainbox: {
    width: '100%',
    textAlign: 'center'
  },
  toolbar: {
    height: '100vh',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  box: {
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: '3%'
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '2%'
    }
  }
}))

export default function DocumentApproval () {
  const classes = useStyles()

  return (
    <div>
      <NavigationDrawer />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Box className={classes.mainbox}>
            <img
              src='/assets/images/docs.png'
              alt='docs'
              className={classes.image}
            />
            <Typography className={classes.maintitle}>
              Please wait for document Approval
            </Typography>
            <Typography className={classes.title} color='secondary'>
              Our team is working
            </Typography>
            <Box className={classes.box}>
              <CircularProgress color='primary' />
            </Box>
          </Box>
        </div>
      </main>
    </div>
  )
}
