//Libraries
import React, { useState, useEffect } from "react";
import {
  Box,
  Zoom,
  Hidden,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  CssBaseline,
  useMediaQuery,
  makeStyles,
  Typography,
  Grid,
  Snackbar,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";

//Components
import actions from "../../Redux/Driver/Driver.actions";
import DocumentCard from "../../Components/DocumentUpload/DocumentCard";
import Yup from "../../Yup/YupAuthValidation.schemas";
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "nowrap",
  },
  img: {
    [theme.breakpoints.between("xs", "md")]: {
      width: 45.45,
      height: 32.67,
    },
  },
  paper: {
    marginBottom: theme.spacing(0),
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(3, 3),
      marginTop: "20%",
    },
    [theme.breakpoints.only("sm")]: {
      margin: theme.spacing(6, 8),
      marginTop: "15%",
    },
    [theme.breakpoints.only("md")]: {
      margin: theme.spacing(6, 7),
      marginTop: "13%",
    },
    [theme.breakpoints.only("lg")]: {
      margin: theme.spacing(16, 17),
      marginTop: "9%",
    },
    [theme.breakpoints.only("xl")]: {
      margin: theme.spacing(3, 4),
      marginTop: "10%",
    },
  },
  form: {
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.only("md")]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.only("lg")]: {
      marginTop: theme.spacing(2),
      alignItems: "center",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: theme.spacing(4),
      alignItems: "center",
    },
  },
  div: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  submit: {
    fontSize: 18,
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(5, 0, 2),
      width: 192.83,
    },
    [theme.breakpoints.between("sm", "lg")]: {
      margin: theme.spacing(3, 0, 2),
      width: 392.83,
    },
    [theme.breakpoints.only("xl")]: {
      margin: theme.spacing(8, 0, 2),
      width: 392.83,
    },
  },
  heading: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 16,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: 24,
    },
  },
  fab: {
    float: "right",
    fontWeight: "bold",
    [theme.breakpoints.down("lg")]: {
      left: "0px",
      margin: theme.spacing(2, 0, 0),
    },
    [theme.breakpoints.only("xl")]: {
      left: "100px",
      margin: theme.spacing(2, 0, 3),
    },
  },
  fabLeft: {
    float: "right",
    margin: theme.spacing(2, 0, 2),
    fontWeight: "bold",
    [theme.breakpoints.only("xs")]: {
      left: "60px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      left: "100px",
    },
    [theme.breakpoints.only("xl")]: {
      margin: theme.spacing(2, 0, 3),
    },
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  loader: {
    width: "50px",
    height: "50px",
  },
  divLoader: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  textField: {
    marginTop: "1%",
  },
}));

const vehicleTypeOptions = [
  {
    id: 1,
    img: "/assets/images/mwb.png",
    name: "Mwb",
    value: "small",
    Desc: "Medium Wheelbase Transit",
  },
  {
    id: 2,
    img: "/assets/images/Lwb.png",
    name: "Lwb",
    value: "medium",
    Desc: "Long Wheelbase Transit",
  },
  {
    id: 3,
    img: "/assets/images/Tlbv.png",
    name: "Tlbv",
    value: "large",
    Desc: "Ton luton body van with tail lift",
  },
  {
    id: 4,
    img: "/assets/images/Tlbw.png",
    name: "Tlbw",
    value: "xl",
    Desc: "Ton luton box van with tail lift",
  },
];

export default function DocumentEdit() {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const driverData = useSelector((state) => state.driver);
  const docsData = useSelector((state) => state.driver.Documents);
  console.log("docsData", docsData);
  const [flag, setFlag] = useState(0);
  useEffect(() => {
    console.log("clearing previous error");
    dispatch(actions.clearError());
  }, []);
  const [initialValues, setInitialValues] = useState(
    docsData
      ? {
          doc1: driverData?.Documents?.drivingLicence,
          doc2: driverData?.Documents?.vehicleLicence,
          doc3: driverData?.Documents?.vehicleInsurance,
          doc4: driverData?.Documents?.personalIDCard,
          vehicleType: driverData?.vehicleFiles?.vehicleType,
          vehicleNumber: driverData?.vehicleFiles?.vehicleNumber,
          vehicleColor: driverData?.vehicleFiles?.vehicleColor,
        }
      : {
          doc1: null,
          doc2: null,
          doc3: null,
          doc4: null,
          vehicleType: "",
          vehicleNumber: "",
          vehicleColor: "",
        }
  );
  const addDocsError = useSelector((state) => state.driver.errors.addDocsError);
  const addDocsSuccess = useSelector(
    (state) => state.driver.success.addDocsSuccess
  );

  const [openSnackUpdateDriver, setOpenSnackUpdateDriver] = useState(false);

  const handleErrorSnackUpdateDriver = () => {
    setOpenSnackUpdateDriver(true);
    setEditDisabled(true);
    setAnimateFab(0);
  };

  const handleCloseSnackUpdateDriver = (event, reason) => {
    dispatch(actions.clearError());
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackUpdateDriver(false);
  };
  const isFetching = useSelector((state) => state.driver.isFetching);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const mdmatches = useMediaQuery(theme.breakpoints.down("md"));
  const lgmatches = useMediaQuery(theme.breakpoints.down("lg"));
  const [editDisabled, setEditDisabled] = useState(true);
  const [animateFab, setAnimateFab] = useState(0);
  const jwt = useSelector((state) => state.driver.jwt);

  const options = [
    {
      id: "click-license",
      CardName: "Driving License",
      doc: "doc1",
    },
    {
      id: "click-vehicle-id",
      CardName: "Vehicle License",
      doc: "doc2",
    },
    {
      id: "click-vehicle-insurance",
      CardName: "Vehicle Insurance",
      doc: "doc3",
    },
    {
      id: "click-personal-id",
      CardName: "Personal ID Card",
      doc: "doc4",
    },
  ];

  useEffect(() => {
    if (driverData) {
      setInitialValues(() => {
        return {
          doc1: driverData?.Documents?.drivingLicence,
          doc2: driverData?.Documents?.vehicleLicence,
          doc3: driverData?.Documents?.vehicleInsurance,
          doc4: driverData?.Documents?.personalIDCard,
          vehicleType: driverData?.vehicleFiles?.vehicleType,
          vehicleNumber: driverData?.vehicleFiles?.vehicleNumber,
          vehicleColor: driverData?.vehicleFiles?.vehicleColor,
        };
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (addDocsError && !addDocsSuccess) {
      handleErrorSnackUpdateDriver();
    } else if (addDocsSuccess && !addDocsError) {
      handleErrorSnackUpdateDriver();
    }
  }, [addDocsError, addDocsSuccess]);

  const handleDialogNo = () => {
    // setFlag(0)
    setOpenDialog(false);
    setEditDisabled(true);
    setAnimateFab(0);
  };

  const handleDialogYes = () => {
    console.log("initiallll values if yes", initialValues);
    const docs = "home";
    dispatch(actions.accountVerificationDocs(initialValues, jwt, docs));
    setOpenDialog(false);
    setEditDisabled(true);
    setAnimateFab(1);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
   
  };

  const fabs = [
    {
      id: "cl-edit",
      color: "primary",
      className: classes.fab,
      icon: <img src="/assets/images/edit.png" alt="edit" />,
      label: "Edit",
    },
    {
      id: "cl-save",
      color: "primary",
      className: classes.fabLeft,
      icon: <img src="/assets/images/tick.png" alt="save" />,
      label: "Save",
    },
  ];

  const onClickFab = () => {
    if (animateFab === 0) {
      setAnimateFab(1);
      setEditDisabled(false);
    } else if (animateFab === 1) {
      // setAnimateFab(0)
      setEditDisabled(true);
      setOpenDialog(true);
    }
  };

  const orderDataHandler = (type, data) => {
    console.log("orderDetailsHandler", data);
    if (type === "doc1") {
      console.log(type, data);
      setInitialValues({
        ...initialValues,
        doc1: data,
      });
    } else if (type === "doc2") {
      console.log(type, data);
      setInitialValues({
        ...initialValues,
        doc2: data,
      });
    } else if (type === "doc3") {
      console.log(type, data);
      setInitialValues({
        ...initialValues,
        doc3: data,
      });
    } else if (type === "doc4") {
      console.log(type, data);
      setInitialValues({
        ...initialValues,
        doc4: data,
      });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <NavigationDrawer />
      <CssBaseline />

      {loading ? (
        <div className={classes.divLoader}>
          <CircularProgress className={classes.loader} />
        </div>
      ) : (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={9} elevation={2}>
            <div className={classes.paper}>
              {isFetching && (
                <div className={classes.divLoader}>
                  <CircularProgress className={classes.loader} />
                </div>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.editDocumentVerificationSchema}
                onSubmit={(values) => {
                  console.log("values");
                  console.log(values);
                  setInitialValues({
                    ...initialValues,
                    vehicleColor: values.vehicleColor,
                    vehicleNumber: values.vehicleNumber,
                    vehicleType: values.vehicleType,
                  });
                  // setOpenDialog(true)
                  setAnimateFab(1);
                  setEditDisabled(false);
                }}
              >
                {({
                  errors,
                  handleChange,
                  handleSubmit,
                  touched,
                  setFieldValue,
                  validateForm,
                  values,
                }) => (
                  <Form
                    className={classes.form}
                    id="nicknameForm"
                    onSubmit={handleSubmit}
                  >
                    <Grid container spacing={mdmatches ? 2 : 5}>
                      {options.map((option) => (
                        <Grid
                          key={option.id}
                          item
                          xs={6}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={3}
                          align="center"
                        >
                          <DocumentCard
                            id={option.id}
                            doc={option.doc}
                            name={option.doc}
                            CardName={option.CardName}
                            setFieldValue={setFieldValue}
                            orderDataHandler={orderDataHandler}
                            disabled={editDisabled}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Box mt={lgmatches ? 5 : 10}>
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={12} sm={3} md={2} lg={2} xl={3}>
                          <Typography className={classes.heading}>
                            Vehicle Type
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={10} lg={10} xl={9}>
                          <TextField
                            error={errors.vehicleType && touched.vehicleType}
                            helperText={
                              errors.vehicleType && touched.vehicleType
                                ? errors.vehicleType
                                : null
                            }
                            id="vehicleType"
                            name="vehicleType"
                            select
                            disabled={editDisabled}
                            fullWidth
                            className={classes.textField}
                            defaultValue={
                              driverData
                                ? driverData.vehicleFiles.vehicleType
                                : ""
                            }
                            onChange={handleChange}
                            variant="outlined"
                          >
                            {vehicleTypeOptions?.map((option) => {
                              return (
                                <MenuItem
                                  value={option.value || ""}
                                  style={
                                    option.id === 4
                                      ? { background: "none" }
                                      : {
                                          background: "none",
                                          borderBottom: "1px solid grey",
                                        }
                                  }
                                  key={option.id}
                                >
                                  <div className={classes.div}>
                                    <Typography variant="subtitle1">
                                      {option.Desc}
                                    </Typography>
                                    <img
                                      src={option.img}
                                      alt="options"
                                      width="50"
                                    />
                                  </div>
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={lgmatches ? 2 : 6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={3} md={2} lg={2} xl={3}>
                          <Typography className={classes.heading}>
                            Vehicle Number
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={10} lg={10} xl={9}>
                          <TextField
                            id="vehicleNumber"
                            error={
                              errors.vehicleNumber && touched.vehicleNumber
                            }
                            helperText={
                              errors.vehicleNumber && touched.vehicleNumber
                                ? errors.vehicleNumber
                                : null
                            }
                            name="vehicleNumber"
                            disabled={editDisabled}
                            defaultValue={
                              driverData
                                ? driverData.vehicleFiles.vehicleNumber
                                : ""
                            }
                            fullWidth
                            onChange={handleChange}
                            className={classes.textField}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={lgmatches ? 2 : 6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={3} md={2} lg={2} xl={3}>
                          <Typography className={classes.heading}>
                            Vehicle Color
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={10} lg={10} xl={9}>
                          <TextField
                            id="vehicleColor"
                            error={errors.vehicleColor && touched.vehicleColor}
                            helperText={
                              errors.vehicleColor && touched.vehicleColor
                                ? errors.vehicleColor
                                : null
                            }
                            name="vehicleColor"
                            disabled={editDisabled}
                            defaultValue={
                              driverData
                                ? driverData.vehicleFiles.vehicleColor
                                : ""
                            }
                            fullWidth
                            onChange={handleChange}
                            className={classes.textField}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Hidden xlUp>
                      {fabs.map((fab, index) => (
                        <Zoom
                          key={fab.id}
                          in={animateFab === index}
                          timeout={400}
                        >
                          <Button
                            type={fab.label === "Save" ? "submit" : null}
                            form={fab.label === "Save" ? "nicknameForm" : null}
                            aria-label={fab.label}
                            className={fab.className}
                            color={fab.color}
                            onClick={fab.label === "Save" ? onClickFab : null}
                            variant="contained"
                          >
                            {fab.icon}
                            <Hidden xsDown>
                              <Box m="10px">{fab.label}</Box>
                            </Hidden>
                          </Button>
                        </Zoom>
                      ))}
                    </Hidden>
                  </Form>
                )}
              </Formik>
            </div>
          </Grid>
          <Hidden lgDown>
            <Grid
              item
              className={classes.grid}
              xs={false}
              sm={false}
              md={false}
              lg={false}
              xl={2}
              align="center"
            >
              {fabs.map((fab, index) => (
                <Zoom key={fab.id} in={animateFab === index} timeout={400}>
                  <Button
                    type={fab.label === "Save" ? "submit" : null}
                    form={fab.label === "Save" ? "nicknameForm" : null}
                    aria-label={fab.label}
                    className={fab.className}
                    color={fab.color}
                    onClick={
                      fab.label === "Save"
                        ? () => setOpenDialog(true)
                        : onClickFab
                    }
                    variant="contained"
                  >
                    {fab.icon}
                    <Hidden xsDown>
                      <Box m="10px">{fab.label}</Box>
                    </Hidden>
                  </Button>
                </Zoom>
              ))}
            </Grid>
          </Hidden>
        </>
      )}
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // disableBackdropClick={true}
        hideBackdrop={true}
      >
        <DialogTitle id="alert-dialog-title">{"Update Documents?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to update your documents?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="click-no-btn"
            onClick={handleDialogNo}
            variant="contained"
          >
            No
          </Button>
          <Button
            id="click-yes-btn"
            onClick={handleDialogYes}
            color="primary"
            autoFocus
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {addDocsError || addDocsSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackUpdateDriver}
          autoHideDuration={2000}
          onClose={handleCloseSnackUpdateDriver}
        >
          <Alert
            onClose={handleCloseSnackUpdateDriver}
            severity={addDocsError ? "error" : "success"}
          >
            {addDocsError ? addDocsError : addDocsSuccess}
          </Alert>
        </Snackbar>
      ) : null}
    </Grid>
  );
}
