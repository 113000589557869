import * as yup from 'yup'

const phoneRegExpNew = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
const onlyStringRegExp = /^[a-zA-Z\s]+$/
const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/

const schemata = {
  SignInValidationSchema: yup.object().shape({
    username: yup.string().required('This field is required.'),
    password: yup.string().required('This field is required.')
  }),

  otpSchema: yup.object().shape({
    otp: yup.string().required('This field is required.')
  }),

  SignUpValidationSchema: yup.object().shape({
    name: yup
      .string()
      .matches(onlyStringRegExp, 'Numbers and symbols are not Allowed.')
      .required('This field is required.'),
    email: yup
      .string()
      .email('Invalid email format.')
      .required('This field is required.'),
    phone: yup
      .string()
      .matches(phoneRegExpNew, 'Invalid phone number format.')
      .required('This field is required.'),
    address: yup.string().required('This field is required.'),
    city: yup
      .string()
      .matches(onlyStringRegExp, 'Numbers and symbols are not Allowed.')
      .required('This field is required.'),
    zipcode: yup.string().required('This field is required'),

    activeZipcodes: yup
      .array()
      .required('This field is required')
      .min(1, 'This field must have at least 1 item'),
  }),

  documentVerificationSchema: yup.object().shape({
    vehicleNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9\s]+$/, 'symbols are not Allowed.')
      .required('This field is required.')
      .max(8, 'last limit 8 digits'),
    vehicleColor: yup
      .string()
      .required('Please enter the required field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .max(35, 'last limit 35 digits'),
    vehicleType: yup
      .string()
      .required('This field is required.')
      .nullable(),
    doc1: yup
      .mixed()
      .nullable()
      .required('This file is required.'),

    doc2: yup
      .mixed()
      .nullable()
      .required('This file is required.'),

    doc3: yup
      .mixed()
      .nullable()
      .required('This file is required.'),

    doc4: yup
      .mixed()
      .nullable()
      .required('This file is required.')
  }),

  editDocumentVerificationSchema: yup.object().shape({
    vehicleNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9\s]+$/, 'symbols are not Allowed.')
      .required('This field is required.')
      .max(8, 'last limit 8 digits')
      .nullable(),
    vehicleColor: yup
      .string()
      .required('This field required field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .max(35, 'last limit 35 digits')
      .nullable(),
    vehicleType: yup
      .string()
      .required('This field is required.')
      .nullable(),
    doc1: yup
      .mixed()
      .nullable()
      .required('This file is required.'),

    doc2: yup
      .mixed()
      .nullable()
      .required('This file is required.'),

    doc3: yup
      .mixed()
      .nullable()
      .required('This file is required.'),

    doc4: yup
      .mixed()
      .nullable()
      .required('This file is required.')
  }),

  profileValidationSchema: yup.object().shape({
    name: yup
      .string()
      .matches(onlyStringRegExp, 'Numbers and symbols are not Allowed.')
      .required('This field is required.'),
    address: yup.string().required('This field is required.'),
    city: yup
      .string()
      .matches(onlyStringRegExp, 'Numbers and symbols are not Allowed.')
      .required('This field is required.'),
    zipcode: yup.string().required('This field is required'),
    activeZipcodes: yup
      .array()
      .required('This field is required')
      .min(1, 'This field must have at least 1 item')
      .nullable()
  }),

  ConfirmPasswordSchema: yup.object().shape({
    password: yup
      .string()
      .matches(
        passwordRegExp,
        'Password must have 8-15 characters, 1 lowercase, 1 uppercase, 1 numeric and 1 special character'
      )
      .required('This field is required'),
    confirmPassword: yup
      .string()
      .required('This field is required.')
      .when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref('password')], 'Password does not match.')
      })
  }),

  ForgotPasswordValidationSchema: yup.object().shape({
    phone: yup
      .string()
      .matches(phoneRegExpNew, 'Invalid phone number format.')
      .required('This field is required.')
  }),

  OTPUpdatePhoneValidationSchema: yup.object().shape({
    phone: yup
      .string()
      .matches(phoneRegExpNew, 'Invalid phone number format.')
      .required('This field is required.')
  })
}

export default schemata
