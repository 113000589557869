//Libraries
import axios from "axios";

//Components
import backendPath from "../../Utils/constants";
import history from "../../Utils/history";

const actions = {
  //#region ACTION DECLARATIONS

  GET_DRIVER: "driver/GET_DRIVER",
  SET_DRIVER: "driver/SET_DRIVER",

  FRESH_SIGN_UP: "driver/FRESH_SIGN_UP",
  UPDATE_PASSWORD_COMPLETE: "driver/UPDATE_PASSWORD_COMPLETE",

  SIGN_UP: "driver/SIGN_UP",
  SET_SIGN_UP_SUCCESS: "driver/SET_SIGN_UP_SUCCESS",
  SET_SIGN_UP_ERROR: "driver/SET_SIGN_UP_ERROR",
  SIGN_UP_COMPLETE: "driver/SIGN_UP_COMPLETE",

  SIGN_IN: "driver/SIGN_IN",
  SIGN_IN_COMPLETE: "driver/SIGN_IN_COMPLETE",
  SET_SIGN_IN_ERROR: "driver/SET_SIGN_IN_ERROR",

  SET_PASSWORD: "driver/SET_PASSWORD",

  SIGN_OUT: "driver/SIGN_OUT",

  VERIFY_OTP: "driver/VERIFY_OTP",
  REQUEST_OTP: "driver/REQUEST_OTP",
  SET_OTP_ERROR: "driver/SET_OTP_ERROR",
  RESENT_OTP_SUCCESS: "driver/RESENT_OTP_SUCCESS",
  SET_PHONE_OTP_ERROR: "driver/SET_PHONE_OTP_ERROR",

  RESENT_PHONE_OTP_SUCCESS: "driver/RESENT_PHONE_OTP_SUCCESS",
  REQUEST_OTP_LOGIN: "driver/REQUEST_OTP_LOGIN",

  UPDATE_PHONE_NEW_DRIVER: "driver/UPDATE_PHONE_NEW_DRIVER",
  SET_UPDATE_PHONE_NEW_DRIVER_SUCCESS:
    "driver/SET_UPDATE_PHONE_NEW_DRIVER_SUCCESS",
  SET_UPDATE_PHONE_NEW_DRIVER_ERROR: "driver/SET_UPDATE_PHONE_NEW_DRIVER_ERROR",

  UPDATE_DRIVER: "driver/UPDATE_DRIVER",
  SET_UPDATE_DRIVER_SUCCESS: "driver/SET_UPDATE_DRIVER_SUCCESS",
  SET_UPDATE_DRIVER_ERROR: "driver/SET_UPDATE_DRIVER_ERROR",

  SET_FORGOT_PASSWORD_ERROR: "driver/SET_FORGOT_PASSWORD_ERROR",
  SET_FORGOT_PASSWORD_SUCCESS: "driver/SET_FORGOT_PASSWORD_SUCCESS",

  SET_JWT: "driver/SET_JWT",
  CLEAR_ERROR: "driver/CLEAR_ERROR",
  ADD_DOCS: "driver/ADD_DOCS",
  SET_ADD_DOCS_SUCCESS: "driver/SET_ADD_DOCS_SUCCESS",
  SET_ADD_DOCS_ERROR: "driver/SET_ADD_DOCS_ERROR",
  UPDATE_DRIVER_STATUS: "driver/UPDATE_DRIVER_STATUS",
  SET_UPDATE_DRIVER_STATUS_SUCCESS: "driver/SET_UPDATE_DRIVER_STATUS_SUCCESS",
  SET_UPDATE_DRIVER_STATUS_ERROR: "driver/SET_UPDATE_DRIVER_STATUS_ERROR",
  CANCEL_ORDER: "driver/CANCEL_ORDER",

  CURRENT_PASS: "driver/CURRENT_PASS",
  SET_CURRENT_PASS_SUCCESS: "driver/SET_CURRENT_PASS_SUCCESS",
  SET_CURRENT_PASS_ERROR: "driver/SET_CURRENT_PASS_ERROR",
  SET_REMEMBER_ME: "driver/SET_REMEMBER_ME",
  CLEAR_REMEMBER_ME: "driver/CLEAR_REMEMBER_ME",

  UPDATE_PHONE_DRIVER: "driver/UPDATE_PHONE_DRIVER",
  SET_UPDATE_PHONE_DRIVER_SUCCESS: "driver/SET_UPDATE_PHONE_DRIVER_SUCCESS",
  SET_UPDATE_PHONE_DRIVER_ERROR: "driver/SET_UPDATE_PHONE_DRIVER_ERROR",
  SET_UPDATE_ACTIVE_SUCCESS: "driver/SET_UPDATE_ACTIVE_SUCCESS",
  SET_UPDATE_ACTIVE_ERROR: "driver/SET_UPDATE_ACTIVE_ERROR",
  SET_UPDATE_NOT_ACTIVE: "driver/SET_UPDATE_NOT_ACTIVE",
  SET_CONTACT_US_SUCCESS: " SET_CONTACT_US_SUCCESS",
  SET_CONTACT_US_ERROR: "SET_CONTACT_US_ERROR",
  //#endregion

  //#region SIGN_IN & GET_DRIVER
  signIn: (signInObj) => (dispatch) => {
    dispatch({
      type: actions.SIGN_IN,
    });
    axios({
      method: "post",
      url: backendPath + "login",
      data: {
        username: signInObj.username,
        password: signInObj.password,
      },
    })
      .then(function (response) {
        console.log('response',response)
        // handle response
        if (response.status === 200) {
          if (response.data && response.data.jwt) {
            console.log(
              "%c Sign In RES",
              "color: #76FF03; font-weight: bold",
              response
            );
            if (signInObj.checked === true) {
              console.log("remember me", signInObj);
              dispatch(actions.setRememberMe(signInObj));
            } else if (signInObj.checked === false) {
              dispatch(actions.clearRememberMe());
            }
            dispatch(actions.getDriver(response.data.jwt));
          } else {
            dispatch(actions.setSignInError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log("%c Sign In ERR", "color: red; font-weight: bold", error);
        if (error.response) {
          dispatch(
            actions.setSignInError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setSignInError("Network Error"));
        } else {
          dispatch(actions.setSignInError("ERR_CONNECTION_REFUSED"));
        }
      })
      .then(function () {
        // always executed
      });
  },

  //Sign in Error
  setSignInError: (errorMessage) => {
    let signInError = "";
    if (errorMessage === "WRONG_CREDENTIAL") {
      signInError =
        "You entered wrong username/password, please try again OR Sign-Up";
    } else if (errorMessage === "Network Error") {
      signInError = "Internet connection failure, please try again later. ";
    } else {
      signInError = "Something went wrong, please try again.";
    }
    return {
      type: actions.SET_SIGN_IN_ERROR,
      payload: { signInError },
    };
  },

  //Get Driver
  getDriver: (jwt) => (dispatch) => {
    dispatch({
      type: actions.GET_DRIVER,
    });
    axios({
      method: "get",
      url: backendPath + "me",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data._id) {
            console.log(
              "%c Get Driver RES",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.setDriver(
                response.data,
                "Welcome " + response.data.name,
                jwt
              )
            );
            history.push("/home");
          }
        }
      })
      .catch(function (error) {
        console.log(
          "%c Get Driver ERR",
          "color: red; font-weight: bold",
          error
        );
      })
      .then(function () {
        // always executed
      });
  },

  //Set Driver
  setDriver: (driverObj, successMessage, jwt) => {
    console.log("New driver", driverObj);
    return {
      type: actions.SET_DRIVER,
      payload: driverObj,
      jwt: jwt,
      successMessage: successMessage,
    };
  },

  //#endregion

  //#region SIGN_UP
  signUp: (driverObj) => (dispatch) => {
    dispatch({
      type: actions.SIGN_UP,
    });
    axios({
      method: "post",
      url: backendPath + "register",
      data: {
        name: driverObj.name,
        email: driverObj.email,
        phone: driverObj.phone,
        address: driverObj.address,
        zipcode: driverObj.zipcode,
        city: driverObj.city,
        activeZipcodes: driverObj.activeZipcodes,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message ===
              "success, A verification token has been sent to the given phone number"
          ) {
            console.log(
              "%c Sign up response",
              "color: #76FF03; font-weight: bold; font-size: 15px",
              response
            );
            dispatch(
              actions.setSignUpSuccess(
                driverObj,
                "Sign Up Successful. Please verify your account!"
              )
            );
            history.push("/otp");
          } else {
            dispatch(actions.setSignUpError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log("%c error", "color: red; font-size: 15px", error);
        if (error.response) {
          dispatch(
            dispatch(actions.setSignUpError(error?.response?.data?.message))
          );
        } else if (!error.status) {
          dispatch(actions.setSignUpError("Network Error"));
        } else {
          dispatch(actions.setSignUpError("ERR_CONNECTION_REFUSED"));
        }
      })
      .then(function () {
        // always executed
      });
  },

  //Sign up Success
  setSignUpSuccess: (driverObj, signUpSuccess) => {
    return {
      type: actions.SET_SIGN_UP_SUCCESS,
      payload: {
        name: driverObj.name,
        email: driverObj.email,
        phone: driverObj.phone,
        address: driverObj.address,
        zipcode: driverObj.zipcode,
        city: driverObj.city,
        activeZipcodes: driverObj.activeZipcodes,
        signUpSuccess: signUpSuccess,
      },
    };
  },

  //Sign up Error
  setSignUpError: (errorMessage) => {
    console.log(errorMessage);
    let signUpError = "";
    if (errorMessage === "USERNAME_IS_NOT_AVAILABLE") {
      signUpError =
        "USERNAME is already in use, please enter another USERNAME.";
    } else if (errorMessage === "EMAIL_IS_NOT_AVAILABLE") {
      signUpError = "EMAIL is already in use, please enter another EMAIL.";
    } else if (errorMessage === "PHONE_IS_NOT_AVAILABLE") {
      signUpError =
        "PHONE NUMBER is already in use, please enter another PHONE NUMBER.";
    } else if (errorMessage === "USER_ALREADY_REGISTERED") {
      signUpError =
        "This USER is already registered but not verified, please verify from OTP sent to your phone number.";
    } else if (errorMessage === "USER_REGISTERED_AND_VERIFIED") {
      signUpError = "This USER is already registered, please try to sign in.";
    } else if (errorMessage === "USER_ALREADY_REGISTERED_AND_VERIFIED") {
      signUpError = "This USER is already registered, please try to sign in.";
    } else if (errorMessage === "Network Error") {
      signUpError = "Internet connection failure, please try again later. ";
    } else {
      signUpError = "Something went wrong, please try again.";
    }
    console.log(signUpError);
    console.log("errorMessage: ", errorMessage);
    return {
      type: actions.SET_SIGN_UP_ERROR,
      payload: { signUpError },
    };
  },

  //Sign up Complete
  signUpComplete: () => {
    return {
      type: actions.SIGN_UP_COMPLETE,
    };
  },

  //#endregion

  //#region OTP, SET_PASSWORD & FORGOT_PASSWORD
  //Verify OTP
  verifyOTP: (otp, phone, isSignIn) => (dispatch) => {
    axios({
      method: "post",
      url: backendPath + "verifyToken",
      data: {
        username: phone,
        token: otp,
      },
    })
      .then(function (response) {
        // handle response

        console.log(isSignIn);
        if (response.status === 200) {
          if (response.data && response.data.jwt) {
            console.log(
              " %c verify otp response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(actions.setJwt(response.data.jwt, otp));
            {
              isSignIn
                ? history.push("/reset_password")
                : history.push("/set_password");
            }
          } else {
            dispatch(actions.setOtpError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          dispatch(
            actions.setOtpError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setOtpError("Network Error"));
        } else {
          dispatch(actions.setOtpError("ERR_CONNECTION_REFUSED"));
        }
      })
      .then(function () {
        // always executed
      });
  },

  //Request OTP
  requestOTP: (phone, isForgotPassword) => (dispatch) => {
    axios({
      method: "post",
      url: backendPath + "requestToken",
      data: {
        username: phone,
      },
    })
      .then(function (response) {
        // handle response
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message ===
              "new token sent to registered phone number"
          ) {
            console.log(
              " %c request otp response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.resentOtpSuccess(
                "A new OTP has been sent to your PHONE NUMBER"
              )
            );
            if (isForgotPassword) {
              dispatch(actions.setForgotPasswordSuccess(phone));
              history.push("/otp");
            }
          } else {
            dispatch(actions.setOtpError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        // handle error

        dispatch(actions.setOtpError(error?.response?.data?.message));
        if (error.response) {
          dispatch(
            actions.setOtpError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setOtpError("Network Error"));
        }
        if (isForgotPassword) {
          dispatch(
            actions.setForgotPasswodError(
              error.response &&
                error.response.data &&
                error.response.data.message
            )
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  //OTP Error
  setOtpError: (errorMessage) => {
    let otpError = "";
    if (errorMessage === "LATE_VERIFICATION") {
      otpError = "Your OTP has expired, please request a new OTP to proceed.";
    } else if (errorMessage === "INVALID_TOKEN") {
      otpError = "You have entered wrong OTP, please try again.";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_NEW_TOKEN") {
      otpError = "Please wait 60 seconds before you request a new OTP.";
    } else if (errorMessage === "Network Error") {
      otpError = "Internet connection failure, please try again later. ";
    } else if (
      errorMessage === "PHONE_ALREADY_REGISTERED_ENTER_NEW_PHONE_NUMBER"
    ) {
      otpError = "Phone is already exist Enter New Phone Number. ";
    } else if (
      errorMessage ===
      "YOU_CAN_NOT_UPDATE_NOW_BEFORE_YOU_NEED_TO_CANCEL_ORDER_OR_COMPLETE_ORDER"
    ) {
      otpError =
        "You can not update now before you need to complete or cancel order";
    } else if (errorMessage === "USER_ALREADY_REGISTERED_AND_VERIFIED") {
      otpError = "User is already registered and verified. ";
    } else if (errorMessage === "PHONE_IS_NOT_AVAILABLE") {
      otpError = "Phone number is already registered";
    } else {
      otpError = "Something went wrong, please try again.";
    }
    console.log(otpError);
    console.log("errorMessage: ", errorMessage);
    return {
      type: actions.SET_OTP_ERROR,
      payload: { otpError },
    };
  },

  // Resent OTP Success
  resentOtpSuccess: (otpSuccess) => {
    return {
      type: actions.RESENT_OTP_SUCCESS,
      payload: { otpSuccess },
    };
  },

  //Set Remember me
  setRememberMe: (signInObj) => {
    return {
      type: actions.SET_REMEMBER_ME,
      payload: { signInObj },
    };
  },

  //Clear Remember Me
  clearRememberMe: () => {
    return {
      type: actions.CLEAR_REMEMBER_ME,
    };
  },

  //Phone OTP Error
  setPhoneOtpError: (errorMessage) => {
    let otpError = "";
    if (errorMessage === "LATE_VERIFICATION") {
      otpError = "Your OTP has expired, please request a new OTP to proceed.";
    } else if (errorMessage === "INVALID_TOKEN") {
      otpError = "You have entered wrong OTP, please try again.";
    } else if (
      errorMessage === "PHONE_ALREADY_REGISTERED_ENTER_NEW_PHONE_NUMBER"
    ) {
      otpError =
        "This Phone number is already registered, Enter new phone Number.";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_NEW_TOKEN") {
      otpError = "Please wait 60 seconds before you request a new OTP.";
    } else if (errorMessage === "Network Error") {
      otpError = "Internet connection failure, please try again later. ";
    } else {
      otpError = "Something went wrong, please try again.";
    }
    console.log(otpError);
    console.log("errorMessage: ", errorMessage);
    return {
      type: actions.SET_PHONE_OTP_ERROR,
      payload: { otpError },
    };
  },

  //Phone OTP Success
  resentPhoneOtpSuccess: (otpSuccess) => {
    return {
      type: actions.RESENT_PHONE_OTP_SUCCESS,
    };
  },

  //Forgot Password Success
  setForgotPasswordSuccess: (phone) => {
    return {
      type: actions.SET_FORGOT_PASSWORD_SUCCESS,
      payload: { phone },
    };
  },

  //Forgot Password Error
  setForgotPasswodError: (errorMessage) => {
    let forgotPasswordError;
    if (errorMessage === "WRONG_CREDENTIAL") {
      forgotPasswordError = "Please Enter Registered Phone Number";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_NEW_TOKEN") {
      forgotPasswordError =
        "Please wait 60 seconds before you request a new OTP.";
    } else if (errorMessage === "Network Error") {
      forgotPasswordError =
        "Internet connection failure, please try again later. ";
    } else {
      forgotPasswordError = "Something went wrong, please try again.";
    }
    return {
      type: actions.SET_FORGOT_PASSWORD_ERROR,
      payload: { forgotPasswordError },
    };
  },

  //Set Password
  setPassword:
    (oldPassword, newPassword, otp, jwt, isChangePassword) => (dispatch) => {
      axios({
        method: "post",
        url: backendPath + "changePassword ",
        headers: { Authorization: `Bearer ${jwt}` },
        data: {
          newPassword: newPassword,
          oldPassword: oldPassword,
          token: otp,
        },
      })
        .then(function (response) {
          // handle
          if (response.status === 200) {
            if (response.data && response.data.message === "password updated") {
              console.log(
                "%c response",
                "color: #76FF03; font-weight: bold",
                response
              );
              if (!isChangePassword) {
                dispatch(actions.signUpComplete());
                history.push("/sign_in");
              }
            } else {
              dispatch(actions.setOtpError("ERR_CONNECTION_REFUSED"));
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log("%c error", "color: red; font-weight: bold", error);
          if (error.response) {
            dispatch(actions.setOtpError(error?.response?.data?.message));
          } else if (!error.status) {
            dispatch(actions.setOtpError("Network Error"));
          } else {
            dispatch(actions.setOtpError("ERR_CONNECTION_REFUSED"));
          }
        })
        .then(function () {
          // always executed
        });
    },

  //Reset Password
  resetPassword: (newPassword, jwt, oldPassword) => (dispatch) => {
    axios({
      method: "post",
      url: backendPath + "changePassword",
      headers: { Authorization: `Bearer ${jwt}` },
      data: {
        newPassword: newPassword,
        oldPassword: oldPassword,
        token: "",
      },
    })
      .then(function (response) {
        // handle
        if (response.status === 200) {
          if (response.data && response.data.message === "password updated") {
            console.log(response.data);
            dispatch(actions.updatePassword());
            dispatch(actions.clearRememberMe());
            dispatch(actions.signOut());
            history.push("/sign_in");
          } else {
            dispatch(actions.setOtpError("ERR_CONNECTION_REFUSED"));
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response);
        if (error.response) {
          dispatch(
            actions.setOtpError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setOtpError("Network Error"));
        } else {
          dispatch(actions.setOtpError("ERR_CONNECTION_REFUSED"));
        }
      })
      .then(function () {
        // always executed
      });
  },

  //update Password Complete
  updatePassword: () => {
    return {
      type: actions.UPDATE_PASSWORD_COMPLETE,
    };
  },

  //#endregion

  //#region UPDATE_UNVERIFIED_PHONE, UPLOAD_DOCS, UPDATE_DRIVER
  updateUnverifiedPhone: (oldPhone, newPhone) => (dispatch) => {
    axios({
      method: "post",
      url: backendPath + "updatePhoneNewUser",
      data: {
        newPhone: newPhone,
        oldPhone: oldPhone,
      },
    })
      .then(function (response) {
        console.log(
          "%c Update phone response",
          "color: #76FF03; font-weight: bold",
          response
        );
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === "Phone number updated"
          ) {
            dispatch(
              actions.setUpdatePhoneNewDriverSuccess(
                "Phone Number Updated Successfully!",
                newPhone
              )
            );
          } else {
            dispatch(
              actions.setUpdatePhoneNewDriverError("ERR_CONNECTION_REFUSED")
            );
          }
        }
      })
      .catch(function (error) {
        console.log(
          "%c Update phone error RES",
          "color: red; font-weight: bold",
          error.response
        );
        if (error.response) {
          dispatch(
            actions.setUpdatePhoneNewDriverError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setUpdatePhoneNewDriverError("Network Error"));
        } else {
          dispatch(
            actions.setUpdatePhoneNewDriverError("ERR_CONNECTION_REFUSED")
          );
        }
      });
  },

  //Update New Phone Success
  setUpdatePhoneNewDriverSuccess: (updatePhoneNewDriverSuccess, phone) => {
    return {
      type: actions.SET_UPDATE_PHONE_NEW_DRIVER_SUCCESS,
      payload: { updatePhoneNewDriverSuccess, phone },
    };
  },

  //Update New Phone Error
  setUpdatePhoneNewDriverError: (errorMessage) => {
    let updatePhoneNewDriverError;
    if (
      errorMessage === "PHONE_IS_NOT_AVAILABLE" ||
      errorMessage === "WRONG_CREDENTIAL"
    ) {
      updatePhoneNewDriverError =
        "Phone Number is already in use, write another one.";
    } else if (errorMessage === "USER_ALREADY_REGISTERED_AND_VERIFIED") {
      updatePhoneNewDriverError =
        "User is already registered and verified, try signing in.";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_PHONE_UPDATE") {
      updatePhoneNewDriverError =
        "Please wait at least 60 seconds before updating phone number";
    } else if (errorMessage === "Network Error") {
      updatePhoneNewDriverError =
        "Internet connection failure, please try again later. ";
    } else {
      updatePhoneNewDriverError = "Something went wrong, please try again.";
    }

    return {
      type: actions.SET_UPDATE_PHONE_NEW_DRIVER_ERROR,
      payload: { updatePhoneNewDriverError },
    };
  },

  //Document Upload and Update
  accountVerificationDocs: (docObj, jwt, docs) => (dispatch) => {
    dispatch({
      type: actions.ADD_DOCS,
    });
    console.log("docObj", docObj);
    if (
      docObj.doc1.type &&
      docObj.doc2.type &&
      docObj.doc3.type &&
      docObj.doc4.type
    ) {
      let formData = new FormData();
      let doc1Details = docObj.doc1.type
        ? docObj.doc1.type.split("/")
        : docObj.doc1.type;
      let doc2Details = docObj.doc2.type
        ? docObj.doc2.type.split("/")
        : docObj.doc2.type;
      let doc3Details = docObj.doc3.type
        ? docObj.doc3.type.split("/")
        : docObj.doc3.type;
      let doc4Details = docObj.doc4.type
        ? docObj.doc4.type.split("/")
        : docObj.doc4.type;
      let doc1type = doc1Details[1];
      let doc2type = doc2Details[1];
      let doc3type = doc3Details[1];
      let doc4type = doc4Details[1];
      let drivingLicence = "drivingLicence.";
      let vehicleLicence = "vehicleLicence.";
      let vehicleInsurance = "vehicleInsurance.";
      let personalIDCard = "personalIDCard.";

      let res = drivingLicence.concat(doc1type);
      let res1 = vehicleLicence.concat(doc2type);
      let res2 = vehicleInsurance.concat(doc3type);
      let res3 = personalIDCard.concat(doc4type);

      try {
        formData.append("vehicleType", docObj.vehicleType);
        formData.append("vehicleNumber", docObj.vehicleNumber);
        formData.append("vehicleColor", docObj.vehicleColor);
        formData.append("filename1", docObj.doc1, res);
        formData.append("filename2", docObj.doc2, res1);
        formData.append("filename3", docObj.doc3, res2);
        formData.append("filename4", docObj.doc4, res3);
        for (var key of formData.entries()) {
          console.log(key[0] + ", " + key[1]);
          console.log(key);
        }

        console.log("form dataaaa", formData.get("filename1"));
      } catch (e) {
        console.error(e);
      }
      axios({
        method: "post",
        url: backendPath + "uploadFiles",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "multipart/form-data; boundary=asdaskldh389",
        },
        data: formData,
      })
        .then(function (response) {
          console.log("response", response);
          // handle success
          if (response.status === 200) {
            if (response.data && response.data.message === "Files uploaded") {
              console.log(
                "%c Update documents response",
                "color: #76FF03; font-weight: bold",
                response
              );
              console.log(response.data);
              console.log(
                "%c Update documents response",
                "color: #76FF03; font-weight: bold",
                response
              );
              dispatch(
                actions.setAddDocsSuccess(response.data.message, response.data)
              );
              dispatch(actions.getDriver(jwt));
              if (docs === "UploadDocs") {
                history.push("/Doc_Approval");
              }
              if (docs === "home") {
                history.push("/home");
              }
            } else {
              dispatch(
                actions.setAddDocsError(
                  "Something went wrong, please try again."
                )
              );
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(
            "%c Update document error RES",
            "color: red; font-weight: bold",
            error
          );
          if (error.response) {
            dispatch(actions.setAddDocsError("Error While Uploading"));
          } else if (!error.status) {
            dispatch(
              actions.setAddDocsError(
                "Internet connection failure, please try again later. "
              )
            );
          } else {
            dispatch(
              actions.setAddDocsError("Something went wrong, please try again.")
            );
          }
        });
    } else {
      dispatch(actions.setAddDocsError("Please Upload Required Documents."));
    }
  },

  //Add Docs Success
  setAddDocsSuccess: (addDocsSuccess, docs) => {
    console.log(addDocsSuccess);
    return {
      type: actions.SET_ADD_DOCS_SUCCESS,
      payload: { addDocsSuccess, docs },
    };
  },

  //Add Docs Error
  setAddDocsError: (addDocsError) => {
    console.log(addDocsError);
    return {
      type: actions.SET_ADD_DOCS_ERROR,
      payload: { addDocsError },
    };
  },

  //update Driver Status
  updateDriverStatus: (data, jwt) => (dispatch) => {
    console.log("updateDriverStatus", data);
    dispatch({
      type: actions.UPDATE_DRIVER_STATUS,
    });
    axios({
      method: "post",
      url: backendPath + "driverStatus",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: data,
    })
      .then(function (response) {
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === "Working Status Successfully updated"
          ) {
            console.log(
              "%c updateDriverStatus",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.setUpdateDriverStatusSuccess(
                response.data && response.data.message
                  ? response.data.message
                  : "Status Successfully Updated",
                data.allowedresponses
              )
            );
          } else {
            dispatch(
              actions.setUpdateDriverStatusError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        console.log(
          "%c updateDriverStatus",
          "color: red; font-weight: bold",
          error
        );
        if (error.response) {
          dispatch(
            actions.setUpdateDriverStatusError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setUpdateDriverStatusError("Network Error"));
        } else {
          dispatch(
            actions.setUpdateDriverStatusError(
              "Something went wrong, please try again."
            )
          );
        }
      });
  },

  //Update Driver Status Success
  setUpdateDriverStatusSuccess: (updateDriverStatusSuccess, workingStatus) => {
    return {
      type: actions.SET_UPDATE_DRIVER_STATUS_SUCCESS,
      payload: { updateDriverStatusSuccess, workingStatus },
    };
  },

  //Update Driver Status Error
  setUpdateDriverStatusError: (updateDriverStatusError) => {
    return {
      type: actions.SET_UPDATE_DRIVER_STATUS_ERROR,
      payload: { updateDriverStatusError },
    };
  },

  //Update Driver Phone Success
  setUpdatePhoneDriverSuccess: (updatePhoneDriverSuccess) => {
    return {
      type: actions.SET_UPDATE_PHONE_DRIVER_SUCCESS,
      payload: { updatePhoneDriverSuccess },
    };
  },

  //Update Driver Phone Error
  setUpdatePhoneDriverError: (errorMessage) => {
    let updatePhoneDriverError;
    if (
      errorMessage === "PHONE_IS_NOT_AVAILABLE" ||
      errorMessage === "WRONG_CREDENTIAL"
    ) {
      updatePhoneDriverError =
        "Phone Number is already in use, write another one.";
    } else if (errorMessage === "DRIVER_ALREADY_REGISTERED_AND_VERIFIED") {
      updatePhoneDriverError =
        "Driver is already registered and verified, try signing in.";
    } else if (
      errorMessage === "PHONE_ALREADY_REGISTERED_ENTER_NEW_PHONE_NUMBER"
    ) {
      updatePhoneDriverError =
        "This Phone number is already registered, Enter new phone Number.";
    } else if (errorMessage === "WAIT_60_SECONDS_FOR_PHONE_UPDATE") {
      updatePhoneDriverError =
        "Please wait at least 60 seconds before updating phone number";
    } else if (errorMessage === "Network Error") {
      updatePhoneDriverError =
        "Internet connection failure, please try again later. ";
    } else {
      updatePhoneDriverError = "Something went wrong, Please try again";
    }
    return {
      type: actions.SET_UPDATE_PHONE_DRIVER_ERROR,
      payload: { updatePhoneDriverError },
    };
  },

  //Update Driver
  updateDriver: (data, jwt) => (dispatch) => {
    console.log("data profile obj", data, jwt);
    axios({
      method: "post",
      url: backendPath + "updateDriver",
      headers: {
        Authorization: "Bearer " + jwt,
      },
      data: {
        name: data.name,
        email: data.email,
        state: " ",
        address: data.address,
        city: data.city,
        activeZipcodes: data.activeZipcodes,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === "success, Updated Information"
          ) {
            console.log(
              "%c Update updateDriver response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.setUpdateDriverSuccess(
                response.data && response.data.message
                  ? response.data.message
                  : "Driver Successfully Updated!",
                data.name,
                data.email,
                data.city,
                data.state,
                data.address,
                data.zipcode,
                data.activeZipcodes
              )
            );
          } else {
            dispatch(
              actions.setUpdateDriverError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        console.log(
          "%c Update setUpdateDriver error",
          "color: red; font-weight: bold",
          error.response
        );
        const errorMessage =
          'MongoServerError: E11000 duplicate key error collection: ManWithVan.drivers index: email_1 dup key: { email: "hamzarahman7@gmail.com" }';
        const pattern = /(E11000 duplicate key error collection)/;

        const match = errorMessage.match(pattern);
        if (match) {
          dispatch(actions.setUpdateDriverError("Email is not available!"));
        } else if (error.response) {
          dispatch(
            actions.setUpdateDriverError(
              error.response.data && error.response.data.message
                ? error.response.data.message
                : "Error While Updating Driver!"
            )
          );
        } else if (!error.status) {
          dispatch(actions.setUpdateDriverError("Network Error"));
        } else {
          dispatch(
            actions.setUpdateDriverError(
              "Something went wrong, please try again."
            )
          );
        }
      });
  },

  //Update Driver Success
  setUpdateDriverSuccess: (
    updateDriverSuccess,
    name,
    email,
    city,
    state,
    address,
    zipcode,
    activeZipcodes
  ) => {
    return {
      type: actions.SET_UPDATE_DRIVER_SUCCESS,
      payload: {
        updateDriverSuccess,
        name,
        email,
        city,
        state,
        address,
        zipcode,
        activeZipcodes,
      },
    };
  },

  //Update Driver Error
  setUpdateDriverError: (updateDriverError) => {
    return {
      type: actions.SET_UPDATE_DRIVER_ERROR,
      payload: { updateDriverError },
    };
  },

  //Update Active Zip Code
  updateActiveZipCode: (zipcode, jwt) => (dispatch) => {
    axios({
      method: "post",
      url: backendPath + "updateZipcode",
      headers: {
        Authorization: "Bearer " + jwt,
      },
      data: {
        zipcode: zipcode,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === "success, Updated Information"
          ) {
            console.log(
              "%c Update active Zipcode response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.setUpdateActiveSuccess(response.data.message, zipcode)
            );
          } else {
            dispatch(
              actions.setUpdateActiveError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        console.log(
          "%cUpdate active zip code error",
          "color: red; font-weight: bold",
          error.response
        );
        if (error.response) {
          dispatch(
            actions.setUpdateActiveError(
              error.response.data && error.response.data.message
                ? error.response.data.message
                : "Error While Updating Driver!"
            )
          );
        } else if (!error.status) {
          dispatch(actions.setUpdateActiveError("Network Error"));
        } else {
          dispatch(
            actions.setUpdateActiveError(
              "Something went wrong, please try again."
            )
          );
        }
      });
  },

  //Update Active Zipcode Success
  setUpdateActiveSuccess: (updateActiveSuccess, zipcode) => {
    console.log("zip codesssssssss", zipcode);
    return {
      type: actions.SET_UPDATE_ACTIVE_SUCCESS,
      payload: {
        updateActiveSuccess,
        zipcode,
      },
    };
  },

  //Update Driver Error
  setUpdateActiveError: (updateActiveError) => {
    return {
      type: actions.SET_UPDATE_ACTIVE_ERROR,
      payload: { updateActiveError },
    };
  },

  //Update Active Zipcode
  UpdateNotActive: (data) => {
    return {
      type: actions.SET_UPDATE_NOT_ACTIVE,
      payload: {
        data,
      },
    };
  },
  //#endregion

  //#region Current Password
  //Current Password Matching
  currentPass: (phone, password) => (dispatch) => {
    console.log("driverObj: ", phone, password);
    dispatch({
      type: actions.CURRENT_PASS,
    });
    axios({
      method: "post",
      url: backendPath + "login",
      data: {
        username: phone,
        password: password,
      },
    })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          if (response.data && response.data.jwt) {
            console.log(
              "%c Current Password matches ",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(
              actions.setCurrentPassSuccess("Password matched.", password)
            );
          } else {
            dispatch(
              actions.setCurrentPassError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(
          "%c Current Password Error",
          "color: red; font-weight: bold",
          error,
          error.response.data.message
        );
        if (error.response) {
          dispatch(actions.setCurrentPassError(error.response.data.message));
        } else if (!error.status) {
          dispatch(actions.setCurrentPassError("Network Error"));
        } else {
          dispatch(
            actions.setCurrentPassError(
              "Something went wrong, please try again."
            )
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  //current password success
  setCurrentPassSuccess: (currentPassSuccess, pass) => {
    return {
      type: actions.SET_CURRENT_PASS_SUCCESS,
      payload: {
        currentPassSuccess: currentPassSuccess,
        currentPassword: pass,
      },
    };
  },

  //current password error
  setCurrentPassError: (errorMessage) => {
    let currentPassError = "";
    if (errorMessage === "WRONG_CREDENTIAL") {
      currentPassError = "You entered wrong password, please try again";
    } else if (errorMessage === "Network Error") {
      currentPassError =
        "Internet connection failure, please try again later. ";
    } else {
      currentPassError = "Something went wrong, please try again.";
    }
    console.log("currentPassError", currentPassError);
    return {
      type: actions.SET_CURRENT_PASS_ERROR,
      payload: { currentPassError: currentPassError },
    };
  },
  //#endregion

  //Fresh Sign up
  freshSignUp: () => {
    return {
      type: actions.FRESH_SIGN_UP,
    };
  },

  //Sign out
  signOut: () => {
    return {
      type: actions.SIGN_OUT,
    };
  },

  //Clear Error
  clearError: () => {
    return {
      type: actions.CLEAR_ERROR,
    };
  },

  //Set JWT
  setJwt: (jwt, otp) => {
    return {
      type: actions.SET_JWT,
      payload: { jwt, otp },
    };
  },

  //Request OTP Login Driver
  requestOTPLoginDriver: (phone, jwt) => (dispatch) => {
    axios({
      method: "POST",
      url: backendPath + "requestTokenLoginUser",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        newPhone: phone,
      },
    })
      .then(function (response) {
        // handle response

        if (response.status === 200) {
          if (
            response.data &&
            response.data.message ===
              "new token sent to registered phone number"
          ) {
            console.log(
              " %c request otp response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch({
              type: actions.REQUEST_OTP_LOGIN,
              payload: {
                phone: phone,
              },
            });
            history.push("/phone_otp");
            dispatch(
              actions.resentPhoneOtpSuccess(
                "A new OTP has been sent to your PHONE NUMBER"
              )
            );
          } else {
            dispatch(
              actions.setUpdatePhoneDriverError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(
          "%c error",
          "color: red; font-weight: bold",
          error.response
        );
        if (error.response) {
          dispatch(
            actions.setUpdatePhoneDriverError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setUpdatePhoneDriverError("Network Error"));
        } else {
          dispatch(
            actions.setUpdatePhoneDriverError(
              "Something went wrong, please try again."
            )
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  //Update phone login driver
  updatePhoneLoginDriver: (otp, phone, jwt) => (dispatch) => {
    console.log("newPhoneeeeee", phone);
    axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      url: backendPath + "updatePhoneLoginUser",
      data: {
        newPhone: phone,
        token: otp,
      },
    })
      .then(function (response) {
        // handle response
        if (response.status === 200) {
          if (response.data && response.data.message === "Phone Updated.") {
            console.log(
              " %c response",
              "color: #76FF03; font-weight: bold",
              response
            );
            console.log(phone);
            dispatch(
              actions.setUpdatePhoneDriverSuccess(response.data.message)
            );
            console.log(
              " %c response",
              "color: #76FF03; font-weight: bold",
              response
            );
            dispatch(actions.clearRememberMe());
            dispatch(actions.signOut());
            history.push("/sign_in");
          } else {
            dispatch(
              actions.setOtpError("Something went wrong, please try again.")
            );
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          dispatch(
            actions.setOtpError(
              error.response.data && error.response.data.message
            )
          );
        } else if (!error.status) {
          dispatch(actions.setOtpError("Network Error"));
        } else {
          dispatch(
            actions.setOtpError("Something went wrong, please try again.")
          );
        }
      })
      .then(function () {
        // always executed
      });
  },

  //Cancel Order
  cancelOrder: (jwt, id, value) => (dispatch) => {
    console.log("order id", id, jwt);
    dispatch({
      type: actions.CANCEL_ORDER,
    });
    axios({
      method: "POST",
      url: backendPath + "driverCancelOrder",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        orderId: id,
        orderCancelRequest: "Late",
        orderCancelReasion: value,
      },
    })
      .then(function (response) {
        // handle response
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === "Request is being processed by Admin"
          ) {
            console.log(
              " %c request cancel Order response",
              "color: #76FF03; font-weight: bold",
              response
            );
            console.log("Ayesha request for order cancellation");
            history.push("/orders");
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(
          "%c Cancel order error",
          "color: red; font-weight: bold",
          error.response
        );
      })
      .then(function () {
        // always executed
      });
  },
  contactUs: (userObj, jwt) => (dispatch) => {
    console.log("userObj", userObj);
    axios({
      method: "post",
      url: backendPath + "contactUs ",
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      data: {
        firstName: userObj.firstName,
        lastName: userObj.lastName,
        email: userObj.email,
        phone: userObj.phone,
        message: userObj.message,
      },
    })
      .then(function (response) {
        console.log("response", response);
        // handle success
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === "Your message have send to admin"
          ) {
            console.log(
              "%c sign up success",
              "color: #76FF03; font-weight: bold",
              response.data
            );
            dispatch(
              actions.setContactUsSuccess(
                "Your message have been Send to Admin!"
              )
            );
          } else {
            dispatch(
              actions.setContactUsError(
                "Something went wrong, please try again."
              )
            );
          }
        }
      })
      .catch(function (error) {
        console.log("%c Sign In Error", "color: red; font-weight: bold", error);
        // handle error
        if (error.response) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error?.response?.data?.message
          );

          dispatch(
            actions.setContactUsError(
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something went wrong while Send message"
            )
          );
        } else if (!error.status) {
          console.log(
            "%c Sign In Error",
            "color: red; font-weight: bold",
            error
          );
          dispatch(actions.setContactUsError("Network Error"));
        } else {
          dispatch(
            actions.setContactUsError("Something went wrong, please try again.")
          );
        }
      })
      .then(function () {
        // always executed
      });
  },
  setContactUsSuccess: (contactUsSuccess) => {
    return {
      type: actions.SET_CONTACT_US_SUCCESS,
      payload: {
        contactUsSuccess: contactUsSuccess,
      },
    };
  },
  setContactUsError: (errorMessage) => {
    console.log(errorMessage);
    let contactUsError = "";
    if (errorMessage === "USER_DOES_NOT_EXIST") {
      contactUsError = "USER_DOES_NOT_EXIST";
    } else if (errorMessage === "Something went wrong, please try again.") {
      contactUsError = "Something went wrong, please try again.";
    } else if (errorMessage === "Network Error") {
      contactUsError = "Internet connection failure, please try again later.";
    } else {
      contactUsError = "Something went wrong while Send message";
    }

    console.log("errorMessage: ", contactUsError);
    return {
      type: actions.SET_CONTACT_US_ERROR,
      payload: { contactUsError: contactUsError },
    };
  },
};

export default actions;
