//Libraries
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Paper,
  CssBaseline,
  TextField,
  Typography,
  Button,
  Hidden,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'

//Components
import history from '../../Utils/history'
import Yup from '../../Yup/YupAuthValidation.schemas'
import actions from '../../Redux/Driver/Driver.actions'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.25rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.025rem'
    }
  },
  image: {
    [theme.breakpoints.only('lg')]: {
      width: '60%',
      height: '60%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '50%',
      height: '50%'
    }
  },
  root: {
    height: '100vh'
  },
  boxes: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '90%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.only('md')]: {
      width: '50%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '80%'
    }
  },
  item: {
    textAlign: 'center',
    alignItems: 'center'
  },
  toolbar: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    [theme.breakpoints.only('xs')]: {
      marginTop: '25%'
    },
    [theme.breakpoints.between(500, 719)]: {
      marginTop: '18%'
    },
    [theme.breakpoints.between(719, 899)]: {
      marginTop: '15%'
    },
    [theme.breakpoints.between(899, 1279)]: {
      marginTop: '12%'
    },
    [theme.breakpoints.between(1279, 1300)]: {
      marginTop: '10%'
    },
    [theme.breakpoints.between(1300, 1920)]: {
      marginTop: '8%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '9%'
    }
  },
  content: {
    flexGrow: 1
  },
  textFields: {
    width: '20vw'
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
    fontWeight: 'bold'
  },
  MuiCardContent: {
    width: '100%',
    display: 'center',
    textAlign: 'center'
  },
  box: {
    [theme.breakpoints.up('lg')]: {
      marginTop: '25%'
    }
  },
  text: {
    float: 'left'
  },
  textfield: {
    marginTop: '2%'
  }
}))

export default function CreatePassword (props) {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xs'))
  const dispatch = useDispatch()
  const isChangePassword = false

  const [initialValues, setInitialValues] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false
  })

  const handleClickShowPassword = () => {
    setInitialValues({
      ...initialValues,
      showPassword: !initialValues.showPassword
    })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const jwt = useSelector(state => state.driver.jwt)
  const otp = useSelector(state => state.driver.otp)
  const isLoggedIn = useSelector(state => state.driver.isLoggedIn)

  window.history.pushState(null, null, window.location.href)
  window.onpopstate = function (event) {
    history.go(1)
  }

  return (
    <div>
      <Grid container component='main' className={classes.root}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          component={Paper}
          elevation={0}
          square
        >
          <main className={classes.content}>
            <div className={classes.toolbar}>
              <Box className={classes.boxes}>
                <Typography className={classes.title}>
                  Create New Password
                </Typography>
                <Typography className={classes.textfield}>
                  Your new Password must be different from the previous password
                  you've used
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.ConfirmPasswordSchema}
                  onSubmit={values => {
                    console.log(values)
                    dispatch(
                      actions.setPassword(
                        '',
                        values.password,
                        otp,
                        jwt,
                        isChangePassword,
                        props
                      )
                    )
                  }}
                >
                  {({ errors, handleChange, touched }) => (
                    <Form className={classes.form}>
                      <Box textAlign='center' mt={5}>
                        <Typography className={classes.text}>
                          New Password
                        </Typography>
                        <TextField
                          error={errors.password && touched.password}
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : null
                          }
                          variant='outlined'
                          margin='normal'
                          fullWidth
                          name='password'
                          placeholder='Password'
                          id='password'
                          className={classes.fields}
                          type={
                            initialValues.showPassword ? 'text' : 'password'
                          }
                          autoComplete='password'
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 20
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'
                                >
                                  {initialValues.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box textAlign='center' mt={4}>
                        <Typography className={classes.text}>
                          Confirm Password
                        </Typography>
                        <TextField
                          error={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          helperText={
                            errors.confirmPassword && touched.confirmPassword
                              ? errors.confirmPassword
                              : null
                          }
                          variant='outlined'
                          margin='normal'
                          fullWidth
                          name='confirmPassword'
                          className={classes.fields}
                          placeholder='Current Password'
                          // type='password'
                          id='confirmPassword'
                          type={
                            initialValues.showPassword ? 'text' : 'password'
                          }
                          autoComplete='confirm-password'
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 20
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'
                                >
                                  {initialValues.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box mt={3}>
                        <Button
                          type='submit'
                          fullWidth
                          color='primary'
                          size='large'
                          variant='contained'
                          className={classes.submit}
                        >
                          {isLoggedIn ? 'Reset Password' : 'Create Password'}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </div>
          </main>
        </Grid>
        <Grid
          item
          xs={false}
          sm={false}
          md={false}
          lg={6}
          className={classes.item}
          component={Paper}
          elevation={0}
          square
        >
          <Box className={classes.box}>
            <Hidden mdDown>
              <img
                src='/assets/images/createPass.png'
                alt='password'
                className={classes.image}
              />
            </Hidden>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
