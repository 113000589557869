//Libraries
import { combineReducers } from 'redux'

//Components
import driverReducer from './Driver/Driver.reducer'
import orderReducer from './Order/Order.reducer'
import { themeReducer } from './Theme/themeReducer'

const rootReducer = combineReducers({
  driver: driverReducer,
  order: orderReducer,
  themes: themeReducer
})

export default rootReducer
