//Libraries
import React, { useState, useRef, useEffect } from 'react'
import { Formik, Form } from 'formik'
import {
  Box,
  RadioGroup,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  withStyles,
  Typography,
  Card,
  CardContent,
  Button,
  useTheme,
  useMediaQuery,
  TextField,
  Dialog,
  makeStyles,
  Slide
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

//Components
import actions from '../../Redux/Order/Order.actions'
import Yup from '../../Yup/YupOrderValidation.schemas'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    marginTop: '2%',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22.5
    }
  },
  margin: {
    marginRight: '2%'
  },
  helperText: {
    marginLeft: 0,
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px'
    }
  },
  dialog: {
    borderRadius: 8
  },
  centered: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    height: '40%'
  },
  root: {
    width: '100%'
  },
  div: {
    color: '#f44336',
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  },
  box: {
    float: 'right',
    marginRight: '1%'
  }
}))

const GreenRadio = withStyles({
  root: {
    '&$checked': {
      color: '#6251FE'
    }
  },
  checked: {}
})(props => <Radio {...props} />)

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function CompleteOrderIssue ({
  open,
  orderID,
  props,
  handleClose
}) {
  const classes = useStyles()
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  const dispatch = useDispatch()
  const dialogRef = useRef()
  const jwt = useSelector(state => state.driver.jwt)
  const [fullWidth] = React.useState(true)

  useEffect(() => {
    console.log('Dialog Ref', dialogRef.current)
  }, [])

  const [initialValues, setInitialValues] = useState({
    value: '',
    desc: ''
  })

  const options = [
    {
      id: 1,
      title: 'Lost luggage'
    },
    {
      id: 2,
      title: 'Dispute'
    },
    {
      id: 3,
      title: 'Lost code'
    },
    {
      id: 4,
      title: 'Accident'
    },
    {
      id: 5,
      title: 'Other'
    }
  ]

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth='xs'
      open={open}
      onClose={handleClose}
      className={classes.dialog}
      TransitionComponent={Transition}
      keepMounted
      hideBackdrop={true}
      aria-labelledby='alert-dialog-title1'
      aria-describedby='alert-dialog-description1'
    >
      <Card key={orderID} className={classes.centered} elevation={2}>
        <Box className={classes.root} p={lgMatch ? 4 : 2}>
          <CardContent>
            <Typography className={classes.title}>
              <b>Report Problem ?</b>
            </Typography>
          </CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.orderProblemValidationSchema}
            enableReinitialize={true}
            onSubmit={values => {
              dispatch(
                actions.completeOrderIssue(
                  jwt,
                  orderID,
                  values.value,
                  values.desc,
                  props
                )
              )
              handleClose()
            }}
          >
            {({ errors, handleChange, touched, values }) => (
              <Form className={classes.form}>
                <FormControl component='fieldset'>
                  <RadioGroup
                    id='value'
                    name='value'
                    // value={values.value}
                    aria-label='cancel'
                    onChange={handleChange}
                  >
                    {options.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option.title}
                        control={<GreenRadio color='primary' />}
                        label={
                          <Typography color='secondary'>
                            {option.title}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {errors.value && touched.value ? (
                    <div className={classes.div}>{errors.value}</div>
                  ) : null}
                </FormControl>
                <TextField
                  className={classes.margin}
                  error={errors.desc && touched.desc}
                  helperText={errors.desc && touched.desc ? errors.desc : null}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  id='desc'
                  name='desc'
                  placeholder='Description'
                  fullWidth
                  multiline={true}
                  rows={2}
                  margin='normal'
                  variant='outlined'
                  onChange={handleChange}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      size='large'
                      style={{ backgroundColor: '#F5F6FE' }}
                      fullWidth
                      className={classes.margin}
                      variant='contained'
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type='submit'
                      fullWidth
                      color='primary'
                      size='large'
                      variant='contained'
                      className={classes.margin}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
    </Dialog>
  )
}
