//Libraries
import React, { useState } from 'react'
import {
  makeStyles,
  withStyles,
  CssBaseline,
  Typography,
  Box,
  Grid,
  Divider
} from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

//Icons
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

//Style
const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: '4%',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.95rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '2.125rem'
    }
  },
  content: {
    flexGrow: 1
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  submit: {
    margin: theme.spacing(2, 0, 4),
    fontWeight: 'bold'
  },
  MuiCardContent: {
    width: '100%',
    display: 'center',
    textAlign: 'center'
  },
  root: {
    display: 'flex'
  },
  image: {
    // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  heading: {
    color: 'primary',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.1rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  subheading: {
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.1rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  boxes: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.only('md')]: {
      width: '95%',
      padding: '1%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '60%',
      padding: '1%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '60%',
      padding: '1%'
    }
  },
  divider: {
    marginTop: '1%',
    marginBottom: '1%'
  },
  accordion: {
    border: 'none',
    boxShadow: 'none'
  }
}))

//Accordion
const Accordion = withStyles({
  root: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

//Accordion Summary
const AccordionSummary = withStyles({
  root: {
    padding: 0
  }
})(MuiAccordionSummary)

//Accordion Details
const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
    textAlign: 'left'
  }
}))(MuiAccordionDetails)

export default function Terms () {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  //QnA
  const options = [
    {
      id: 'panel1',
      question: 'How do i provide rating to customer ?',
      answer:
        'Use the app and at the end of order and after payment give a rating to the customer.'
    },
    {
      id: 'panel2',
      question: 'How to update my profile ?',
      answer:
        'The User Profile functionality allows you to make changes for all general info other than authentication info.'
    },
    {
      id: 'panel3',
      question: 'How to delete my account ?',
      answer:
        'Go to Settings  then in the account settings section click the Delete Account Button and after confirmation your account will be deleted.'
    },
    {
      id: 'panel4',
      question: 'How do i set forward complaint ?',
      answer:
        'In the Help section you can register your complaint using the app or you can contact the company via call, message or chat.'
    },
    {
      id: 'panel5',
      question: 'What can get my account blocked ?',
      answer:
        'Contact the admin and explain your situation. After you have paid your fines your account will be unblocked.'
    },
    {
      id: 'panel6',
      question: 'What is the penalty for order cancelation ?',
      answer:
        'The Penalty for order cancelation is account blocking, temporary order block and a fine for the next order.'
    }
  ]

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Grid container>
            <Grid item lg={12} align='center'>
              <Box className={classes.boxes}>
                <Typography className={classes.title} align='center'>
                  Terms and Conditions
                </Typography>
                {options.map(option => (
                  <React.Fragment key={option.id}>
                    <Accordion
                      className={classes.accordion}
                      expanded={expanded === option.id}
                      onChange={handleChange(option.id)}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === option.id ? <RemoveIcon /> : <AddIcon />
                        }
                      >
                        <Typography className={classes.heading}>
                          {option.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.subheading}>
                          {option.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Divider className={classes.divider} />{' '}
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  )
}
