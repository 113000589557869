//libraries
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  Typography,
  Button,
  ListItem,
  Divider,
  Box,
  Grid,
  Card,
  CssBaseline,
  Avatar,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

//components
import actions from '../../Redux/Order/Order.actions'
import history from '../../Utils/history'

//Styles
const useStyles = makeStyles(theme => ({
  rateValue: {
    marginTop: 2,
    fontSize: 15
  },
  card: {
    height: 'auto',
    borderRadius: 8,
    padding: '20px',
    marginRight: '3%',
    // width: 250,
    // margin: 'auto',
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    marginTop: '3%'
  },
  pos: {
    marginBottom: 12
  },
  purple: {
    backgroundColor: '#6251FE'
  },
  large: {
    width: '48px',
    height: '48px'
  }
}))

export const CustomerOfferCard = React.memo(function CustomerCard ({
  customerId,
  customerImage,
  customerTitle,
  customerDesc,
  customerDriverMatch,
  customerAmount,
  customerRating,
  customerSchedule,
  day,
  customerPickup,
  customerDropoff
}) {
  const classes = useStyles()
  const theme = useTheme()
  const lgMatch = useMediaQuery(theme.breakpoints.up('lg'))
  var now = moment()

  var startTime = moment(now).format('DD/M/YYYY HH:mm A')
  const [cTime, setTime] = useState(startTime)

  var duration = moment
    .utc(customerDriverMatch, 'DD/M/YYYY HH:mm A')
    .diff(moment.utc(cTime, 'DD/M/YYYY HH:mm A'), 'minutes')
  const [mins, setMins] = useState(duration)
  useEffect(() => {
    var duration2 = moment
      .utc(customerDriverMatch, 'DD/M/YYYY HH:mm A')
      .diff(moment.utc(cTime, 'DD/M/YYYY HH:mm A'), 'minutes')
    setMins(duration2)
    return () => {}
  }, [cTime])

  useEffect(() => {
    setInterval(() => {
      var now1 = moment()
      var startTime1 = moment(now1).format('DD/M/YYYY HH:mm A')
      setTime(startTime1)
    }, 10000)
    return () => {}
  })

  const jwt = useSelector(state => state.driver.jwt)
  const dispatch = useDispatch()

  const OrderAcceptHandler = () => {
    dispatch(actions.orderConfirmation(customerId, 'Accepted', jwt))
  }

  const OrderRejectHandler = () => {
    dispatch(actions.orderConfirmation(customerId, 'Rejected', jwt))
  }
  let myStr = customerTitle ? customerTitle.toUpperCase() : 'User'
  let first = myStr.match(/\b(\w)/g)
  const firstChar = first.slice(0, 3)

  return (
    <div>
      <CssBaseline />
      <Card key={customerId} className={classes.card}>
        <div onClick={() => history.push(`/order_details/${customerId}`)}>
          <ListItem
            style={{
              paddingRight: 0,
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <Avatar className={clsx(classes.large, classes.purple)}>
              {firstChar}
            </Avatar>
            <Box mt={3} ml={1} />
            <Typography
              style={{ fontWeight: 'bold', fontSize: lgMatch ? 24 : 18 }}
            >
              {customerTitle}
            </Typography>
          </ListItem>
          <Grid container direction='row' style={{ marginTop: '2%' }}></Grid>
          <Grid container direction='row'>
            <Typography
              variant='subtitle1'
              color='secondary'
              style={{ fontWeight: 'bold', fontSize: lgMatch ? 20 : 16 }}
            >
              {day}, {customerSchedule}
            </Typography>
          </Grid>
          <Grid container direction='row'>
            <Typography
              style={{ fontWeight: 'bold', fontSize: lgMatch ? 20 : 16 }}
              color='primary'
            >
              <b>£{customerAmount}</b>
            </Typography>
          </Grid>
          <Grid container direction='row'>
            <Typography style={{ fontSize: lgMatch ? 20 : 16 }}>
              <b>Time Limit: </b>
              {mins} minutes remaining
            </Typography>
          </Grid>
          <Grid container direction='row' alignItems='center'>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <img src='/assets/images/pick.png' alt='pick' />
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
              <Grid
                container
                direction='column'
                justifyContent='space-between'
                alignItems='flex-start'
              >
                <Typography
                  variant='body1'
                  style={{ fontSize: lgMatch ? 18 : 14 }}
                >
                  {customerPickup}
                </Typography>
                <Box mt={3} />
                <Divider style={{ width: '100%' }} />
                <Box m={1} />
                <Typography
                  variant='body1'
                  style={{ fontSize: lgMatch ? 18 : 14 }}
                >
                  {customerDropoff}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Box mt={3} />
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  style={{ backgroundColor: '#F5F6FE' }}
                  size='large'
                  fullWidth
                  variant='contained'
                  onClick={OrderRejectHandler}
                >
                  Decline
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color='primary'
                  size='large'
                  fullWidth
                  variant='contained'
                  onClick={OrderAcceptHandler}
                >
                  Accept
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
})

export default CustomerOfferCard
