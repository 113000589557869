//Libraries
import React from 'react'
import {
  makeStyles,
  Typography,
  CssBaseline,
  TextField,
  Button,
  Box
} from '@material-ui/core'

//Components
import NavigationDrawer from '../Components/NavigationDrawer/NavigationDrawer'

//Style
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0, 0)
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4, 0, 0)
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  image: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(12),
      width: '50%',
      height: '50%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(15),
      width: '50%',
      height: '50%'
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(18),
      width: '50%',
      height: '50%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(12),
      width: '40%',
      height: '40%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(12)
    }
  },
  heading: {
    fontWeight: 'bold',
    paddingTop: '2%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: 28
    }
  },
  box: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      padding: '1%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '70%',
      padding: '3%'
    },
    [theme.breakpoints.only('md')]: {
      width: '65%',
      padding: '1%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '40%',
      padding: '1%'
    },
    [theme.breakpoints.only('xl')]: {
      width: '35%',
      padding: '3%'
    }
  },
  text: {
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16
    }
  }
}))

export default function Invite () {
  const classes = useStyles()

  return (
    <div>
      <NavigationDrawer />
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Box className={classes.box}>
            <img
              src='/assets/images/invite.png'
              alt='invite'
              className={classes.image}
            />
            <Typography className={classes.heading}>
              Invite your Friends and Family
            </Typography>
            <Box textAlign='center' mt={3}>
              <Typography color='secondary' className={classes.text}>
                Email Address
              </Typography>
              <TextField
                id='filled-full-width'
                placeholder='Enter Email Address'
                fullWidth
                margin='normal'
                variant='outlined'
              />
            </Box>
            <Button
              fullWidth
              color='primary'
              size='large'
              variant='contained'
              className={classes.submit}
            >
              Confirm
            </Button>
          </Box>
        </div>
      </main>
    </div>
  )
}
