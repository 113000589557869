//Components
import driverActions from './Driver.actions'

// Driver State/Store
const initialState = {
  _id: null,
  name: null,
  email: null,
  phone: null,
  newPhone: null,
  city: null,
  adddress: null,
  zipcode: null,
  activeZipcodes: [],
  status: null,
  jwt: null,
  otp: null,
  currentPassword: null,
  isVisible: false,
  yes: null,
  no: null,
  isLoggedIn: false,
  isFetching: false,
  isJWTRecieved: false,
  isOTPRecieved: false,
  isOTPResent: false,
  isSignUpStarted: false,
  isFreshSignUp: false,
  activityStatus: null,
  workingStatus: null,
  updateP: null,
  verifications: {
    phoneVerified: false,
    emailVerified: false,
    driverLicenseVerified: false
  },
  vehicleFiles: {
    vehicleType: null,
    vehicleColor: null,
    vehicleNumber: null
  },
  errors: {
    signInError: null,
    signUpError: null,
    otpError: null,
    updateDriverError: null,
    otpPhoneError: null,
    forgotPasswordError: null,
    updatePhoneNewDriverError: null,
    addDocsError: null,
    workingStatusError: null,
    currentPassError: null,
    updateActiveError: null,
    contactUsError: null
  },
  updatePhoneDriverSuccess: null,
  success: {
    signInSuccess: null,
    otpResentSuccess: null,
    signUpSuccess: null,
    otpSuccess: null,
    forgotPasswordSucces: null,
    updateDriverSuccess: null,
    updatePhoneNewDriverSuccess: null,
    addDocsSuccess: null,
    workingStatusSuccess: null,
    currentPassSuccess: null,
    updateActiveSuccess: null,
    contactUsSuccess: null
  },
  Documents: {
    drivingLicence: null,
    vehicleLicence: null,
    vehicleInsurance: null,
    personalIDCard: null
  },
  rememberMe: {
    isRememberMe: false,
    password: null,
    emailPhone: null
  }
}

// Driver Reducer
const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    //#region SIGN_IN & GET_DRIVER
    case driverActions.SET_DRIVER:
      return {
        ...state,
        _id: action.payload._id,
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
        city: action.payload.city,
        address: action.payload.address,
        zipcode: action.payload.zipcode,
        activeZipcodes: action.payload.activeZipcodes,
        activityStatus: action.payload.activityStatus,
        workingStatus: action.payload.workingStatus,
        status: action.payload.status,
        isLoggedIn: true,
        isFetching: false,
        isVisible: false,
        isJWTRecieved: true,
        jwt: action.jwt,
        verifications: {
          phoneVerified: action.payload.verifications.phoneVerified,
          emailVerified: action.payload.verifications.emailVerified,
          driverLicenseVerified:
            action.payload.verifications.driverLicenseVerified
        },
        vehicleFiles: {
          vehicleType: action?.payload?.vehicleDetails?.vehicleType
            ? action?.payload?.vehicleDetails?.vehicleType
            : null,
          vehicleColor: action?.payload?.vehicleDetails?.vehicleColor
            ? action?.payload?.vehicleDetails?.vehicleColor
            : null,
          vehicleNumber: action?.payload?.vehicleDetails?.vehicleNumber
            ? action?.payload?.vehicleDetails?.vehicleNumber
            : null
        },
        Documents: {
          drivingLicence: action?.payload?.driverFiles?.drivingLicence
            ? action?.payload?.driverFiles?.drivingLicence
            : null,
          vehicleLicence: action?.payload?.driverFiles?.vehicleLicence
            ? action?.payload?.driverFiles?.vehicleLicence
            : null,
          vehicleInsurance: action?.payload?.driverFiles?.vehicleInsurance
            ? action?.payload?.driverFiles?.vehicleInsurance
            : null,
          personalIDCard: action?.payload?.driverFiles?.personalIDCard
            ? action?.payload?.driverFiles?.personalIDCard
            : null
        },
        errors: { ...state.errors, signInError: null },
        updatePhoneDriverSuccess: null,
        success: {
          ...state.success,
          signInSuccess: action.successMessage
        }
      }

    case driverActions.SIGN_IN:
      return {
        ...state,
        isFetching: true,
        updatePhoneDriverSuccess: null,
        errors: { ...state.errors, signInError: null },
        success: { ...state.success, signInSuccess: null }
      }

    case driverActions.SET_SIGN_IN_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, signInError: action.payload.signInError },
        success: { ...state.success, signInSuccess: null }
      }
    //#endregion

    //#region SIGN_UP
    case driverActions.FRESH_SIGN_UP:
      return initialState

    case driverActions.SIGN_UP:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors, signUpError: null },
        success: { ...state.success, signUpSuccess: null }
      }

    case driverActions.SET_SIGN_UP_SUCCESS:
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
        city: action.payload.city,
        adddress: action.payload.adddress,
        zipcode: action.payload.zipcode,
        activeZipcodes: action.payload.activeZipcodes,
        isFetching: false,
        isOTPRecieved: true,
        errors: { ...state.errors, signUpError: null },
        success: {
          ...state.success,
          signUpSuccess: action.payload.signUpSuccess
        }
      }

    case driverActions.SET_SIGN_UP_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, signUpError: action.payload.signUpError },
        success: { ...state.success, signUpSuccess: null }
      }

    case driverActions.SIGN_UP_COMPLETE:
      return initialState
    //#endregion

    //#region remember me
    case driverActions.SET_REMEMBER_ME:
      console.log('password', action.payload)
      return {
        ...state,
        rememberMe: {
          isRememberMe: action.payload.signInObj.checked,
          emailPhone: action.payload.signInObj.username,
          password: action.payload.signInObj.password
        }
      }

    case driverActions.CLEAR_REMEMBER_ME:
      return {
        ...state,
        rememberMe: initialState.rememberMe
      }
    //#endregion

    //#region OTP and jwt
    case driverActions.RESENT_PHONE_OTP_SUCCESS:
      return {
        ...state,
        isOTPResent: true,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case driverActions.VERIFY_OTP:
      return {
        ...state,
        otp: action.payload.otp,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case driverActions.RESENT_OTP_SUCCESS:
      return {
        ...state,
        isOTPResent: true,
        errors: { ...state.errors, otpError: null },
        success: {
          ...state.success,
          otpResentSuccess: action.payload.otpSuccess
        }
      }

    case driverActions.REQUEST_OTP_LOGIN:
      return {
        ...state,
        newPhone: action.payload.phone
      }

    case driverActions.SET_JWT:
      return {
        ...state,
        jwt: action.payload.jwt,
        otp: action.payload.otp,
        isJWTRecieved: true,
        errors: { ...state.errors, otpError: null },
        success: { ...state.success, otpSuccess: null }
      }

    case driverActions.SET_OTP_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, otpError: action.payload.otpError },
        success: { ...state.success, otpSuccess: null }
      }

    case driverActions.SET_PHONE_OTP_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, otpPhoneError: action.payload.otpError },
        success: { ...state.success, otpSuccess: null }
      }
    //#endregion

    //#region update phone
    case driverActions.UPDATE_PHONE_NEW_DRIVER:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case driverActions.SET_UPDATE_PHONE_DRIVER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, updatePhoneUserError: null },
        updatePhoneDriverSuccess: action.payload.updatePhoneDriverSuccess,
        success: {
          ...state.success
        }
      }

    case driverActions.SET_UPDATE_PHONE_NEW_DRIVER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        phone: action.payload.phone,
        errors: { ...state.errors, updatePhoneNewDriverError: null },
        success: {
          ...state.success,
          updatePhoneNewDriverSuccess:
            action.payload.updatePhoneNewDriverSuccess
        }
      }

    case driverActions.SET_UPDATE_PHONE_NEW_DRIVER_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          updatePhoneNewDriverError: action.payload.updatePhoneNewDriverError
        },
        success: { ...state.success, updatePhoneNewDriverSuccess: null }
      }

    case driverActions.SET_UPDATE_PHONE_DRIVER_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          updatePhoneDriverError: action.payload.updatePhoneDriverError
        },
        success: { ...state.success, updatePhoneDriverSuccess: null }
      }
    //#endregion

    //#region forgot and update password
    case driverActions.SET_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          forgotPasswordError: action.payload.forgotPasswordError
        },
        success: { ...state.success }
      }

    case driverActions.SET_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        phone: action.payload.phone,
        isOTPRecieved: true,
        errors: { ...state.errors, forgotPasswordError: null },
        success: { ...state.success }
      }

    case driverActions.UPDATE_PASSWORD_COMPLETE:
      return {
        ...state,
        currentPassword: null,
        isVisible: false
      }
    //#endregion

    //#region Documents
    case driverActions.ADD_DOCS:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors, addDocsError: null },
        success: { ...state.success, addDocsSuccess: null }
      }

    case driverActions.SET_ADD_DOCS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        vehicleFiles: {
          vehicleType: action.payload.docs.vehicleDetails.vehicleType,
          vehicleColor: action.payload.docs.vehicleDetails.vehicleColor,
          vehicleNumber: action.payload.docs.vehicleDetails.vehicleNumber
        },
        Documents: {
          drivingLicence: action.payload.docs.driverFiles.drivingLicence,
          vehicleLicence: action.payload.docs.driverFiles.vehicleLicence,
          vehicleInsurance: action.payload.docs.driverFiles.vehicleInsurance,
          personalIDCard: action.payload.docs.driverFiles.personalIDCard
        },
        // vehicleType: action.payload.vehicleType,
        // vehicleNumber: action.payload.vehicleNumber,
        // vehicleColor: action.payload.vehicleColor,
        errors: { ...state.errors, addDocsError: null },
        success: {
          ...state.success,
          addDocsSuccess: action.payload.addDocsSuccess
        }
      }

    case driverActions.SET_ADD_DOCS_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: { ...state.errors, addDocsError: action.payload.addDocsError },
        success: { ...state.success, addDocsSuccess: null }
      }
    //#endregion

    //#region Update driver
    case driverActions.UPDATE_DRIVER_STATUS:
      return {
        ...state,
        errors: { ...state.errors, workingStatusError: null },
        success: { ...state.success, workingStatusSuccess: null }
      }

    case driverActions.SET_UPDATE_DRIVER_STATUS_SUCCESS:
      return {
        ...state,
        errors: { ...state.errors, workingStatusError: null },
        success: {
          ...state.success,
          workingStatusSuccess: action.payload.updateDriverStatusSuccess
        },
        activityStatus:
          action.payload.workingStatus === 'Enable'
            ? 'Looking for work'
            : 'Disable'
      }

    case driverActions.SET_UPDATE_DRIVER_STATUS_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          workingStatusError: action.payload.updateDriverStatusError
        },
        success: { ...state.success, workingStatusSuccess: null }
      }

    case driverActions.UPDATE_DRIVER:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors },
        success: { ...state.success }
      }

    case driverActions.SET_UPDATE_DRIVER_SUCCESS:
      console.log('UPDATE PROFILE', action.payload)
      return {
        ...state,
        isFetching: false,
        name: action.payload.name,
        email:action.payload.email,
        city: action.payload.city,
        state: action.payload.state,
        address: action.payload.address,
        activeZipcodes: action.payload.activeZipcodes,
        zipcode: action.payload.zipcode,
        errors: { ...state.errors, updateDriverError: null },
        success: {
          ...state.success,
          updateDriverSuccess: action.payload.updateDriverSuccess
        }
      }

    case driverActions.SET_UPDATE_DRIVER_ERROR:
      return {
        ...state,
        isFetching: false,
        errors: {
          ...state.errors,
          updateDriverError: action.payload.updateDriverError
        },
        success: { ...state.success, updateDriverSuccess: null }
      }
    //#endregion

    //#region Update active zipcode
    case driverActions.SET_UPDATE_ACTIVE_SUCCESS:
      console.log('UPDATE Active status', action.payload)
      return {
        ...state,
        activeZipcodes: state.activeZipcodes.concat(action.payload.zipcode),
        yes: 'Yes',
        errors: { ...state.errors, updateActiveError: null },
        success: {
          ...state.success,
          updateActiveSuccess: action.payload.updateActiveSuccess
        }
      }

    case driverActions.SET_UPDATE_NOT_ACTIVE:
      return {
        ...state,
        no: action.payload.data
      }

    case driverActions.SET_UPDATE_ACTIVE_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          updateActiveError: action.payload.updateActiveError
        },
        success: { ...state.success, updateActiveSuccess: null }
      }
    //#endregion

    //#region current pass
    case driverActions.CURRENT_PASS:
      return {
        ...state,
        isFetching: true,
        errors: { ...state.errors, currentPassError: null },
        success: { ...state.success, currentPassSuccess: null }
      }

    case driverActions.SET_CURRENT_PASS_ERROR:
      
      return {
        ...state,
        isFetching: false,
        isVisible: false,
        errors: {
          ...state.errors,
          currentPassError: action.payload.currentPassError
        },
        success: { ...state.success, currentPassSuccess: null }
      }

    case driverActions.SET_CURRENT_PASS_SUCCESS:
      return {
        ...state,
        isVisible: true,
        isFetching: false,
        errors: { ...state.errors, signUpError: null },
        currentPassword: action.payload.currentPassword,
        success: {
          ...state.success,
          currentPassSuccess: action.payload.currentPassSuccess
        }
      }
    //#endregion

    //cancel order
    case driverActions.CANCEL_ORDER:
      return {
        ...state,
        isVisible: false,
        errors: { ...state.errors },
        success: {
          ...state.success
        }
      }

    //clear error
    case driverActions.CLEAR_ERROR:
      return {
        ...state,
        currentPassword: null,
        errors: {
          ...state.errors,
          signInError: null,
          signUpError: null,
          otpError: null,
          updateDriverError: null,
          otpPhoneError: null,
          forgotPasswordError: null,
          updatePhoneNewDriverError: null,
          addDocsError: null,
          workingStatusError: null,
          currentPassError: null,
          updateActiveError: null,
          contactUsError: null
        },
        success: {
          ...state.success,
          signInSuccess: null,
          otpResentSuccess: null,
          signUpSuccess: null,
          otpSuccess: null,
          forgotPasswordSucces: null,
          updateDriverSuccess: null,
          updatePhoneNewDriverSuccess: null,
          addDocsSuccess: null,
          workingStatusSuccess: null,
          currentPassSuccess: null,
          updateActiveSuccess: null, 
          contactUsSuccess: null
        }
      }
      case driverActions.SET_CONTACT_US_SUCCESS:
        return {
          ...state,
  
          errors: { ...state.errors, contactUsError: null },
          success: {
            ...state.success,
            contactUsSuccess: action.payload.contactUsSuccess
          }
        }
      case driverActions.SET_CONTACT_US_ERROR:
        console.log('contactUsError',action.payload)
        return {
          ...state,
  
          errors: {
            ...state.errors,
            contactUsError: action.payload.contactUsError
          },
          success: { ...state.success, contactUsSuccess: null }
        }

    //sign out
    case driverActions.SIGN_OUT:
      return {
        ...state,
        _id: null,
        name: null,
        email: null,
        phone: null,
        newPhone: null,
        city: null,
        adddress: null,
        zipcode: null,
        activeZipcodes: [],
        status: null,
        jwt: null,
        otp: null,
        currentPassword: null,
        isVisible: false,
        isLoggedIn: false,
        isFetching: false,
        isJWTRecieved: false,
        isOTPRecieved: false,
        isOTPResent: false,
        isSignUpStarted: false,
        isFreshSignUp: false,
        activityStatus: null,
        workingStatus: null,
        updateP: null,
        verifications: initialState.verifications,
        vehicleFiles: initialState.vehicleFiles,
        errors: initialState.errors,
        success: initialState.success,
        Documents: initialState.Documents
      }

    default:
      return state
  }
}

export default driverReducer
