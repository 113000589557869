//Libraries
import React, { useState } from 'react'
import {
  CardActionArea,
  CardContent,
  Card,
  CardMedia,
  Box,
  Button,
  CssBaseline,
  useMediaQuery,
  makeStyles,
  Typography,
  Grid,
  useTheme,
  Hidden
} from '@material-ui/core'

//Style
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(4),
      width: 216,
      height: 216
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: theme.spacing(10),
      width: '50%',
      height: '60%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(10),
      width: '70%',
      height: '70%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(16)
    }
  },
  paper: {
    marginBottom: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(1, 3)
    },
    [theme.breakpoints.only('sm')]: {
      margin: theme.spacing(1, 7)
    },
    [theme.breakpoints.only('md')]: {
      margin: theme.spacing(6, 19)
    },
    [theme.breakpoints.only('lg')]: {
      margin: theme.spacing(5, 7)
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(9, 19)
    }
  },
  form: {
    [theme.breakpoints.down('lg')]: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
      alignItems: 'center'
    },
    [theme.breakpoints.only('xl')]: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(4),
      alignItems: 'center'
    }
  },
  submit: {
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(3, 0, 0)
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(7, 0, 2),
      fontSize: 18
    }
  },
  icon: {
    [theme.breakpoints.only('xs')]: {
      width: 65.25,
      height: 35.06
    },
    [theme.breakpoints.only('sm')]: {
      width: 85.25,
      height: 55.06
    },
    [theme.breakpoints.only('md')]: {
      width: 105.25,
      height: 65.06
    },
    [theme.breakpoints.only('lg')]: {
      width: '55.25%',
      height: '25.06%'
    }
  },
  card: {
    boxShadow: '0 0 6px rgba(34, 35, 58, 0.2)',
    variant: 'outlined',
    height: '100%'
  },
  desc: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  },
  vanText: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14.5
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 18
    }
  },
  box: {
    [theme.breakpoints.down('md')]: {
      marginRight: '10%'
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '40%'
    }
  }
}))

export default function VehicleType () {
  const theme = useTheme()
  const classes = useStyles()
  const matches = useMediaQuery(theme.breakpoints.up('xl'))
  const xsmatches = useMediaQuery(theme.breakpoints.only('xs'))
  const mdmatches = useMediaQuery(theme.breakpoints.down('md'))
  const [id1, setid1] = useState(false)
  const [id2, setid2] = useState(false)
  const [id3, setid3] = useState(false)
  const [id4, setid4] = useState(false)
  const options = [
    {
      id: id1,
      img: '/assets/images/mwb.png',
      name: 'Mwb',
      className: classes.icon,
      Desc: 'Medium Wheelbase Transit'
    },
    {
      id: id2,
      img: '/assets/images/Lwb.png',
      name: 'Lwb',
      className: classes.icon,
      Desc: 'Long Wheelbase Transit'
    },
    {
      id: id3,
      img: '/assets/images/Tlbv.png',
      name: 'Tlbv',
      className: classes.icon,
      Desc: 'Ton luton body van with tail lift'
    },
    {
      id: id4,
      img: '/assets/images/Tlbw.png',
      name: 'Tlbw',
      className: classes.icon,
      Desc: 'Ton luton box van with tail lift'
    }
  ]

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Hidden lgUp>
        <Grid item xs={12} sm={12} md={12} lg={false} xl={false} align='center'>
          <img
            src='/assets/images/vehicleregister.png'
            alt='vehicle'
            className={classes.image}
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className={classes.paper}>
          <Hidden mdDown>
            <Typography component='h1' variant='h5'>
              <b>Choose your Vehicle Type</b>
            </Typography>
            <Box mt={2}>
              <Typography component='h3' variant='h6' color='secondary'>
                Choose the type of van you want to Register with van go
              </Typography>
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Typography className={classes.vanText}>Choose Van Type</Typography>
          </Hidden>
          <form className={classes.form} noValidate>
            <Grid
              container
              spacing={mdmatches ? (xsmatches ? 2 : 5) : matches ? 5 : 3}
            >
              {options.map(option => (
                <Grid item xs={6} lg={6}>
                  <Card
                    className={classes.card}
                    bgcolor='background.paper'
                    p={2}
                    borderRadius={8}
                  >
                    <CardActionArea>
                      <CardMedia>
                        <Box m={mdmatches ? 1 : 2}>
                          <img
                            src={option.img}
                            alt='van'
                            className={option.className}
                          />
                        </Box>
                      </CardMedia>
                      <CardContent>
                        <Typography gutterBottom className={classes.text}>
                          <b>{option.name}</b>
                        </Typography>
                        <Box className={classes.box}>
                          <Typography
                            className={classes.desc}
                            color='secondary'
                          >
                            {option.Desc}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </form>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Submit
          </Button>
        </div>
      </Grid>
      <Grid item xs={false} sm={false} md={false} lg={6} xl={6} align='center'>
        <Hidden mdDown>
          <img
            src='/assets/images/vehicleregister.png'
            alt='vehicle'
            className={classes.image}
          />
        </Hidden>
      </Grid>
    </Grid>
  )
}
