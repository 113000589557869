//Libraries
import React, { useState, useEffect } from "react";
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
  Divider,
  Switch,
  withStyles,
  Snackbar,
  Box,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert as MuiAlert } from "@material-ui/lab";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

//Components
import NavigationDrawer from "../../Components/NavigationDrawer/NavigationDrawer";
import { applyTheme } from "../../Redux/Theme/themeActions";
import actions from "../../Redux/Driver/Driver.actions";

//Style
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    [theme.breakpoints.only("xs")]: {
      marginTop: "30%",
      marginLeft: "7%",
      marginRight: "7%",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "18%",
      marginLeft: "4.5%",
      marginRight: "5%",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "12%",
      marginLeft: "2.5%",
      marginRight: "5%",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "2%",
      marginTop: "6%",
      marginLeft: "5%",
      marginRight: "5%",
      paddingRight: "1.5%",
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 23.2,
    },
  },
  maintitle: {
    fontWeight: "bold",
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 22,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 24,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 28,
    },
  },
  mainRoot: {
    display: "flex",
    width: "100%",
  },
  root: {
    textAlign: "right",
  },
  img: {
    verticalAlign: "middle",
    height: 68,
    width: 68,
  },
  box: {
    width: "100%",
    textAlign: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  heading: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: 22,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 32.4,
    },
  },
  subheading: {
    paddingTop: "2%",
  },
  row: {
    textDecoration: "none",
    color: theme.palette.type === "light" ? "black" : "white",
  },
  dialogC: {
    padding: "24px 24px",
  },
  image: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "50%",
      height: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
      height: "40%",
    },
  },
  divider: {
    [theme.breakpoints.between("xs", "lg")]: {
      marginTop: "1.5%",
      marginBottom: "1.5%",
    },
    [theme.breakpoints.only("xl")]: {
      marginTop: "2.5%",
      marginBottom: "2.5%",
    },
  },
  item: {
    textAlign: "left",
    paddingRight: "2%",
  },
}));

//IOS Switch
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 55,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(28px)",
      // color: 'error',
      "& + $track": {
        backgroundColor: "#6251FE",
        opacity: 1,
        border: "none",
      },
      "&$disabled": {
        color: "#ddd",
        "& + $track": {
          // gray out the track
          backgroundColor: "#ddd",
        },
      },
    },

    "&$focusVisible $thumb": {
      color: "#6251FE",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#B9B6B6",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function Alert(props) {
  return <MuiAlert elevation={2} variant="filled" {...props} />;
}

export default function Setting(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const themesType = useSelector((state) => state.themes.theme);

  useEffect(() => {
    console.log("clearing previous error");
    dispatch(actions.clearError());
  }, []);

  const jwt = useSelector((state) => state.driver.jwt);
  const workingStatusError = useSelector(
    (state) => state.driver.errors.workingStatusError
  );
  const workingStatusSuccess = useSelector(
    (state) => state.driver.success.workingStatusSuccess
  );
  const activityStatus = useSelector((state) => state.driver.activityStatus);
  console.log("activity status in settings", activityStatus);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);
  const handleErrorSnack = () => {
    setOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError());
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (workingStatusError && !workingStatusSuccess) {
      handleErrorSnack();
    }

    if (workingStatusSuccess && !workingStatusError) {
      handleErrorSnack();
    }
  }, [workingStatusSuccess, workingStatusError]);

  useEffect(() => {
    navigator.permissions
        .query({
          name: "geolocation",
        })
        .then(function (result) {
          const onLocationFetchSuccess = (position) => {
            setIsLocationEnabled(true);
            /*
           Consume location coordinates here and proceed as required
           using position.coords.latitude and position.coords.longitude
        */
          };

          const onLocationFetchFailure = (error = {}) => {
            setIsLocationEnabled(false);
            // Error code 1 corresponds to user denying/blocking the location permission
            if (error.code === 1) {
              // Respond to failure case as required
            }
          };

          navigator.geolocation.getCurrentPosition(
            onLocationFetchSuccess,
            onLocationFetchFailure
          );

          if (result.state === "denied") {
            onLocationFetchFailure();
          }

          // This will still work for Chrome
          result.onchange = function () {
            if (result.state === "denied") {
              onLocationFetchFailure();
            }
          };
        });
  }, []);

  const changeThemeHandler = () => {
    if (themesType === "dark") {
      dispatch(applyTheme("light"));
    } else if (themesType === "light") {
      dispatch(applyTheme("dark"));
    }
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const changeStatusHandler = () => {
    if (activityStatus === "Looking for work") {
      dispatch(
        actions.updateDriverStatus({ allowedresponses: "Disable" }, jwt)
      );
    } else {
      dispatch(actions.updateDriverStatus({ allowedresponses: "Enable" }, jwt));
    }
  };

  const changeLocationHandler = () => {
    
    const isLocation=!isLocationEnabled
   
    if (isLocation) {
      navigator.permissions
        .query({
          name: "geolocation",
        })
        .then(function (result) {
          const onLocationFetchSuccess = (position) => {
            setIsLocationEnabled(true);
            /*
           Consume location coordinates here and proceed as required
           using position.coords.latitude and position.coords.longitude
        */
          };

          const onLocationFetchFailure = (error = {}) => {
            setIsLocationEnabled(false);
            // Error code 1 corresponds to user denying/blocking the location permission
            if (error.code === 1) {
              // Respond to failure case as required
            }
          };

          navigator.geolocation.getCurrentPosition(
            onLocationFetchSuccess,
            onLocationFetchFailure
          );

          if (result.state === "denied") {
            onLocationFetchFailure();
          }

          // This will still work for Chrome
          result.onchange = function () {
            if (result.state === "denied") {
              onLocationFetchFailure();
            }
          };
        });
    } else {
      setIsLocationEnabled(!isLocationEnabled);
    }
  };

  const changeConsentHandler = () => {
    if (Notification.permission === "default") {
      Notification.requestPermission();
    } else if (Notification.permission === "denied") {
      alert(
        "There is no Notification support on this device or it is disabled. Please check your settings."
      );
    } else {
      alert("Notification permission already granted");
    }
  };

  return (
    <div className={classes.mainRoot}>
      <NavigationDrawer />
      <main className={classes.content}>
        <Typography className={classes.maintitle}>General Settings</Typography>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid
            item
            className={classes.item}
            xs={9}
            sm={10}
            md={11}
            lg={11}
            xl={11}
          >
            <Typography className={classes.title}>
              Enable Notification
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            className={classes.root}
          >
            <IOSSwitch
              checked={Notification.permission === "granted" ? true : false}
              onChange={changeConsentHandler}
              name="checkedA"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid
            item
            xs={9}
            sm={10}
            md={11}
            lg={11}
            xl={11}
            className={classes.item}
          >
            <Typography className={classes.title}>Enable Dark Theme</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            className={classes.root}
          >
            <IOSSwitch
              checked={themesType === "dark" ? true : false}
              onChange={changeThemeHandler}
              name="checkedB"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid
            item
            xs={9}
            sm={10}
            md={11}
            lg={11}
            xl={11}
            className={classes.item}
          >
            <Typography className={classes.title}>Enable Location</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            className={classes.root}
          >
            <IOSSwitch
              checked={isLocationEnabled}
              onChange={changeLocationHandler}
              name="checkedC"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        {activityStatus === "Looking for work" ||
        activityStatus === "Disable" ? (
          <>
            <Grid container>
              <Grid
                item
                xs={9}
                sm={10}
                md={11}
                lg={11}
                xl={11}
                className={classes.item}
              >
                <Typography className={classes.title}>
                  Enable Working Status
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sm={2}
                md={1}
                lg={1}
                xl={1}
                className={classes.root}
              >
                <IOSSwitch
                  checked={activityStatus === "Looking for work" ? true : false}
                  onChange={changeStatusHandler}
                  name="checkedC"
                />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </>
        ) : null}
        <Typography className={classes.maintitle}>Privacy Settings</Typography>
        <Divider className={classes.divider} />
        <Link id="RouterNavLink1" className={classes.row} to="/reset_password">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.row}
          >
            <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
              <Typography className={classes.title}>Change Password</Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={2}
              md={1}
              lg={1}
              xl={1}
              className={classes.root}
            >
              <ArrowRightIcon />
            </Grid>
          </Grid>
        </Link>
        <Divider className={classes.divider} />
        <Link id="RouterNavLink1" className={classes.row} to="/updatePhone">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.row}
          >
            <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
              <Typography className={classes.title}>Change Phone</Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={2}
              md={1}
              lg={1}
              xl={1}
              className={classes.root}
            >
              <ArrowRightIcon />
            </Grid>
          </Grid>
        </Link>
        <Divider className={classes.divider} />
        <Typography className={classes.maintitle}>Feedback</Typography>
        <Divider className={classes.divider} />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={handleClickOpen2}
        >
          <Grid item xs={9} sm={10} md={11} lg={11} xl={11}>
            <Typography className={classes.title}>Rate this Website</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            className={classes.root}
          >
            <ArrowRightIcon />
          </Grid>
        </Grid>
        <Box mt={matches ? 1.5 : 2.5}>
          <Divider />
        </Box>
        <Dialog
          open={open2}
          onClose={handleClose2}
          aria-labelledby="form-dialog-title"
          fullWidth
          hideBackdrop={true}
          maxWidth="sm"
        >
          <DialogContent className={classes.dialogC}>
            <div className={classes.toolbar}>
              <Box className={classes.box}>
                <img
                  src="/assets/images/rating.png"
                  alt="rating"
                  className={classes.image}
                />
                <Typography className={classes.heading}>
                  Your opinion matter to us !
                </Typography>
                <Typography className={classes.subheading}>
                  If you're enjoying using our application, please give us
                  rating.
                </Typography>
                <Box mt={3}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className={classes.submit}
                  >
                    Play Store
                  </Button>
                </Box>
              </Box>
            </div>
          </DialogContent>
        </Dialog>
        {workingStatusSuccess ? (
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
          >
            <Alert onClose={handleCloseSnack} severity="success">
              {workingStatusSuccess}
            </Alert>
          </Snackbar>
        ) : null}
        {workingStatusError ? (
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
          >
            <Alert onClose={handleCloseSnack} severity="error">
              {workingStatusError}
            </Alert>
          </Snackbar>
        ) : null}
      </main>
    </div>
  );
}
