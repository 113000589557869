//Libraries
import axios from 'axios'

//Components
import backendPath from '../../Utils/constants'

const actions = {
  //#region action declaration
  UPDATE_START_LOCATION: 'order/UPDATE_START_LOCATION',
  UPDATE_END_LOCATION: 'order/UPDATE_END_LOCATION',
  UPDATE_ORDER_DETAILS: 'order/UPDATE_ORDER_DETAILS',

  CONJESTION_CHARGES: 'order/CONJESTION_CHARGES',
  CONJESTION_CHARGES_SUCCESS: 'order/CONJESTION_CHARGES_SUCCESS',
  CONJESTION_CHARGES_ERROR: 'order/CONJESTION_CHARGES_ERROR',

  PLACE_ORDER: 'order/PLACE_ORDER',
  PLACE_ORDER_SUCCESS: 'order/PLACE_ORDER_SUCCESS',
  SET_PLACE_ORDER_ERROR: 'order/SET_PLACE_ORDER_ERROR',
  GET_ORDERS: 'order/GET_ORDERS',
  SET_ORDERS: 'order/SET_ORDERS',
  GET_ORDER_DETAILS: 'order/GET_ORDER_DETAILS',
  GET_ORDER_CONFIRMATION: 'order/GET_ORDER_CONFIRMATION',
  GET_ACCEPTED_ORDERS: 'order/GET_ACCEPTED_ORDERS',
  SET_ACCEPTED_ORDERS: 'order/SET_ACCEPTED_ORDERS',
  UPDATE_ORDER_STATUS: 'order/UPDATE_ORDER_STATUS',
  VERIFY_ORDER_CODE: 'order/VERIFY_ORDER_CODE',
  GET_PAST_ORDERS: 'order/GET_PAST_ORDERS',
  GET_ACTIVE_ORDERS: 'order/GET_ACTIVE_ORDERS',
  GET_PENDING_ORDERS: 'order/GET_PENDING_ORDERS',
  GET_DELIVERED_ORDERS: 'order/GET_DELIVERED_ORDERS',
  GET_CANCELLED_ORDERS: 'order/GET_CANCELLED_ORDERS',
  COMPLETE_ORDER_ISSUE: 'order/COMPLETE_ORDER_ISSUE',
  SOCKET_CONNECTION: 'SOCKET_CONNECTION',
  DRIVER_CURRENT_LOCATION: 'DRIVER_CURRENT_LOCATION',
  UPDATE_LOCATION: 'order/UPDATE_LOCATION',
  UPDATE_POLYLINE: 'order/UPDATE_POLYLINE',
  RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
  CLEAR_WATCH_HISTORY: 'CLEAR_WATCH_HISTORY',
  CLEAR_ERROR: 'order/CLEAR_ERROR',
  //#endregion

  //#region start, end, driver location
  //update Start Location
  updateStartLocation: locationObj => {
    return {
      type: actions.UPDATE_START_LOCATION,
      payload: locationObj
    }
  },

  //Update End Location
  updateEndLocation: locationObj => {
    return {
      type: actions.UPDATE_END_LOCATION,
      payload: locationObj
    }
  },

  //Driver Current Location
  driverCurrentLocation: (lat, lng) => dispatch => {
    console.log('currentLocation in dispatch', lat, lng)
    dispatch({
      type: actions.DRIVER_CURRENT_LOCATION,
      payload: { lat, lng }
    })
  },

  //Update locations
  updateLocations: (lat, lng) => dispatch => {
    dispatch({
      type: actions.UPDATE_LOCATION,
      payload: { lat, lng }
    })
  },

  //Update polyline
  updatePolyline: (lat, lng) => dispatch => {
    dispatch({
      type: actions.UPDATE_POLYLINE,
      payload: { lat, lng }
    })
  },
  //#endregion

  //#region socket connection
  //Socket Connection for chat
  socketConnection: socket => dispatch => {
    dispatch({
      type: actions.SOCKET_CONNECTION,
      payload: socket
    })
  },

  //ReceiveMessage
  recieveMessage: msg => dispatch => {
    dispatch({
      type: actions.RECEIVE_MESSAGE,
      payload: msg
    })
  },
  //#endregion

  //#region order status, confirmation, complete order issue
  //Clear watch history
  clearWatchHistory: () => dispatch => {
    dispatch({
      type: actions.CLEAR_WATCH_HISTORY
    })
  },

  //Complete Order with issue
  completeOrderIssue: (jwt, id, value, desc) => dispatch => {
    console.log('order id', id, jwt)
    dispatch({
      type: actions.COMPLETE_ORDER_ISSUE
    })
    axios({
      method: 'POST',
      url: backendPath + 'updateOrderStatus',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      data: {
        orderId: id,
        orderStatus: 'order complete',
        completeOrderProblem: value,
        description: desc
      }
    })
      .then(function (response) {
        // handle response
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === 'success, orderStatus updated'
          ) {
            console.log(
              ' %c request cancel Order response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch(actions.getOrderDetails(jwt, id))
            console.log('Ayesha request for order completion issue')
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log('%c error', 'color: red; font-weight: bold', error.response)
      })
      .then(function () {
        // always executed
      })
  },

  //Order Confirmed
  orderConfirmation: (orderId, response, jwt) => dispatch => {
    axios({
      method: 'post',
      url: backendPath + 'orderConfirmation',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      data: {
        orderId: orderId,
        response: response
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (
            response.data.message === 'success, order rejected' ||
            response.data.message === 'success, order accepted'
          ) {
            console.log(
              '%c Get Order Confirmation',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch(actions.getOrderDetails(jwt, orderId))
            dispatch(actions.getOrdersList(jwt))
            dispatch({
              type: actions.GET_ORDER_CONFIRMATION,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c Get Order Confirmation',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  //Update Order Status
  updateOrderStatus: (
    orderId,
    orderStatus,
    jwt,
    otp,
    stopWatching
  ) => dispatch => {
    console.log(orderId, orderStatus, jwt)
    axios({
      method: 'post',
      url: backendPath + 'updateOrderStatus',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      data: {
        orderId: orderId,
        orderStatus: orderStatus,
        enterOrderCode: otp
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (
            response.data &&
            response.data.message === 'success, orderStatus updated'
          ) {
            console.log(
              '%c Update Order Status',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch(actions.getOrderDetails(jwt, orderId))
            dispatch({
              type: actions.UPDATE_ORDER_STATUS,
              payload: response.data
            })
            if (orderStatus === 'order complete') {
              stopWatching()
            }
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c Update Order Status',
          'color: red; font-weight: bold',
          error
        )
      })
  },
  //#endregion

  //#region Orders List, details and update order details
  //Get Orders List
  getOrdersList: jwt => dispatch => {
    dispatch({
      type: actions.GET_ORDERS
    })
    axios({
      method: 'get',
      url: backendPath + 'allPendingOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && Array.isArray(response.data)) {
            dispatch(actions.setOrdersList(response.data))
          }
        }
      })
      .catch(function (error) {})
  },

  //Set Orders List
  setOrdersList: orderObj => {
    return {
      type: actions.SET_ORDERS,
      payload: orderObj
    }
  },

  //Accepted Orders List
  getAcceptedOrdersList: jwt => dispatch => {
    dispatch({
      type: actions.GET_ACCEPTED_ORDERS
    })
    axios({
      method: 'get',
      url: backendPath + 'allActiveOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(actions.setAcceptedOrdersList(response.data))
        }
      })
      .catch(function (error) {})
  },

  //Accepted orders list
  setAcceptedOrdersList: orderObj => {
    return {
      type: actions.SET_ACCEPTED_ORDERS,
      payload: orderObj
    }
  },

  //Get Details of order
  getOrderDetails: (jwt, orderId) => dispatch => {
    axios({
      method: 'post',
      url: backendPath + 'orderDetails',
      headers: {
        Authorization: `Bearer ${jwt}`
      },
      data: {
        orderId: orderId
        // orderId: orderId.orderId,
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data && response.data._id) {
            dispatch({
              type: actions.GET_ORDER_DETAILS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c Get Order Detail',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  //GET Past orders List
  getPastOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'getPastOrdersList',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        console.log(
          '%c Get Past Orders List',
          'color: #76FF03; font-weight: bold',
          response
        )

        if (response.status === 200) {
          dispatch({
            type: actions.GET_PAST_ORDERS,
            payload: response.data
          })
        }
      })
      .catch(function (error) {
        console.log(
          '%c Get Past Orders List',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  //GET Active orders list
  getActiveOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'allActiveOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            console.log(
              '%c GET_ACTIVE_ORDERS Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.GET_ACTIVE_ORDERS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c GET_ACTIVE_ORDERS Error',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  //GET Pending Orders list
  getPendingOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'allPendingOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            console.log(
              '%c GET_PENDING_ORDERS Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.GET_PENDING_ORDERS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c GET_PENDING_ORDERS Error',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  // GET Delivered orders list
  getDeliveredOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'allCompleteOrders',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            console.log(
              '%c GET_DELIVERED_ORDERS Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.GET_DELIVERED_ORDERS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c GET_DELIVERED_ORDERS Error',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  //GET Canceled orders list
  getCancelledOrdersList: jwt => dispatch => {
    axios({
      method: 'get',
      url: backendPath + 'allCancelOrders ',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            console.log(
              '%c GET_CANCELLED_ORDERS Response',
              'color: #76FF03; font-weight: bold',
              response
            )
            dispatch({
              type: actions.GET_CANCELLED_ORDERS,
              payload: response.data
            })
          }
        }
      })
      .catch(function (error) {
        console.log(
          '%c GET_CANCELLED_ORDERS Error',
          'color: red; font-weight: bold',
          error
        )
      })
  },

  //Update Order Details
  updateOrderDetails: orderDetailsObj => {
    return {
      type: actions.UPDATE_ORDER_DETAILS,
      payload: orderDetailsObj
    }
  },
  //#endregion

  //#region conjestion charges
  //Conjestion Charges
  conjestionCharges: (orderId, dataObj, jwt) => dispatch => {
    axios({
      method: 'post',
      url: backendPath + 'conjectionCharge',
      headers: {
        Authorization: 'Bearer ' + jwt
      },
      data: {
        orderId: orderId,
        numberOfTollPlaze: dataObj.tollPlaza,
        totalAmountOfTollPlaza: dataObj.amountPlaza,
        citiesWithCongestion: dataObj.cities,
        totalAmountOfCongestion: dataObj.amountCongestion
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          if (
            response.data
            // &&
            // response.data.message === 'success, Updated Information'
          ) {
            dispatch(
              actions.setCongestionChargesSuccess(
                response.data.message
                  ? response.data.message
                  : 'Additional Charges added'
              )
            )
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch(
            actions.setCongestionError(
              error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error While Congestion Charges!'
            )
          )
        } else if (!error.status) {
          dispatch(actions.setCongestionError('Network Error'))
        }
      })
  },

  //Congestion Charges Success
  setCongestionChargesSuccess: success => {
    return {
      type: actions.CONJESTION_CHARGES_SUCCESS,
      payload: { success }
    }
  },

  //Congestion error
  setCongestionError: error => {
    return {
      type: actions.CONJESTION_CHARGES_ERROR,
      payload: { error }
    }
  },
  //#endregion

  //Clear Error
  clearError: () => {
    return {
      type: actions.CLEAR_ERROR
    }
  }
}

export default actions
