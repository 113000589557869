//Libraries
import React, { useEffect, useState } from 'react'
import {
  Paper,
  Box,
  Link,
  TextField,
  CssBaseline,
  Button,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  Snackbar,
  useTheme,
  Hidden
} from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

//components
import actions from '../../../Redux/Driver/Driver.actions'
import Loader from '../../../Components/Loader/Loader'
import Yup from '../../../Yup/YupAuthValidation.schemas'

function Alert (props) {
  return <MuiAlert elevation={2} variant='filled' {...props} />
}

//Style
const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  title: {
    fontWeight: 'bold',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1.17rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2.25rem'
    }
  },
  subtitle: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.17rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.37rem'
    }
  },
  image: {
    width: '80%',
    height: 'auto'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3)
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(5)
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: theme.spacing(6)
    }
  },
  submit: {
    fontSize: 14,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(3, 0, 4)
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(3, 0, 2)
    },
    [theme.breakpoints.only('xl')]: {
      margin: theme.spacing(7, 0, 2)
    }
  },
  links: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.85rem'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.95rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '1.0rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1.155rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.125rem'
    }
  },
  boxLog: {
    marginBottom: '1%',
    [theme.breakpoints.only('xs')]: {
      marginTop: '12%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: '7%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '6%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '9%'
    }
  },
  remBox: {
    [theme.breakpoints.only('md')]: {
      marginTop: '3%'
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '1%'
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '1%'
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '2%'
    }
  },
  textfields: {
    marginTop: '2%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.83rem'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem'
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '1rem'
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '1.1rem'
    }
  },
  grid: {
    marginTop: '2%',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left'
    }
  },
  cont: {
    display: 'inline-block'
  }
}))

export default function SignIn (props) {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const dispatch = useDispatch()
  useEffect(() => {
    console.log('clearing previous error')
    dispatch(actions.clearError())
  }, [])

  const signInError = useSelector(state => state.driver.errors.signInError)
  const loading = useSelector(state => state.driver.isFetching)
  const phone = useSelector(state => state.driver.rememberMe.emailPhone)
  const password = useSelector(state => state.driver.rememberMe.password)
  const isRemember = useSelector(state => state.driver.rememberMe.isRememberMe)
  const updatePhoneDriverSuccess = useSelector(
    state => state.driver.updatePhoneDriverSuccess
  )

  const themeType = useSelector(state => state.themes.theme)

  const [openSnackUpdatePhone, setOpenSnackUpdatePhone] = useState(false)

  const handleErrorSnackUpdatePhone = () => {
    setOpenSnackUpdatePhone(true)
  }

  const handleCloseSnackUpdatePhone = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpenSnackUpdatePhone(false)
  }

  useEffect(() => {
    if (updatePhoneDriverSuccess) {
      handleErrorSnackUpdatePhone()
    }
  }, [updatePhoneDriverSuccess])

  useEffect(() => {
    if (signInError) {
      console.log(signInError)
      handleErrorSnack()
    }
  }, [signInError])

  const [initialValues, setInitialValues] = useState({
    username: phone === null ? '' : phone,
    password: password === null ? '' : password,
    showPassword: false,
    checked: isRemember === true ? true : false
  })

  const handleClickShowPassword = () => {
    setInitialValues({
      ...initialValues,
      showPassword: !initialValues.showPassword
    })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleErrorSnack = () => {
    setOpen(true)
  }

  const handleCloseSnack = (event, reason) => {
    dispatch(actions.clearError())
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        component={Paper}
        elevation={2}
        square
      >
        <div className={classes.paper}>
          <Typography variant='h1' className={classes.title} color='primary'>
            LOGO
          </Typography>
          <Box className={classes.boxLog}>
            <Typography variant='h1' className={classes.title}>
              Log in.
            </Typography>
          </Box>
          <Typography
            variant='caption'
            className={classes.subtitle}
            color='secondary'
          >
            Login with your data that you entered during registration
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.SignInValidationSchema}
            onSubmit={values => {
              console.log(values)
              console.log('remember me', values.checked)
              dispatch(actions.signIn(values, props))
            }}
          >
            {({ errors, handleChange, touched }) => (
              <Form className={classes.form}>
                <Typography variant='body2' className={classes.textfields}>
                  Email / Phone
                </Typography>
                <TextField
                  error={errors.username && touched.username}
                  helperText={
                    errors.username && touched.username ? errors.username : null
                  }
                  variant='outlined'
                  margin='normal'
                  defaultValue={phone}
                  fullWidth
                  placeholder='Enter your Email'
                  id='username'
                  name='username'
                  autoComplete='username'
                  autoFocus
                  onChange={handleChange}
                />

                <Box mt={2}>
                  <Typography variant='body2' className={classes.textfields}>
                    Password
                  </Typography>
                </Box>

                <TextField
                  error={errors.password && touched.password}
                  helperText={
                    errors.password && touched.password ? errors.password : null
                  }
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  placeholder='Enter your Password'
                  name='password'
                  type={initialValues.showPassword ? 'text' : 'password'}
                  id='password'
                  defaultValue={password}
                  autoComplete='current-password'
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge='end'
                        >
                          {initialValues.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Box className={classes.remBox}>
                  <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={isRemember === true ? true : false}
                            onChange={handleChange}
                            name='checked'
                            color='primary'
                          />
                        }
                        classes={{
                          body1: classes.body1Text,
                          label: classes.links // here
                        }}
                        label='Remember me'
                      />
                    </Grid>
                    <Grid item>
                      <Link
                        href='/forgot_password'
                        variant='body2'
                        color='primary'
                        id='click-forgot'
                        className={classes.links}
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  disabled={loading}
                  className={classes.submit}
                >
                  {loading ? <Loader /> : 'Sign In'}
                </Button>
              </Form>
            )}
          </Formik>
          <Grid container className={classes.cont}>
            <Grid item className={classes.grid}>
              <Typography className={classes.links}>
                Don't have an account?
                <Link href='/sign_up'>{' Sign up'}</Link>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={false}
        lg={6}
        xl={6}
        align='center'
        style={{ backgroundColor: '#6251FE' }}
      >
        <Hidden mdDown>
          <Grid item lg={10}>
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              className={classes.root}
            >
              {themeType == 'light' ? (
                <img
                  src='/assets/images/signin.png'
                  alt='sign in'
                  className={classes.image}
                />
              ) : (
                <img
                  src='/assets/images/signind.png'
                  alt='sign in'
                  className={classes.image}
                />
              )}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      {signInError ? (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity='error'>
            {signInError}
          </Alert>
        </Snackbar>
      ) : null}
      {updatePhoneDriverSuccess ? (
        <Snackbar
          open={openSnackUpdatePhone}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={handleCloseSnackUpdatePhone}
        >
          <Alert onClose={handleCloseSnackUpdatePhone} severity={'success'}>
            {updatePhoneDriverSuccess}
          </Alert>
        </Snackbar>
      ) : null}
    </Grid>
  )
}
