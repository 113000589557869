//Libraries
import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  useTheme,
  AppBar,
  Tabs,
  Tab,
  Box,
  Grid,
  Snackbar,
  CircularProgress,
  useMediaQuery
} from '@material-ui/core'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, AlertTitle } from '@material-ui/lab'

//Components
import ActiveSection from './ActiveSection/activeHome.component'
import NavigationDrawer from '../../Components/NavigationDrawer/NavigationDrawer'
import actions from '../../Redux/Order/Order.actions'
import CustomerOffers from '../../Components/UserRequests/CustomerOffers.component'

//Style
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    [theme.breakpoints.only('xs')]: {
      marginTop: '3%',
      marginLeft: '5%',
      marginRight: '1%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '3%',
      marginLeft: '5%',
      marginRight: '2%'
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '2%',
      marginTop: '3%',
      marginRight: '4%',
      marginLeft: '2%'
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: theme.spacing(3),
      paddingTop: '2%',
      marginTop: '3%',
      marginRight: '5%',
      marginLeft: '2%'
    },
    [theme.breakpoints.only('xl')]: {
      paddingLeft: theme.spacing(3),
      paddingTop: '2%',
      marginTop: '3%',
      marginRight: '6%',
      marginLeft: '2%'
    }
  },
  mainRoot: {
    display: 'flex',
    width: '100%'
  },
  customStyleOnTab: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '12px',
      marginRight: '13px'
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '13px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '15px'
    }
  },
  activeTab: {
    fontWeight: '600',
    color: 'textPrimary',
    [theme.breakpoints.only('xs')]: {
      fontSize: '12px',
      marginRight: 13
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '13px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '15px'
    }
  },
  tabs: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      textAlign: 'left',
      alignItems: 'left'
    },
    '& .MuiTab-root': {
      padding: '0px'
    }
  },
  bar: {
    background: 'transparent',
    boxShadow: 'none',
    textAlign: 'left',
    [theme.breakpoints.only('xs')]: {
      paddingTop: '15%'
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '10%'
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '6%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '1.5%',
      paddingLeft: '2.5%'
    }
  },
  div: {
    textAlign: 'center'
  },
  progress: {
    width: '30px',
    height: '30px'
  }
}))

//Tab Panel
function TabPanel (props) {
  const { children, value, index, ...other } = props
  const theme = useTheme()

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

//Styled Tabs
const StyledTabs = styled(({ className, ...other }) => {
  return (
    <Tabs
      {...other}
      classes={{
        root: className,
        flexContainer: 'flexContainer',
        indicator: 'indicator'
      }}
      TabIndicatorProps={{ children: <span /> }}
    />
  )
})({
  '& .indicator': {
    background: 'primary',
    minWidth: '30px',
    maxWidth: '30px'
  },
  '& .flexContainer': {
    flexDirection: 'row',
    alignItems: 'left',
    justifyContent: 'left',
    textAlign: 'left'
    // width:'fit-content'
  }
})

export default function HomeNew () {
  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [loadingOrder, setLoadingOrder] = useState(true)

  useEffect(() => {
    console.log('clearing previous error')
    dispatch(actions.clearError())
  }, [])

  const activityStatus = useSelector(state => state.driver.activityStatus)

  const jwt = useSelector(state => state.driver.jwt)

  useEffect(() => {
    dispatch(actions.getActiveOrdersList(jwt))
    dispatch(actions.getOrdersList(jwt))
    setLoading(false)
    setLoadingOrder(false)
  }, [value])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  return (
    <div className={classes.mainRoot}>
      <NavigationDrawer />
      <main className={classes.content}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className={classes.root}>
              <AppBar position='static' color='default' className={classes.bar}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor='primary'
                  className={classes.tabs}
                >
                  <Tab
                    label={
                      <span
                        className={
                          value === 0
                            ? classes.activeTab
                            : classes.customStyleOnTab
                        }
                      >
                        User Requests
                      </span>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <span
                        className={
                          value === 1
                            ? classes.activeTab
                            : classes.customStyleOnTab
                        }
                      >
                        Active Orders
                      </span>
                    }
                    {...a11yProps(1)}
                  />
                </StyledTabs>
              </AppBar>
              <SwipeableViews
                slideStyle={{ overflow: 'visible' }}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  {loading ? (
                    <div className={classes.div}>
                      <CircularProgress className={classes.progress} />
                    </div>
                  ) : (
                    <CustomerOffers />
                  )}
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  {loadingOrder ? (
                    <div className={classes.div}>
                      <CircularProgress className={classes.progress} />
                    </div>
                  ) : (
                    <ActiveSection />
                  )}
                </TabPanel>
              </SwipeableViews>
            </div>
          </Grid>
        </Grid>
      </main>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={activityStatus == 'Disable'}
      >
        <Alert severity='error'>
          <AlertTitle>Working Status</AlertTitle>
          Your working status is disabled —{' '}
          <strong>
            <Link to={'/settings'}>check it out!</Link>
          </strong>
        </Alert>
      </Snackbar>
    </div>
  )
}
